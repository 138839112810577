import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../../../api/api';
import { multiBarTransform } from '../../../utils/graphs&charts/transBarData/multiBarTransData';
import { _isPayloadTruthy } from '../../../utils/misc/misc';
import {
    requestInstallationProgressPerWeekDataFail,
    requestInstallationProgressPerWeekData,
    requestInstallationProgressPerWeekDataSuccess,
    requestInstallationProgressProgressPerWeekFilters,
    requestInstallationProgressProgressPerWeekFiltersFailed,
    requestInstallationProgressProgressPerWeekFiltersSuccess
} from './actions';
import {
    graphLabelMaps,
    recruitmentInstallationProgressPerWeekColorMapG2
} from './meta/meta';
import { buildGETUrlWithParams } from '../../../api/misc/misc';
import { systemError } from '../../../utils/notifyToast';

function* mySagaFunction(action: any) {
    let data;

    try {
        yield axios.all([
            get(buildGETUrlWithParams(action.payload, '/installation_progress_weeks/cumulative/?')),
            get(buildGETUrlWithParams(action.payload, '/installation_progress_weeks/installation_statistics/?')),
        ]).then(axios.spread((...responses) => {
            data = {
                installationProgressWeeksCumulative: _isPayloadTruthy(responses[1].data) ? multiBarTransform(responses[0].data, recruitmentInstallationProgressPerWeekColorMapG2, null, graphLabelMaps) : {},
                installationProgressInstallationStatistics: _isPayloadTruthy(responses[1].data) ? responses[1].data : {},
            };
        }));
        yield put(requestInstallationProgressPerWeekDataSuccess(data));
    } catch (err) {
        yield put(requestInstallationProgressPerWeekDataFail(err));
    }
}

function* requestInstallationProgressProgressPerWeekFiltersSaga(action: any) {
    try {
        const { data } = yield call(() => get(buildGETUrlWithParams(action.payload, '/installation_progress_weeks/filters/?')));
        yield put(requestInstallationProgressProgressPerWeekFiltersSuccess(data));
    } catch (err) {
        systemError(err);
        yield put(requestInstallationProgressProgressPerWeekFiltersFailed(err));
    }
}

export function* installationProgressPerWeekWatchSaga() {
    yield takeLatest(requestInstallationProgressPerWeekData, mySagaFunction);
    yield (takeLatest(requestInstallationProgressProgressPerWeekFilters, requestInstallationProgressProgressPerWeekFiltersSaga));
}
