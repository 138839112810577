export const fetchReportingTrackerData = (data: any) => {
    const bigTableData:any[] = [];
    const bigDataTableFirst:any[] = [];
    const addedMonths:string[] = [];
    data.map((objectValue: any) => {
        const {customerNumber,smartcardId,intabStatusHh,intabStatusSc} = objectValue;
        bigDataTableFirst.push({
            customerNumber,
            smartcardId,
            intabStatusHh,
            intabStatusSc
        });
        for (const [year, monthObject] of Object.entries(objectValue)) {
            if(year !== 'customerNumber' && year !== 'smartcardId' && year !== 'intabStatusHh' && year !== 'intabStatusSc' ){
                for(const [monthKey, data] of Object.entries(monthObject as unknown as any)) {
                    const isAdd = addedMonths.find(x => x === `${monthKey} ${year}`);
                    if(isAdd){
                        const headerMatch = bigTableData.find(x => x.header === `${monthKey} ${year}`);
                        headerMatch.tableData.data.push(data);
                    }else{
                        const daysColumn:any[] = [];
                        for(const [dayKey,] of Object.entries(data as unknown as any)){
                            daysColumn.push({
                                Header: dayKey,
                                accessor: dayKey,
                            });
                           
                        }
                        const tableData:any = {
                            columns:[
                                {
                                    Header: `${monthKey} ${year}`,
                                    columns: daysColumn,
                                }
                            ],
                            data:[data]
                        };
                        bigTableData.push(
                            {tableData , header: `${monthKey} ${year}`}
                        );
                        addedMonths.push(`${monthKey} ${year}`);
                    }
                }
            }
            
        }
    });
    return {bigDataTableFirst,bigTableData,originalData:data};
};


