import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CInput, CInputGroup, CInputGroupPrepend, CInputGroupText, CRow, CSpinner } from '@coreui/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { watchResetPasswordRequest } from '../../redux/passwordReset/actions';
import dstvLogo from '../../resources/logos/DStv-i-Logo_RGB.png';
import kantarLogo from '../../resources/logos/KANTAR_Large_Logo_Black_RGB.png';
import multiChoiceLogo from '../../resources/logos/MultiChoice_logo.png';
import { themeColors } from '../../scss/themeColors';
import { logoCollectionWrapper } from '../../components/login/loginStyles';
import { RootState } from '../../redux/store';


const ForgotPassword = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: RootState) => state.password);
    const [emailSent, setEmailSent] = useState(false);
    const history = useHistory();
    const resetPassword = (values: any) => {
        dispatch(watchResetPasswordRequest({
            data: { username: values.username },
            onError: () => console.log('error'),
            onSuccess: () => {
                setEmailSent(true);
                toast.success('Please check your emails to reset your password.');
            }
        }));

    };
    const goToLogin = () => {
        history.push('/login');
    };
    if (emailSent) return <Redirect to='/login' />;
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <div style={logoCollectionWrapper} onClick={goToLogin}>
                <div className="d-flex align-items-end mr-4" style={{ position: 'relative' }}><img alt='DSTV-logo' style={{ width: '150px', position: 'relative', bottom: '-4px' }} src={dstvLogo} /></div>
                <div className="d-flex align-items-start p-2" style={{ position: 'relative' }}> <img alt='Multi-Choice-logo' style={{ width: '150px', position: 'relative', bottom: '-7px' }} src={multiChoiceLogo} /> </div>
                <div className="d-flex align-items-end ml-4"><img alt='Kantar-logo' style={{ width: '150px' }} src={kantarLogo} /></div>
            </div>
            <CContainer style={{ position: 'relative' }}>
                <CRow className="justify-content-center">
                    <CCol md="6">

                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody >
                                    {
                                        // emailSent ?
                                        // <div>
                                        //     <h3>Please check your emails to reset your password.</h3>
                                        //     <CButton style={{ background: themeColors['blueStates'], color: 'white' }}>
                                        //         <Link to='/login' className="text-white">Login</Link>
                                        //     </CButton>
                                        // </div> :
                                        <Formik
                                            onSubmit={resetPassword}
                                            initialValues={{
                                                username: ''
                                            }}
                                            validationSchema={Yup.object({
                                                username: Yup.string().required('Username field is required').trim()
                                            })}
                                        >
                                            {({ errors, values: { username }, touched, handleBlur, handleChange, handleSubmit }) => (
                                                <>
                                                    <h1>Reset Password</h1>
                                                    <p className="text-muted">Enter username</p>
                                                    <CInputGroup className="mb-3">
                                                        <CInputGroupPrepend>
                                                            <CInputGroupText>
                                                                <CIcon name="cil-user" />
                                                            </CInputGroupText>
                                                        </CInputGroupPrepend>
                                                        <CInput
                                                            type="email"
                                                            placeholder="Username"
                                                            value={username}
                                                            onChange={handleChange('username')}
                                                            onBlur={handleBlur('username')}

                                                        />
                                                    </CInputGroup>
                                                    <div className="text-danger mb-2">{errors.username && touched.username ? errors.username : null}</div>
                                                    <div className="d-flex align-items-center">
                                                        <CButton
                                                            style={{ background: themeColors['blueStates'], color: 'white' }}
                                                            type="submit"
                                                            className="px-4"
                                                            onClick={handleSubmit}
                                                            disabled={isLoading}
                                                        >Submit</CButton>
                                                        {isLoading && <CSpinner color="info" style={{ margin: '0 5px' }} />}
                                                        <CButton
                                                            onClick={goToLogin}
                                                            style={{ background: themeColors['orangeStates'], color: 'white', right: '2.6rem' }}
                                                            className="px-4 float-right position-absolute"
                                                        >Cancel</CButton>
                                                    </div>

                                                </>
                                            )}

                                        </Formik>
                                    }
                                </CCardBody>
                            </CCard>

                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default ForgotPassword;
