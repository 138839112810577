import { createReducer } from 'redux-act';
import { genPaginatedData } from '../../utils/pagination/pagiantion';
import { clearFilters, requestAddUser, requestAddUserFail, requestAddUserSuccess, requestAdminData, requestAdminDataFail, requestAdminDataSuccess, submitForm, updateFilters } from './actions';

export type TAdminState = {
    isLoading: boolean,
    error: any,
    data: { [key: string]: any }[] | null,
    filters: { [key: string]: string | number | (string | number)[]} | null,
    groups: {[key: string | number]: string}
}

const defaultState = {
    isLoading: true,
    error: null,
    data: null,
    filters: null,
    groups: {}
};

export const adminReducer = createReducer<TAdminState>({}, defaultState);

adminReducer.on(requestAdminData, state => ({
    ...state,
    isLoading: true
})).on(requestAdminDataSuccess, (state, payload) => ({
    ...state,
    data: genPaginatedData(50, payload.data),
    isLoading: false,
    groups: payload.groups
})).on(requestAdminDataFail, (state, payload) => ({
    ...state,
    error: payload,
    isLoading: false
})).on(submitForm, (state) => ({
    ...state,
    isLoading: true
})).on(updateFilters, (state, payload) => ({
    ...state,
    filters: {
        ...state.filters,
        ...payload
    }
})).on(clearFilters, (state) => ({
    ...state,
    filters: null
})).on(requestAddUser, (state) => ({
    ...state,
    isLoading: true
})).on(requestAddUserSuccess, (state, payload) => ({
    ...state,
    isLoading: false,
    data: genPaginatedData(50, payload),
})).on(requestAddUserFail, (state, payload) => ({
    ...state,
    error: payload
}));
