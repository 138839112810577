import { put, takeLatest } from '@redux-saga/core/effects';
import { requestTroubleShootingData, requestTroubleShootingDataFailed, requestTroubleShootingDataSuccess, requestTroubleShootingFilters, requestTroubleShootingFiltersFailed, requestTroubleShootingFiltersSuccess } from './actions';
import { get } from '../../../api/api';
import { systemError } from '../../../utils/notifyToast';
import axios from 'axios';
import { buildGETUrlWithParams } from '../../../api/misc/misc';



function* requestTroubleShootData(action: any) {
    let data;

    try {
        yield axios.all([
            get(buildGETUrlWithParams(action.payload.body, '/troubleshooting_activities/troubleshooting_activities/?')),
            get(buildGETUrlWithParams(action.payload.body, '/troubleshooting_activities/ts_installer_visits/?')),
            get(buildGETUrlWithParams(action.payload.body, '/troubleshooting_activities/weekly_progress_province/?'))
        ]).then((...responses: any) => {
            data = {
                troubleshootingActivities: responses[0][0].data,
                tsInstallerVisits: responses[0][1].data,
                weeklyProgressProvince: responses[0][2].data,
            };
        });
        yield put(requestTroubleShootingDataSuccess(data));
    } catch (error) {
        systemError(error);
        yield put(requestTroubleShootingDataFailed(error));
    }
}

function* requestTroubleShootFilter(action: any) {
    let data;
    try {
        yield axios.all([
            get(buildGETUrlWithParams(action.payload.body,'/troubleshooting_activities/ts_filters/?')),
        ]).then((...responses: any) => {
            data = {
                filters: responses[0][0].data,
            };
        });
        yield put(requestTroubleShootingFiltersSuccess(data));
    } catch (error) {
        systemError(error);
        yield put(requestTroubleShootingFiltersFailed(error));
    }
}

export function* troubleShootWatchSaga() {
    yield takeLatest(requestTroubleShootingData, requestTroubleShootData);
    yield takeLatest(requestTroubleShootingFilters, requestTroubleShootFilter);
}
