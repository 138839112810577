import { createReducer } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { requestOutcome, requestOutcomeSuccess, requestOutcomeFailed, requestOutcomeFilters, requestOutcomeFiltersSuccess, requestOutcomeFiltersFailed, requestOutcomeTopResCall, requestOutcomeTopResCallFailed, requestOutcomeTopResCallSuccess, requestOutcomeTopInstallCall, requestOutcomeTopInstallCallFailed, requestOutcomeTopInstallCallSuccess } from './action';


export type OutcomeResponseView = {
    reason1:string;
    reason1Count:number;
    reason2:string;
    reason2Count:number;
    reason3:string;
    reason3Count:number;
}

export type TOutcomeState = {
  outcome: OutcomeResponseView;
  isLoading: boolean;
  error: any;
  isUpdating: boolean;
  updatigError: any;
}


const defaultOutcomeState: TOutcomeState = {
    outcome:{
        reason1:'',
        reason1Count:0,
        reason2:'',
        reason2Count:0,
        reason3:'',
        reason3Count:0
    },
    isLoading:false,
    error:'',
    isUpdating:false,
    updatigError:''
};

export const outcomeReducer = createReducer<TOutcomeState>({}, defaultOutcomeState);

outcomeReducer.on(requestOutcome , state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestOutcomeSuccess,(state,outcome) => ({
    ...state,
    isLoading: false,
    outcome
})).on(requestOutcomeFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
}));
    

export type TOutcomeFiltersState = {
    filters: TFilters;
    isLoading:boolean;
    error: any;
  }
  
  
const defaultOutcomeFilterState: TOutcomeFiltersState = {
    filters: {},
    isLoading:false,
    error:''
};
  
export const outcomeFilterReducer = createReducer<TOutcomeFiltersState>({}, defaultOutcomeFilterState);
  
outcomeFilterReducer.on(requestOutcomeFilters, state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestOutcomeFiltersSuccess,(state,filters) => ({
    ...state,
    isLoading: false,
    filters
})).on(requestOutcomeFiltersFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
}));


export type TOutcomeResState = {
    resCall: TFilters;
    isLoading:boolean;
    error: any;
  }
  
  
const defaultOutcomeResState: TOutcomeResState = {
    resCall: {},
    isLoading:false,
    error:''
};
  
export const outcomeResReducer = createReducer<TOutcomeResState>({}, defaultOutcomeResState);
  
outcomeResReducer.on(requestOutcomeTopResCall, state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestOutcomeTopResCallSuccess,(state,resCall) => ({
    ...state,
    isLoading: false,
    resCall
})).on(requestOutcomeTopResCallFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
}));


export type TOutcomeInstallState = {
    installCall:OutcomeResponseView;
    isLoading:boolean;
    error: any;
  }
  
  
const defaultOutcomeInstallState: TOutcomeInstallState = {
    installCall: {
        reason1:'',
        reason1Count:0,
        reason2:'',
        reason2Count:0,
        reason3:'',
        reason3Count:0
    },
    isLoading:false,
    error:''
};
  
export const outcomeInstallReducer = createReducer<TOutcomeInstallState>({}, defaultOutcomeInstallState);
  
outcomeInstallReducer.on(requestOutcomeTopInstallCall, state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestOutcomeTopInstallCallSuccess,(state,installCall) => ({
    ...state,
    isLoading: false,
    installCall
})).on(requestOutcomeTopInstallCallFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
}));

