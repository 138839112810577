/*
transforms camelCased labels into human readable worlds, PS any labels cotaining digits and special characters need
rather be stored in a mapping dict with their correct humanReadable label as value
*/

export const transCamelLabels = (string: string) => {

    const isInt = string.search(/([0-9])/);
    if (isInt > -1) {
        return string;
    } else {
        const result = string.replace(/([A-Z-0-9])/g, ' $1');
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult;
    }
};
