
import { call, put, takeLatest } from 'redux-saga/effects';
import { requestLogin, requestLoginFailed, requestLoginSuccess } from './action';
import { Action } from 'redux-act';
import { LoginRequestView, LoginResponseView } from './reducer';
import { post } from '../../api/api';
import { systemError } from '../../utils/notifyToast';


function* loginFunc(action: Action<LoginRequestView>) {
    try {
        const { data } = yield call(() => post('api/token/', action.payload));
        yield put(requestLoginSuccess(data as LoginResponseView));

    } catch (err) {
        systemError(err);
        yield put(requestLoginFailed(err));
    }
}

export function* loginWatchSaga() {
    yield takeLatest(requestLogin, loginFunc);
}
