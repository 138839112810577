import { pastelThemeColors, themeColors } from '../../../scss/themeColors';
import { humanReadableLabels } from '../../dataMapping/apiMappings';
import { transCamelLabels } from '../../labels/transLabels';
import { colorMap } from '../colorMap';

const simpleLineGraphColorMap: { [key: number]: string } = {
    0: themeColors['blue'],
    1: themeColors['green'],
    2: themeColors['red'],

};

export const transLineData = (data: any, meta?: string) => {


    const sortByYearsAndWeeksKey = (a: any, b: any) => {
        const aValue = a.match(/[0-9]+/g);
        const bValue = b.match(/[0-9]+/g);

        if (aValue && bValue) {
            const aIntValue = aValue.reduce((a: any, b: any) => Number(a) * 12 * 5 + Number(b));
            const bIntValue = bValue.reduce((a: any, b: any) => Number(a) * 12 * 5 + Number(b));

            return aIntValue < bIntValue ? -1 : 0;
        }
        else {
            return -1;
        }
    };


    if (Object.keys(data).length > 0) {

        const labels: string[] = meta && meta == 'sortByYearAndWeeks' ? Object.keys(data).sort(sortByYearsAndWeeksKey) : Object.keys(data);

        const legends: string[] = [];

        const firstKey = Object.keys(data)[0];
        // Gen LineData Sections and Legends
        const lineDataSections: { [key: string]: number[] } = {};

        Object.keys(data[firstKey]).forEach((key) => {
            lineDataSections[key] = [];
            legends.push(transCamelLabels(key));
        });

        // Poulate Line Data Sections
        labels.forEach((key: string, index) => {
            Object.keys(data[key]).forEach((childKey) => {
                lineDataSections[childKey].push(data[key][childKey]);
            });
        });

        const lineData = Object.keys(lineDataSections).map((key, index) => {

            return {
                label: legends[index],
                data: Object.values(lineDataSections[key]),
                fill: false,
                backgroundColor: simpleLineGraphColorMap[index],
                borderColor: simpleLineGraphColorMap[index],
            };
        });

        return [
            {
                labels: labels,
                datasets: lineData
            }
        ];
    }
    else {

        return {};
    }

};

export const transSpecialLineData = (data: any, meta: { legendKey: string, labelKey: string, dataKey: string[] | string }) => {

    if (Object.keys(data).length > 0) {
        // Generate legends
        const legends: any[] = [];
        Object.keys(data).forEach((key: string, index) => {
            Object.values(meta.dataKey).forEach(metaKey => { 
                const legendItem = key + ' ' + transCamelLabels(metaKey);
                legends.includes(legendItem) ? null : legends.push(legendItem);
            });
        });

        // graphWeeklyProgressProvince: transSpecialLineData(payload[2].data, { legendKey: 'province', labelKey: 'week', dataKey: ['numberInProgress', 'numberConcluded'] }),

        // Generate Labels
        const labels: string[] = [];
        const firstKeyObj = Object.keys(data)[0];

        Object.keys(data[firstKeyObj]).forEach((key) => {
            const labelItem = key;
            labels.includes(labelItem) ? null : labels.push(labelItem);
        });

        const lineDataSections: { [key: string]: number[] } = {};
        // Gen LindeData Sections
        legends.forEach((_name: string) => {
            lineDataSections[_name] ? null : lineDataSections[_name] = [];
        });

    
        // Populate Line Data Sections
        Object.keys(data).forEach((key) => {
            Object.values(meta.dataKey).forEach(metaKey => {
                const legendItem = key + ' ' + transCamelLabels(metaKey);
                
                const value: number[] = [];
                Object.keys(data[key]).forEach((dataKey) => {
                    value.push(data[key][dataKey][`${metaKey}`]);
                });

                lineDataSections[legendItem] ? lineDataSections[legendItem] = [...value] : null;
            });
        });


        // Packaging Data
        const packagedLineData: any[] = [];
        Object.keys(lineDataSections).forEach((key: any, index) => {

            packagedLineData.push(
                {
                    label: legends[index],
                    data: Object.values(lineDataSections[key]),
                    fill: false,
                    backgroundColor: colorMap[index],
                    borderColor: colorMap[index],
                    borderDash: [key.includes('Concluded') ? 2 : 0]
                }
            );
        });

        return [
            {
                labels: labels,
                datasets: packagedLineData
            }
        ];
    }
    else {
        return {};
    }

};

export const filterLineData = (itemToFilter: string[], data: any) => {
    let filteredData = data.datasets;

    itemToFilter.forEach((itemName) => {
        filteredData = filteredData.filter(function (value: any, index: number) {
            return !value.label.includes(itemName);
        });
    });

    return filteredData;
};





