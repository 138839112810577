import { call, takeLatest } from '@redux-saga/core/effects';
import { systemError } from '../../../utils/notifyToast';
import axiosInstance, { get, post } from '../../../api/api';
import { put } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import { Action } from 'redux-act';
import { clearTechnicianTroubleshootingFilters, postTechnicianTroubleshootingFormData, postTechnicianTroubleshootingFormDataFail, requestExportTechnicianTroubleshootingTableData, requestExportTechnicianTroubleshootingTableDataFail, requestExportTechnicianTroubleshootingTableDataSuccess, requestTechnicianTroubleshootingData, requestTechnicianTroubleshootingDataFail, requestTechnicianTroubleshootingDataSuccess, requestTechnicianTroubleshootingFilters, requestTechnicianTroubleshootingFiltersFail, requestTechnicianTroubleshootingFiltersSuccess, updateQueryParameters, updateQueryParametersSuccess } from './actions';
import axios from 'axios';
import { buildGETUrlWithParams } from '../../../api/misc/misc';

// Request Cati Data Function
function* requestTechnicianTroubleshootingDataFunction(action: Action<{ [key: string]: string | number }>) {
    let data: any;

    try {
        yield axios.all([
            get('/cati/cati_management/', { ...action.payload })
        ]).then((...responses) => {
            data = responses[0];
        });
        yield put(requestTechnicianTroubleshootingDataSuccess(data[0].data));
    } catch (err) {
        systemError();
        yield put(requestTechnicianTroubleshootingDataFail(err));
    }
}

function* requestTechnicianTroubleshootingFiltersFunction(action: Action<{ [key: string]: string | number }>) {
    let data: any;

    try {
        yield axios.all([
            get(buildGETUrlWithParams(action.payload, '/cati/cati_filters/?'))
        ]).then((...responses) => {
            data = responses[0];
        });
        yield put(requestTechnicianTroubleshootingFiltersSuccess(data[0].data));
    } catch (err) {
        systemError();
        yield put(requestTechnicianTroubleshootingFiltersFail(err));
    }
}

// Post CATI Form Data Function
function* postTechnicianTroubleshootingDataFunction(action: any) {
    try {
        yield call(() => axiosInstance.put('/cati/multichoice_input/', action.payload.body));
        const { status } = yield call(() => axiosInstance.put('/cati/kantar_input/', action.payload.body));
        if (status == 200) {
            const { data } = yield call(() => get('/cati/cati_management/', { ...action.payload.queryParameters }));
            yield put(requestTechnicianTroubleshootingDataSuccess(data));
        }
    } catch (err) {
        systemError();
        yield put(postTechnicianTroubleshootingFormDataFail(err));
    }
}

// Update Query Parameters Function
function* updateQueryParametersFunction(action: any) {

    const requestUrl = buildGETUrlWithParams(action.payload, '/cati/cati_management/?');

    try {
        const { data } = yield call(() => get(requestUrl));
        yield put(updateQueryParametersSuccess(data));
    } catch (err) {
        console.log(err);
        systemError();
        yield put(postTechnicianTroubleshootingFormDataFail(err));
    }
}

// Clear Filters Function
function* clearTechnicianTroubleshootingFiltersFunction(action: Action<{[key: string]: string | number}>) {
    try {
        const { data } = yield call(() => get('/cati/cati_management/', {...action.payload }));
        yield put(updateQueryParametersSuccess(data));

    } catch (err) {
        systemError();
        yield put(postTechnicianTroubleshootingFormDataFail(err));
    }
}

// Export/Download Table Data
export function* downloadFileGet(action: any) {

    try {
        const { data } = yield call(() => axiosInstance.get(action.payload.endpoint, {
            responseType: 'blob',
            params: { ...action.payload.queryParameters }
        }));

        yield put(requestExportTechnicianTroubleshootingTableDataSuccess());
        fileDownload(data, action.payload.fileName);
        return { data: true };
    } catch (err) {
        systemError();
        yield put(requestExportTechnicianTroubleshootingTableDataFail(err));
    }
}


// Request Cati Data
export function* requestTechnicianTroubleshootingDataWatchSaga() {
    yield takeLatest(requestTechnicianTroubleshootingData, requestTechnicianTroubleshootingDataFunction);
    yield takeLatest(requestTechnicianTroubleshootingFilters, requestTechnicianTroubleshootingFiltersFunction);
}
// Post CATI Form Data
export function* postTechnicianTroubleshootingFormDataWatchSaga() {
    yield takeLatest(postTechnicianTroubleshootingFormData, postTechnicianTroubleshootingDataFunction);
}
// Update Query Parameters
export function* updateTechnicianTroubleshootingQueryParametersWatchSaga() {
    yield takeLatest(updateQueryParameters, updateQueryParametersFunction);
}
// Clear Filters
export function* clearTechnicianTroubleshootingFiltersWatchSaga() {
    yield takeLatest(clearTechnicianTroubleshootingFilters, clearTechnicianTroubleshootingFiltersFunction);
}

// Export/Download Table Data
export function* exportTechnicianTroubleshootingTableDataWatchSaga() {
    yield takeLatest(requestExportTechnicianTroubleshootingTableData, downloadFileGet);
}
