import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';

export const updateInstallationProgressPerWeekFilters = createAction<TFilters>(
    'INSTALLATION_PROGRESS_PER_WEEK_FILTER_UPDATE'
);

export const requestInstallationProgressPerWeekData = createAction<TFilters>(
    'INSTALLATION_PROGRESS_PER_WEEK_DATA'
);
export const requestInstallationProgressPerWeekDataSuccess = createAction<any>(
    'INSTALLATION_PROGRESS_PER_WEEK_DATA_SUCCESS'
);
export const requestInstallationProgressPerWeekDataFail = createAction<any>(
    'INSTALLATION_PROGRESS_PER_WEEK_DATA_FAIL'
);

export const requestInstallationProgressProgressPerWeekFilters = createAction(
    'INSTALLATION_PROGRESS_PER_WEEK_FILTERS'
);
export const requestInstallationProgressProgressPerWeekFiltersSuccess =
    createAction<any>('GET_INSTALLATION_PROGRESS_PER_WEEK_FILTERS_SUCCESS');
export const requestInstallationProgressProgressPerWeekFiltersFailed =
    createAction<any>('GET_INSTALLATION_PROGRESS_PER_WEEK_FILTERS_FAILED');
