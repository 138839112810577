import { createReducer } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { humanReadableLabels } from '../../../utils/dataMapping/apiMappings';
import { transChartData } from '../../../utils/graphs&charts/transBarData/transformData';

import {
    requestInstallationProgressSnapshotSummaryDataSuccess,
    requestInstallationProgressSnapshotSummaryData,
    requestInstallationProgressSnapshotSummaryDataFail,
    updateFiltersSelected,
    requestInstallationProgressSnapshotSummaryProvinceData,
    requestInstallationProgressSnapshotSummaryProvinceDataSuccess,
    requestInstallationProgressSnapshotSummaryProvinceDataFail,
    requestInstallationProgressSnapshotSummaryFiltersSuccess
} from './actions';
import { TInstallationProgressSnapshotSummaryData } from './types/types';

export type TInstallationProgressSnapshotSummaryState = {
    isLoading: boolean,
    error: any,
    filtersSelected: TFilters | null,
    filters: TFilters, // recruitmentInstallationProgressFilters
    recruitmentInstallationProgress: any,
    recruitmentInstallationProgressProvince: any,
    recruitmentInstallationProgressReasons: any,
    recruitmentInstallationProgressCumulative: any,
};

const defaultState: TInstallationProgressSnapshotSummaryState = {
    isLoading: true,
    error: null,
    filtersSelected: {},
    filters: null,
    recruitmentInstallationProgress: null,
    recruitmentInstallationProgressProvince: null,
    recruitmentInstallationProgressReasons: null,
    recruitmentInstallationProgressCumulative: null,
};


export const installationProgressSnapshotSummaryReducer = createReducer<TInstallationProgressSnapshotSummaryState>({}, defaultState);


installationProgressSnapshotSummaryReducer.on(requestInstallationProgressSnapshotSummaryData, state => ({
    ...state,
    isLoading: true
})).on(requestInstallationProgressSnapshotSummaryDataSuccess, (state, payload: TInstallationProgressSnapshotSummaryData) => ({
    ...state,
    isLoading: false,
    recruitmentInstallationProgress: payload.recruitmentInstallationProgress,
    recruitmentInstallationProgressReasons: payload.recruitmentInstallationProgressReasons,
    recruitmentInstallationProgressCumulative: payload.recruitmentInstallationProgressCumulative,
})).on(requestInstallationProgressSnapshotSummaryDataFail, (state, payload: any) => ({
    ...state,
    error: payload
}))
    .on(requestInstallationProgressSnapshotSummaryFiltersSuccess, (state, filters: TFilters) => ({
        ...state,
        filters: filters,
    }))
    .on(updateFiltersSelected, (state, payload: TFilters) => ({
        ...state,
        filtersSelected: {
            ...payload
        }
    }))
    .on(requestInstallationProgressSnapshotSummaryProvinceData, state => ({
        ...state,
    })).on(requestInstallationProgressSnapshotSummaryProvinceDataSuccess, (state, payload: any) => ({
        ...state,
        recruitmentInstallationProgressProvince: transChartData(payload.recruitmentInstallationProgressProvince, humanReadableLabels, 'bar'),
    })).on(requestInstallationProgressSnapshotSummaryProvinceDataFail, (state, payload: any) => ({
        ...state,
        error: payload
    }));
