
import { call, put, takeLatest } from 'redux-saga/effects';
import { requestMoreProgressTracker, requestProgressTracker, requestProgressTrackerDownload, requestProgressTrackerDownloadFailed, requestProgressTrackerDownloadSuccess, requestProgressTrackerFailed, requestProgressTrackerFilters, requestProgressTrackerFiltersFailed, requestProgressTrackerFiltersSuccess, requestProgressTrackerSuccess } from './action';
import { downloadFilePost, post } from '../../../api/api';
import { ProgressTrackerFiltersRespose, ProgressTrackerRequestView, ProgressTrackerResponseView } from './reducer';
import { Action } from 'redux-act';
import { fetchProgressTrackerData } from '../../../utils/progressTracker/progressTrackerTransData';
import { success, systemError } from '../../../utils/notifyToast';


function* progressTrackerFunc(action: Action<ProgressTrackerRequestView>) {
    try {
        const { page_y, filters, originalData, } = action.payload;
        const { data } = yield call(() => post('/progress_tracker/', { page_y, filters }));
        const totalPages = data.totalPages;
        const currentPage = data.curentPage;
        const progressTrackerData = fetchProgressTrackerData(data.data);
        const totalRecords = data.totalRecords;
        yield put(requestProgressTrackerSuccess({ progressTrackerData, totalPages, currentPage, totalRecords } as unknown as ProgressTrackerResponseView));
    } catch (err) {
        systemError(err);
        yield put(requestProgressTrackerFailed(err));
    }
}

function* progressTrackerFilterFunc(action: Action<{ [key: string]: string | number }>) {
    try {
        const { data } = yield call(() => post('/progress_tracker/filters/', action.payload));
        yield put(requestProgressTrackerFiltersSuccess(data as ProgressTrackerFiltersRespose));
    } catch (err) {
        systemError(err);
        yield put(requestProgressTrackerFiltersFailed(err));
    }
}

function* progressTrackerFileExportFunc(action: Action<{ [key: string]: string | number }>) {
    try {
        const { data } = yield call(() => downloadFilePost('/progress_tracker/export_excel/', action.payload, 'progress_tracker.xlsx'));
        success('File downloaded successfully');
        yield put(requestProgressTrackerDownloadSuccess());
    } catch (err) {
        systemError();
        yield put(requestProgressTrackerDownloadFailed(err));
    }
}

export function* progressTrackerWatchSaga() {
    yield takeLatest(requestProgressTracker, progressTrackerFunc);
    yield takeLatest(requestProgressTrackerFilters, progressTrackerFilterFunc);
    yield takeLatest(requestMoreProgressTracker, progressTrackerFunc);
    yield takeLatest(requestProgressTrackerDownload, progressTrackerFileExportFunc);
}
