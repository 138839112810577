
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    requestMoreReportingTracker,
    requestReportingTracker,
    requestReportingTrackerDownload,
    requestReportingTrackerDownloadFailed,
    requestReportingTrackerDownloadSuccess,
    requestReportingTrackerFailed,
    requestReportingTrackerFilters,
    requestReportingTrackerFiltersFailed,
    requestReportingTrackerFiltersSuccess,
    requestReportingTrackerSuccess
} from './action';
import { downloadFilePost, post } from '../../../api/api';
import {
    ReportingTrackerFiltersRespose,
    ReportingTrackerRequestView,
    ReportingTrackerResponseView
} from './reducer';
import { Action } from 'redux-act';
import { success , systemError } from '../../../utils/notifyToast';
import { fetchReportingTrackerData } from '../../../utils/reportingTraccker/reportingTrackerDataTrans';
import { TFilters } from '../../../types/commonTypes';


function* reportingTrackerFunc(action: Action<ReportingTrackerRequestView>){
    try{
        const { page_y,filters,originalData, } = action.payload;
        const { data } = yield call(() => post('/reporting_tracker/',{ page_y, filters}));
        const totalPages = data.totalPages;
        const currentPage = data.curentPage;
        const reportingTrackerData = fetchReportingTrackerData(data.reportingTrackers);
        yield put(requestReportingTrackerSuccess({ reportingTrackerData , totalPages, currentPage} as unknown as ReportingTrackerResponseView));
    }catch (err) {
        systemError(err);
        yield put(requestReportingTrackerFailed(err));
    }
}

function* reportingTrackerFilterFunc(action: Action<TFilters>){
    try{
        const { data } = yield call(() => post('/reporting_tracker/filters/',action.payload));
        yield put(requestReportingTrackerFiltersSuccess(data as ReportingTrackerFiltersRespose));
    }catch (err) {
        systemError(err);
        yield put(requestReportingTrackerFiltersFailed(err));
    }
}

function* reportingTrackerFileExportFunc(action: Action<TFilters>){
    try{
        yield call(() => downloadFilePost('/reporting_tracker/export_excel/', action.payload, 'reporting_tracker.xlsx'));
        yield put(requestReportingTrackerDownloadSuccess());
        success('File downloaded successfully');
    }catch (err) {
        systemError();
        yield put(requestReportingTrackerDownloadFailed(err));
    }
}

export function* reportingTrackerWatchSaga(){
    yield takeLatest(requestReportingTracker,reportingTrackerFunc);
    yield takeLatest(requestReportingTrackerFilters,reportingTrackerFilterFunc);
    yield takeLatest(requestMoreReportingTracker,reportingTrackerFunc);
    yield takeLatest(requestReportingTrackerDownload,reportingTrackerFileExportFunc);
}
