import _ from 'lodash';
import { themeColors } from '../../../scss/themeColors';
import { humanReadableLabels } from '../../dataMapping/apiMappings';
import { transCamelLabels } from '../../labels/transLabels';
import { systemError } from '../../notifyToast';
import { basicColorMap } from '../colorMap';
import { optionsActualBarPecentage, optionsTotalsBarPecentage } from '../optionTemplates';
import { processLabels } from '../proccessingUtils';

/*
Utility function for processing and packaging data for Pie Chart Consumtion
*/
export const transPieData = (
    data: { [key: string]: number },
    labelsToIgnore?: string[] | null,
    max?: number | null, // max value for % calcs
    backgroundColors?: string[] | null,
    borderColors?: string[] | null,
    labelMatching?: { [key: string | number]: string } // Custom label matching
) => {

    try {
        const maxValue = max ? max : _.sum(Object.values(data) as number[]);
        const labels = labelsToIgnore ? Object.keys(data).filter(key => !labelsToIgnore.includes(key)) : Object.keys(data);

        const pieSet: any = new Set(Object.values(data));
        const validateData = pieSet.has(0) && pieSet.size < 2 ? false : true;

        if (validateData) {
            const _backgroundColor = backgroundColors ? backgroundColors : Object.values(basicColorMap).map(str => `${str.substring(0, str.length - 1)}, 0.8)`);
            const _borderColor = borderColors ? borderColors : Object.values(basicColorMap);

            const dataLabels = labels.map((key: string, index: number) => {
                const _percentage = Number((100 / maxValue) * data[key]);
                return `${labelMatching && labelMatching[labels[index]] ? labelMatching[labels[index]] :
                    (humanReadableLabels[labels[index]] ? humanReadableLabels[labels[index]] :
                        transCamelLabels(labels[index]))} ${_percentage.toFixed(2)}%`;
            });

            const _data = labelsToIgnore ? labels.map(key => data[key]) : Object.values(data);

            return [
                {
                    labels: dataLabels,
                    datasets: [
                        {
                            data: _data,
                            backgroundColor: _backgroundColor,
                            borderColor: _borderColor,
                            borderWidth: 1,
                        },
                    ],
                }
            ];
        }
    } catch (err) {
        console.log(err);
        return systemError(err);

    }
    return [];
};

export const transChartDataPercentage = (
    data: any,
    match: {[key: string]: string},
    type: string,
    meta?: string
) => {
    const _optionsTotalsBarPecentage = optionsTotalsBarPecentage;
    const _optionsActualBarPecentage = optionsActualBarPecentage;


    let dataValues: any;
    let dataLabels;
    const backgroundColor = [themeColors['blue']];
    const borderColor = [themeColors['blueStates']];

    if (type === 'pie') {
        dataValues = [data['nonPolling'], data['polling']];
        dataLabels = ['Non Polling', 'Polling'];
    } else if (type === 'bar') {
        dataLabels = processLabels(data, match);
        dataValues = Object.values(data).map((item, i) => {
            return item;
        });
    }

    let max = Math.max(...dataValues);

    if (meta == 'max') {
        max = dataValues.reduce((a: number, b: number) => a + b);
        _optionsTotalsBarPecentage.scales.y.max = 100;
    }

    dataValues = Object.values(dataValues).map((item: any, i) => {
        return Math.ceil((100 / max) * item);
    });

    return [
        {
            labels: dataLabels,
            datasets: [
                {
                    data: dataValues,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                },
            ],
        },
        meta == 'max' ? _optionsTotalsBarPecentage : _optionsActualBarPecentage,
    ];
};
