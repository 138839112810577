import { call, put, takeLatest } from 'redux-saga/effects';
import { post, update } from '../../api/api';
import { changePasswordError, changePasswordRequest, changePasswordSuccess, confirmResetPasswordError, confirmResetPasswordRequest, confirmResetPasswordSuccess, resetPasswordError, resetPasswordRequest, resetPasswordSuccess, WATCH_CHANGE_PASSWORD_REQUEST, WATCH_CONFIRM_RESET_PASSWORD_REQUEST, WATCH_RESET_PASSWORD_REQUEST } from './actions';

function* resetPassword(object: any) {
    const { data, onSuccess, onError } = object.payload;
    yield put(resetPasswordRequest());
    try {
        yield call(() => post('/api/password_reset/', { email: data.username, url: `${window.location.origin}/password_reset/confirm/` }));
        yield put(resetPasswordSuccess());
        onSuccess();

    } catch (error) {
        yield put(resetPasswordError());
        onError();

    }
}

function* changePassword(object: any) {
    const { data: {oldPassword, newPassword}, onSuccess, onError } = object.payload;
    yield put(changePasswordRequest());
    try {
        const {data: {message}} =  yield call(() => update('/api/change_password', { old_password: oldPassword, new_password: newPassword }));
        yield put(changePasswordSuccess());
        onSuccess(message);

    } catch (error) {
        yield put(changePasswordError());
        onError();

    }
}

function* confirmResetPassword(object: any) {
    const { data, onSuccess, onError } = object.payload;
    yield put(confirmResetPasswordRequest());
    try {
        yield call(() => post('/api/password_reset/confirm/', { ...data }));
        yield put(confirmResetPasswordSuccess());
        onSuccess();

    } catch (error) {
        yield put(confirmResetPasswordError());
        onError();

    }
}

export function* passwordSaga() {
    yield takeLatest(WATCH_RESET_PASSWORD_REQUEST, resetPassword);
    yield takeLatest(WATCH_CHANGE_PASSWORD_REQUEST, changePassword);
    yield takeLatest(WATCH_CONFIRM_RESET_PASSWORD_REQUEST, confirmResetPassword);
}