import { createReducer } from 'redux-act';
import {
    requestExceptionList,
    requestExceptionListDownload,
    requestExceptionListDownloadFailed,
    requestExceptionListDownloadSuccess,
    requestExceptionListFailed,
    requestExceptionListFilters,
    requestExceptionListFiltersFailed,
    requestExceptionListFiltersSuccess,
    requestExceptionListSuccess,
    requestExceptionListRemovalReasons,
    requestExceptionListRemovalReasonsSuccess,
    requestExceptionListRemovalReasonsFailed,
    updateExceptionList,
    updateExceptionListFailed,
    updateExceptionListSuccess
} from './action';


export type ExceptionListResponseView = {

}

export type TExceptionListState = {
    exception: ExceptionListResponseView[];
    removalReasons: string[];
    isLoading: boolean;
    isRemovalReasonsLoading: boolean;
    error: any;
    isUpdating: boolean;
    updatigError: any;
}


const defaultExceptionListStatee: TExceptionListState = {
    exception: [],
    removalReasons: [],
    isRemovalReasonsLoading: false,
    isLoading: false,
    error: '',
    isUpdating: false,
    updatigError: ''
};

export const exceptionListReducer = createReducer<TExceptionListState>({}, defaultExceptionListStatee);

exceptionListReducer.on(requestExceptionList, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestExceptionListRemovalReasons, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestExceptionListSuccess, (state, exception) => ({
    ...state,
    isLoading: false,
    exception
})).on(requestExceptionListRemovalReasonsSuccess, (state, removalReasons) => ({
    ...state,
    isLoading: false,
    removalReasons
})).on(requestExceptionListFailed, (state, error) => ({
    ...state,
    isLoading: false,
    error
})).on(requestExceptionListRemovalReasonsFailed, (state, error) => ({
    ...state,
    isLoading: false,
    error
})).on(updateExceptionList, state => ({
    ...state,
    isUpdating: true,
    error: ''
})).on(updateExceptionListSuccess, state => ({
    ...state,
    isUpdating: false,
})).on(updateExceptionListFailed, (state, updatigError) => ({
    ...state,
    updatigError
}));


export type ExceptionListFiltersRespose = {
    country: string[];
    service: string[];
    noContacts: number[];
    noSubmissionsToTs: number[];
}

export type TExceptionListFiltersState = {
    filters: ExceptionListFiltersRespose;
    isLoading: boolean;
    error: any;
}


const defaultExceptionListFilterState: TExceptionListFiltersState = {
    filters: {
        country: [],
        service: [],
        noContacts: [],
        noSubmissionsToTs: []
    },
    isLoading: false,
    error: ''
};

export const exceptionListFilterReducer = createReducer<TExceptionListFiltersState>({}, defaultExceptionListFilterState);

exceptionListFilterReducer.on(requestExceptionListFilters, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestExceptionListFiltersSuccess, (state, filters) => ({
    ...state,
    isLoading: false,
    filters
})).on(requestExceptionListFiltersFailed, (state, error) => ({
    ...state,
    isLoading: false,
    error
}));


export type TExceptionListFileExportState = {
    file: string;
    isLoading: boolean;
    error: any;
}

const defaultFileExportState: TExceptionListFileExportState = {
    file: '',
    isLoading: false,
    error: ''
};

export const exceptionListFileExportReducer = createReducer<TExceptionListFileExportState>({}, defaultFileExportState);

exceptionListFileExportReducer.on(requestExceptionListDownload, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestExceptionListDownloadSuccess, state => ({
    ...state,
    isLoading: false,
})).on(requestExceptionListDownloadFailed, (state, error) => ({
    ...state,
    isLoading: false,
    error
}));
