import { createReducer } from 'redux-act';
import { TQuotaDesignFilterState, TQuotaDesignState } from '../../../types';
import { 
    exportQuotaDesignWeeksCompounded,
    exportQuotaDesignWeeksCompoundedFailed,
    exportQuotaDesignWeeksCompoundedSuccess,
    requestQuotaDesign, 
    requestQuotaDesignFailed, 
    requestQuotaDesignFilters, 
    requestQuotaDesignFiltersFailed, 
    requestQuotaDesignFiltersSuccess, 
    requestQuotaDesignReset, 
    requestQuotaDesignSuccess, 
    requestQuotaDesignWeeksCompounded, 
    requestQuotaDesignWeeksCompoundedFailed, 
    requestQuotaDesignWeeksCompoundedReset, 
    requestQuotaDesignWeeksCompoundedSuccess, 
    saveQuotaDesignPriority, 
    saveQuotaDesignPriorityFailed, 
    saveQuotaDesignPrioritySuccess, 
    saveQuotaDesignWeeks,
    saveQuotaDesignWeeksFailed,
    saveQuotaDesignWeeksSuccess
} from './action';

const defaultQuota: TQuotaDesignState = {
    quotaDesignData:[],
    quotaDesignWeekComponded:[],
    isLoading: false,
    error: null,
    isSaving: false,
    isLoadingQuotaDesignWeekComponded: false,
    quotaDesignWeekCompondedError:'',
    isDownloading: false,
    downloadError: ''
};

export const quotaDesignReducer = createReducer<TQuotaDesignState>({}, defaultQuota);

quotaDesignReducer.on(requestQuotaDesign , state => ({
    ...state,
    isLoading:true,
    error:'',
})).on(requestQuotaDesignSuccess , (state,quotaDesignData) => ({
    ...state,
    isLoading:false,
    quotaDesignData
})).on(requestQuotaDesignFailed,(state,error) => ({
    ...state,
    isLoading:false,
    error
})).on(requestQuotaDesignReset,state => ({
    ...state,
    ...defaultQuota
})).on(saveQuotaDesignWeeks,state => ({
    ...state,
    isSaving: true,
})).on(saveQuotaDesignWeeksSuccess,state => ({
    ...state,
    isSaving: false,
})).on(saveQuotaDesignWeeksFailed,state => ({
    ...state,
    isSaving: false,
})).on(saveQuotaDesignPriority,state => ({
    ...state,
    isSaving: true,
})).on(saveQuotaDesignPrioritySuccess,state => ({
    ...state,
    isSaving: false,
})).on(saveQuotaDesignPriorityFailed,state => ({
    ...state,
    isSaving: false,
})).on(requestQuotaDesign , state => ({
    ...state,
    isLoading:true,
    error:'',
})).on(requestQuotaDesignSuccess , (state,quotaDesignData) => ({
    ...state,
    isLoading:false,
    quotaDesignData
})).on(requestQuotaDesignFailed,(state,error) => ({
    ...state,
    isLoading:false,
    error
})).on(requestQuotaDesignReset,state => ({
    ...state,
    ...defaultQuota
})).on(requestQuotaDesignWeeksCompounded , state => ({
    ...state,
    isLoadingQuotaDesignWeekComponded:true,
    quotaDesignWeekCompondedError:'',
})).on(requestQuotaDesignWeeksCompoundedSuccess , (state,quotaDesignWeekComponded) => ({
    ...state,
    isLoadingQuotaDesignWeekComponded:false,
    quotaDesignWeekComponded
})).on(requestQuotaDesignWeeksCompoundedFailed,(state,quotaDesignWeekCompondedError) => ({
    ...state,
    isLoadingQuotaDesignWeekComponded:false,
    quotaDesignWeekCompondedError
})).on(requestQuotaDesignWeeksCompoundedReset,state => ({
    ...state,
    ...defaultQuota
})).on(exportQuotaDesignWeeksCompounded, state => ({
    ...state,
    isDownloading: true,
    downloadError:''
})).on(exportQuotaDesignWeeksCompoundedSuccess , state => ({
    ...state,
    isDownloading:false,
})).on(exportQuotaDesignWeeksCompoundedFailed, (state,downloadError) => ({
    ...state,
    isDownloading:false,
    downloadError
}));



const defaultFilters: TQuotaDesignFilterState = {
    filters: {
        country:[],
        services:[]
    },
    isLoading: false,
    error: null,
};

export const quotaDesignFiltersReducer = createReducer<TQuotaDesignFilterState>({}, defaultFilters);

quotaDesignFiltersReducer.on(requestQuotaDesignFilters , state => ({
    ...state,
    isLoading:true,
    error:'',
})).on(requestQuotaDesignFiltersSuccess , (state,filters) => ({
    ...state,
    isLoading:false,
    filters
})).on(requestQuotaDesignFiltersFailed,(state,error) => ({
    ...state,
    isLoading:false,
    error
}));



