import { createReducer } from 'redux-act';
import { EAuthToken } from '../../types/authTokens';
import { TUserAccessState } from '../../types/userAccess.types';
import { requestLogOut } from '../login/action';
import { requestUserAccess, requestUserAccessFailed, requestUserAccessSuccess } from './action';

const defaultUserAccessState: TUserAccessState = {
    access: {
        totalPollingStats: false,
        progressTracker: false,
        reportingTracker: false,
        fileIngestionStats: false,
        troubleShootingActivities: false,
        batchTracker: false,
        technicianListTroubleshooting: false,
        exceptionsList: false,
        weeklyProgress: false,
        submissionAndMonitoring: false,
        userType: '',
        troubleShootingOutcomes: false,
        admin: false,
        country: '',
        fiscalWeeks: false,
        installerProgressPerWeek: false,
        quotaDesign: false,
        installationProgressSnapshotSummary: false,
        changePassword: false,
        quotaSplit: false,
        recruitmentProgressPerWeek: false,
        snapshotSummary: false,

    },
    isLoading: false,
    error: ''
};

export const userAccessReducer = createReducer<TUserAccessState>({}, defaultUserAccessState);

userAccessReducer.on(requestUserAccess, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestUserAccessSuccess, (state, access) => {
    return {
        ...state,
        isLoading: false,
        access: { ...access }
    };
}).on(requestUserAccessFailed, (state, error) => {
    localStorage.removeItem(EAuthToken.ACCESS_TOKEN);
    localStorage.removeItem(EAuthToken.REFRESH_TOKEN);
    localStorage.clear();
    requestLogOut();
    window.location.href = "/login";
    return {
        ...state,
        isLoading: false,
        error
    };
});
