import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { OutcomeResponseView } from './reducer';

export const requestOutcome = createAction< TFilters>('GET_OUTCOMES_REQUEST');
export const requestOutcomeSuccess = createAction<OutcomeResponseView>('GET_OUTCOMES_SUCCESS_REQUEST');
export const requestOutcomeFailed = createAction<any>('GET_OUTCOMES_FAILED_REQUEST');


export const requestOutcomeFilters = createAction< TFilters>('GET_OUTCOMES_FILTER_REQUEST');
export const requestOutcomeFiltersSuccess = createAction< TFilters>('GET_OUTCOMES_FILTER_SUCCESS_REQUEST');
export const requestOutcomeFiltersFailed = createAction<any>('GET_OUTCOMES_FAILED_FILTER_REQUEST');


export const requestOutcomeTopResCall = createAction< TFilters>('GET_OUTCOMES_RES_CALL_REQUEST');
export const requestOutcomeTopResCallSuccess = createAction<OutcomeResponseView>('GET_OUTCOMES_RES_CALL_SUCCESS_REQUEST');
export const requestOutcomeTopResCallFailed = createAction<any>('GET_OUTCOMES_RES_CALL_FAILED_REQUEST');


export const requestOutcomeTopInstallCall = createAction< TFilters>('GET_OUTCOMES_INSTALL_CALL_REQUEST');
export const requestOutcomeTopInstallCallSuccess = createAction<OutcomeResponseView>('GET_OUTCOMES_INSTALL_CALL_SUCCESS_REQUEST');
export const requestOutcomeTopInstallCallFailed = createAction<any>('GET_OUTCOMES_INSTALL_CALL_FAILED_REQUEST');








