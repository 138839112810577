import { createAction } from 'redux-act';

type ACTION = {
    data?: any,
    onSuccess: (text?: string) => void,
    onError: (text?: string) => void
}

export const RESET_PASSWORD = {
    REQUEST: 'RESET_PASSWORD/REQUEST',
    SUCCESS: 'RESET_PASSWORD/SUCCESS',
    ERROR: 'RESET_PASSWORD/ERROR'
};

export const resetPasswordRequest = createAction(RESET_PASSWORD.REQUEST);
export const resetPasswordSuccess = createAction(RESET_PASSWORD.SUCCESS);
export const resetPasswordError = createAction(RESET_PASSWORD.ERROR);

export const WATCH_RESET_PASSWORD_REQUEST = 'WATCH_RESET_PASSWORD_REQUEST';

export const watchResetPasswordRequest = createAction<ACTION>(WATCH_RESET_PASSWORD_REQUEST);

export const CHANGE_PASSWORD = {
    REQUEST: 'CHANGE_PASSWORD/REQUEST',
    SUCCESS: 'CHANGE_PASSWORD/SUCCESS',
    ERROR: 'CHANGE_PASSWORD/ERROR'
};

export const changePasswordRequest = createAction(CHANGE_PASSWORD.REQUEST);
export const changePasswordSuccess = createAction(CHANGE_PASSWORD.SUCCESS);
export const changePasswordError = createAction(CHANGE_PASSWORD.ERROR);

export const WATCH_CHANGE_PASSWORD_REQUEST = 'WATCH_CHANGE_PASSWORD_REQUEST';

export const watchChangePasswordRequest = createAction<ACTION>(WATCH_CHANGE_PASSWORD_REQUEST);

export const CONFIRM_RESET_PASSWORD = {
    REQUEST: 'CONFIRM_RESET_PASSWORD/REQUEST',
    SUCCESS: 'CONFIRM_RESET_PASSWORD/SUCCESS',
    ERROR: 'CONFIRM_RESET_PASSWORD/ERROR'
};

export const confirmResetPasswordRequest = createAction(CONFIRM_RESET_PASSWORD.REQUEST);
export const confirmResetPasswordSuccess = createAction(CONFIRM_RESET_PASSWORD.SUCCESS);
export const confirmResetPasswordError = createAction(CONFIRM_RESET_PASSWORD.ERROR);

export const WATCH_CONFIRM_RESET_PASSWORD_REQUEST = 'WATCH_CONFIRM_RESET_PASSWORD_REQUEST';

export const watchConfirmResetPasswordRequest = createAction<ACTION>(WATCH_CONFIRM_RESET_PASSWORD_REQUEST);