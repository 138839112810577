import { call, takeLatest } from '@redux-saga/core/effects';
import { systemError } from '../../../utils/notifyToast';
import { exportSubmissionAndMonitoringDemmographicsTable, exportSubmissionAndMonitoringDemmographicsTableSuccess, exportSubmissionAndMonitoringTable, exportSubmissionAndMonitoringTableFail, exportSubmissionAndMonitoringTableSuccess, postCommentSubmissionAndMonitoringFormData, postCommentSubmissionAndMonitoringFormDataFail, postCommentSubmissionAndMonitoringFormDataSuccess, postSubmissionAndMonitoringFormData, postSubmissionAndMonitoringFormDataFail, postSubmissionAndMonitoringFormDataSuccess, requestSubmissionAndMonitoringData, requestSubmissionAndMonitoringDataFail, requestSubmissionAndMonitoringDataSuccess, requestSubmissionAndMonitoringFilters, requestSubmissionAndMonitoringFiltersFail, requestSubmissionAndMonitoringFiltersSuccess, updateFilters } from './actions';
import axiosInstance, { downloadFilePost, get, post } from '../../../api/api';
import { put } from 'redux-saga/effects';
import { Action } from 'redux-act';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { toggleLoadingCursor } from '../../home/action';
import { TFormBody } from '../../../types/formTypes';
import { buildGETUrlWithParams } from '../../../api/misc/misc';


type TPayload = {
    [key: string]: string | number
}


// Request Table and Filter Data
function* requestDataFunction(action: any) {
    try {
        let data = {};

        yield axios
            .all([
                get('/submission_monitoring/'),
                get('/submission_monitoring/filters/')
            ])
            .then(
                axios.spread((...responses) => {
                    data = {
                        data: responses[0].data,
                        filterData: responses[1].data,
                    };
                })
            );

        yield put(requestSubmissionAndMonitoringDataSuccess(data));
    } catch (err) {
        console.log(err);
        systemError(err);
        yield put(requestSubmissionAndMonitoringDataFail(err));
    }
}

// Request Filtered Table and Filter Data
function* requestFilteredDataFunction(action: Action<TPayload>) {
    try {
        let data = {};

        yield axios
            .all([
                get(buildGETUrlWithParams(action.payload, '/submission_monitoring/?')),
            ])
            .then(
                axios.spread((...responses) => {
                    data = {
                        data: responses[0].data
                    };
                })
            );

        yield put(requestSubmissionAndMonitoringDataSuccess(data));
    } catch (err) {
        console.log(err);
        systemError(err);
        yield put(requestSubmissionAndMonitoringDataFail(err));
    }
}

function* requestFiltersFunction(action: Action<TPayload>) {
    try {
        let data = {};
        yield axios
            .all([
                get(buildGETUrlWithParams(action.payload,'/submission_monitoring/filters/?'))
            ])
            .then(
                axios.spread((...responses) => {
                    data = {
                        filterData: responses[0].data,
                    };
                })
            );
        yield put(requestSubmissionAndMonitoringFiltersSuccess(data));
    } catch (err) {
        console.log(err);
        systemError(err);
        yield put(requestSubmissionAndMonitoringFiltersFail(err));
    }
}


// Helper function for: postSubmissionAndMonitoringFormDataFunction
function genHouseholdPk(formBody: TFormBody) {
    const idList = formBody.map(item => {
        return item.householdPk;
    });

    return idList;
}

// Post Submissions
function* postSubmissionAndMonitoringFormDataFunction(action: Action<TFormBody>) {
    yield put(toggleLoadingCursor(true));
    const idList = genHouseholdPk(action.payload);

    const data: any = {};
    try {

        yield axios.all([
            post('/submission_monitoring/', action.payload),
            post('/submission_monitoring/demographics/', idList)
        ]).then(axios.spread((...responses) => {
            data['tableExportData'] = responses[1].data;
        }));

        yield put(postSubmissionAndMonitoringFormDataSuccess(data));
        yield put(toggleLoadingCursor(false));
    } catch (err) {
        console.log(err);
        systemError(err);
        yield put(postSubmissionAndMonitoringFormDataFail(err));
        yield put(toggleLoadingCursor(false));
    }
}

// Post Comments
function* postSubmissionAndMonitoringCommentFormDataFunction(action: any) {

    // Update Data State for Optimum Performance if Obj updates return 201, Instead of re-loading entire component and losing table order in user session.
    function updateState(updatedData: TFormBody, dataList: any) {

        const data = dataList;

        // Loop throguh both data lists and update any objects containing customerId's present in the latter.
        updatedData.forEach(updatedDataObj => {
            const customerId = updatedDataObj.customerId;

            Object.keys(data).forEach(key => {
                data[key].forEach((dataObj: any, index: number) => {
                    if (dataObj.customerId == customerId) {
                        data[key][index] = {
                            ...updatedDataObj
                        };
                    }
                });
            });
        });

        return data;
    }


    yield put(toggleLoadingCursor(true));
    try {

        yield call(() => post('/submission_monitoring/', action.payload.body));

        const data = updateState(action.payload.body, action.payload.dataList);

        yield put(postCommentSubmissionAndMonitoringFormDataSuccess(data));
        yield put(toggleLoadingCursor(false));
    } catch (err) {
        console.log(err);
        systemError(err);
        yield put(postCommentSubmissionAndMonitoringFormDataFail(err));
        yield put(toggleLoadingCursor(false));
    }
}

// Export/Download Table Data
export function* downloadFileGet(action: any) {

    try {
        const { data } = yield call(() => axiosInstance.get(action.payload.endpoint, {
            responseType: 'blob'
        }));

        yield put(exportSubmissionAndMonitoringTableSuccess());
        fileDownload(data, action.payload.fileName);
        return { data: true };
    } catch (err) {
        systemError();
        yield put(exportSubmissionAndMonitoringTableFail(err));
    }
}

// Export/Download Demographics Table Data
export function* downloadDemographicsExcel(action: any) {

    try {
        yield call(() => downloadFilePost(action.payload.endpoint,
            {
                households: genHouseholdPk(action.payload.formBody),
                filters: action.payload.filters,
            },
            'submission_monitoring_demographic_data.xlsx'));

        yield put(exportSubmissionAndMonitoringDemmographicsTableSuccess());
    } catch (err) {
        systemError();
        yield put(exportSubmissionAndMonitoringTableFail(err));
    }
}


export function* requestSubmissionAndMonitoringDataWatchSaga() {
    yield takeLatest(requestSubmissionAndMonitoringData, requestDataFunction);
    yield takeLatest(updateFilters, requestFilteredDataFunction);
    yield takeLatest(postSubmissionAndMonitoringFormData, postSubmissionAndMonitoringFormDataFunction);
    yield takeLatest(postCommentSubmissionAndMonitoringFormData, postSubmissionAndMonitoringCommentFormDataFunction);
    yield takeLatest(exportSubmissionAndMonitoringTable, downloadFileGet);
    yield takeLatest(exportSubmissionAndMonitoringDemmographicsTable, downloadDemographicsExcel);
    yield takeLatest(requestSubmissionAndMonitoringFilters, requestFiltersFunction);
}
