import { createReducer } from 'redux-act';
import { 
    requestMoreReportingTracker,
    requestReportingTracker, 
    requestReportingTrackerDownload, 
    requestReportingTrackerDownloadFailed, 
    requestReportingTrackerDownloadSuccess, 
    requestReportingTrackerFailed, 
    requestReportingTrackerFilters, 
    requestReportingTrackerFiltersFailed, 
    requestReportingTrackerFiltersSuccess, 
    requestReportingTrackerSuccess 
} from './action';

export type ReportingTrackerRequestView = {
  page_y: number;
  filters: any;
  originalData: any[];
}


export type ReportingTrackerFiltersRespose = {
  country: string[];
  service:string[];
  hhStatus: string[];
  hhSubStatus: string[];
  hhUniquePollingStatus: string[];
  pollingBucket: string[];
  viewerExperience: string[];
  decoderType: string[];
  modem: string[];
  connectedServicesIndicator: string[];
  package: string[];
  network: string[];
  tenure: string[];
  province: string[];
  modemType: string[];
  modemSupplier: string[];
  recruitmentPhase: string[];
  tsBucketClassification: string[];
  connectedFlag: string[];
  equipmentSetUp: string[];
  noOfSmartcards: number[];
  daysSinceLastPoll: number[];
  submission1BatchNumber: number[];
  submission2BatchNumber: number[];
}

export type ReportingTrackerResponseView = {
  reportingTrackerData:any;
  totalPages:number;
  currentPage:number;
}

export type TReportingTrackerState = {
  reportingTrackerData:any;
  totalPages:number;
  currentPage:number;
  isLoading:boolean;
  isLoadingMoreRows: boolean;
  error: any;
}


const defaultReportingTrackerState: TReportingTrackerState = {
    reportingTrackerData:{},
    totalPages:1,
    currentPage:0,
    isLoading:false,
    isLoadingMoreRows: false,
    error:''
};

export const reportingTrackerReducer = createReducer<TReportingTrackerState>({}, defaultReportingTrackerState);

reportingTrackerReducer.on(requestReportingTracker, state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestReportingTrackerSuccess,(state,payload) => ({
    ...state,
    isLoading: false,
    isLoadingMoreRows: false,
    reportingTrackerData: payload,
    totalPages: payload.totalPages === 0 ? payload.reportingTrackerData.originalData.length : payload.totalPages,
    currentPage: payload.currentPage,
})).on(requestReportingTrackerFailed,(state,payload) => ({
    ...state,
    isLoading: false,
    isLoadingMoreRows: false,
    error: payload
})).on(requestMoreReportingTracker,state => ({
    ...state,
    isLoadingMoreRows:true
}));


export type TReportingTrackerFiltersState = {
  reportingTrackerFilters:ReportingTrackerFiltersRespose;
  isLoading:boolean;
  error: any;
}


const defaultReportingTrackerFilterState: TReportingTrackerFiltersState = {
    reportingTrackerFilters: {
        country:[],
        service:[],
        hhStatus:[],
        hhSubStatus:[],
        hhUniquePollingStatus:[],
        pollingBucket:[],
        viewerExperience:[],
        decoderType:[],
        modem:[],
        connectedServicesIndicator:[],
        package:[],
        network:[],
        tenure:[],
        province:[],
        modemType:[],
        modemSupplier:[],
        recruitmentPhase:[],
        tsBucketClassification:[],
        connectedFlag:[],
        equipmentSetUp:[],
        noOfSmartcards:[],
        daysSinceLastPoll:[],
        submission1BatchNumber:[],
        submission2BatchNumber:[],
    },
    isLoading:false,
    error:''
};

export const reportingTrackerFilterReducer = createReducer<TReportingTrackerFiltersState>({}, defaultReportingTrackerFilterState);

reportingTrackerFilterReducer.on(requestReportingTrackerFilters, state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestReportingTrackerFiltersSuccess,(state,payload) => ({
    ...state,
    isLoading: false,
    reportingTrackerFilters: payload
})).on(requestReportingTrackerFiltersFailed,(state,payload) => ({
    ...state,
    isLoading: false,
    error: payload
}));
    

export type TReportingTrackerFileExportState = {
    file:string;
    isLoading:boolean;
    error: any;
  }
  
const defaultFileExportState: TReportingTrackerFileExportState = {
    file:'',
    isLoading: false,
    error:''
};
  
export const reportingTrackerFileExportReducer = createReducer<TReportingTrackerFileExportState>({}, defaultFileExportState);
  
reportingTrackerFileExportReducer.on(requestReportingTrackerDownload,state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestReportingTrackerDownloadSuccess,state => ({
    ...state,
    isLoading: false,
})).on(requestReportingTrackerDownloadFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
}));