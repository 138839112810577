import { pastelThemeColors, themeColors } from '../../../../scss/themeColors';

export const recruitmentInstallationProgressPerWeekColorMapG1 = {
    0: themeColors['green'],
    1: themeColors['red'],
};

export const recruitmentInstallationProgressPerWeekColorMapG2 = {
    0: 'grey',
    1: themeColors['green'],
    2: themeColors['blue'],
    3: themeColors['red'],
    4: themeColors['yellowStates'],
};

export const graphLabelMaps = {
    pollingNotOnPmr: 'Polling not on PMR',
    removedPreInstall: 'Removed Pre Install',
    removedOther: 'Removed Other',
    fullyInstalled: 'Fully Installed',
    addedToInstaller: 'Added to Installation List Less Removed Pre-Install (excl T&Cs)'

};
