import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { TFormBody } from '../../../types/formTypes';

type TData = {[key: string]: string | number}[]

type TPayload = {
        data?: [{[key: string]: string | number | boolean}],
        filterData: any,
}


// Filters
export const updateFilters = createAction<TFilters>('UPDATE_SUBMISSION_AND_MONITORING_FILTERS');

// Request Data
export const requestSubmissionAndMonitoringData = createAction<{ [key: string]: any }>('REQUEST_SUBMISSION_AND_MONITORING_DATA');
export const requestSubmissionAndMonitoringDataSuccess = createAction<any>('REQUEST_SUBMISSION_AND_MONITORING_DATA_SUCCESS');
export const requestSubmissionAndMonitoringDataFail = createAction<any>('REQUEST_SUBMISSION_AND_MONITORING_DATA_FAIL');

// Request Filters
export const requestSubmissionAndMonitoringFilters = createAction<{ [key: string]: any }>('REQUEST_SUBMISSION_AND_MONITORING_FILTERS');
export const requestSubmissionAndMonitoringFiltersSuccess = createAction<any>('REQUEST_SUBMISSION_AND_MONITORING_FILTERS_SUCCESS');
export const requestSubmissionAndMonitoringFiltersFail = createAction<any>('REQUEST_SUBMISSION_AND_MONITORING_FILTERS_FAIL');

// Update Table & Table Filtering State
export const setTableFilters = createAction<TData>('SET_SUBMISSION_AND_MONITORING_TABLE_FILTERS');
export const updateFormBody = createAction<TFormBody>('UPDATE_SUBMISSION_AND_MONITORING_TABLE_FORM_BODY');
export const updateCommentsFormBody = createAction<TFormBody>('UPDATE_SUBMISSION_AND_MONITORING_COMMENT_TABLE_FORM_BODY');

// Update Demograhics Table Table Filtering State
export const updateDemographicsFilterState = createAction<{[key: string]: boolean}>('UPDATE_SUBMISSION_AND_MONITORING_DEMOGRAPHICS_FILTER_STATE');

// Post Form Data ( Only for Submissions )
export const postSubmissionAndMonitoringFormData = createAction<any[]>('POST_SUBMISSION_AND_MONITORING_FORM_DATA');
export const postSubmissionAndMonitoringFormDataSuccess = createAction<TPayload>('POST_SUBMISSION_AND_MONITORING_FORM_DATA_SUCCESS');
export const postSubmissionAndMonitoringFormDataFail = createAction<any>('POST_SUBMISSION_AND_MONITORING_FORM_DATA_FAIL');

// Post Form Data ( Only for Comments )
export const postCommentSubmissionAndMonitoringFormData = createAction<{body: TFormBody, dataList: any}>('POST_COMMENT_SUBMISSION_AND_MONITORING_FORM_DATA');
export const postCommentSubmissionAndMonitoringFormDataSuccess = createAction<TPayload>('POST_COMMENT_SUBMISSION_AND_MONITORING_FORM_DATA_SUCCESS');
export const postCommentSubmissionAndMonitoringFormDataFail = createAction<any>('POST_COMMENT_SUBMISSION_AND_MONITORING_FORM_DATA_FAIL');

// Export Table Data Regular
export const exportSubmissionAndMonitoringTable = createAction<any>('EXPORT_SUBMISSION_AND_MONITORING_TABLE');
export const exportSubmissionAndMonitoringTableSuccess = createAction('EXPORT_SUBMISSION_AND_MONITORING_TABLE_SUCCESS');
export const exportSubmissionAndMonitoringTableFail = createAction<any>('EXPORT_SUBMISSION_AND_MONITORING_TABLE_FAIL');

// Export Table Data Demographics
export const exportSubmissionAndMonitoringDemmographicsTable = createAction<any>('EXPORT_SUBMISSION_AND_MONITORING_DEMOGRAPHICS_TABLE');
export const exportSubmissionAndMonitoringDemmographicsTableSuccess = createAction('EXPORT_SUBMISSION_AND_MONITORING_DEMOGRAPHICS_TABLE_SUCCESS');
