import { createReducer } from 'redux-act';
import { TFilterList, TFilters } from '../../../types/commonTypes';
import {
    requestSnapshotSummaryData,
    requestSnapshotSummaryDataSuccess,
    requestSnapshotSummaryDataFail,
    requestSnapshotSummaryFilterUpdate,
    requestActualsConversion,
    requestPercentageConversion,
    requestSnapshotSummaryFilters,
    requestSnapshotSummaryFiltersSuccess,
    requestSnapshotSummaryFiltersFail,
    updateSnapshotSummaryFilters
} from './actions';



export type TSnapshotSummaryState = {
    selectedFilters: TFilters;
    filters: TFilterList;
    isLoading: boolean,
    error: any,
    recruitmentFiscalWeeksTotalVsTarget: any,
    recruitmentFiscalWeeksTotalVsTargetPercentage: any,
    recruitmentFiscalWeeksTotalVsTargetActive: any,
    recruitmentFiscalWeeksPackage: any,
    recruitmentFiscalWeeksPackagePercentage: any,
    recruitmentFiscalWeeksPackageActive: any,
    recruitmentFiscalWeeksDecoderType: any,
    recruitmentFiscalWeeksDecoderTypePercentage: any,
    recruitmentFiscalWeeksDecoderTypeActive: any,
    recruitmentFiscalWeeksViewerExperience: any,
    recruitmentFiscalWeeksViewerExperiencePercentage: any,
    recruitmentFiscalWeeksViewerExperienceActive: any,
    recruitmentFiscalWeeksRegion: any,
    recruitmentFiscalWeeksRegionPercentage: any,
    recruitmentFiscalWeeksRegionActive: any,
};



const defaultState: TSnapshotSummaryState = {
    selectedFilters: null,
    filters: null,
    isLoading: true,
    error: null,
    recruitmentFiscalWeeksTotalVsTarget: null,
    recruitmentFiscalWeeksTotalVsTargetPercentage: null,
    recruitmentFiscalWeeksTotalVsTargetActive: null,
    recruitmentFiscalWeeksPackage: null,
    recruitmentFiscalWeeksPackagePercentage: null,
    recruitmentFiscalWeeksPackageActive: null,
    recruitmentFiscalWeeksDecoderType: null,
    recruitmentFiscalWeeksDecoderTypePercentage: null,
    recruitmentFiscalWeeksDecoderTypeActive: null,
    recruitmentFiscalWeeksRegion: null,
    recruitmentFiscalWeeksRegionPercentage: null,
    recruitmentFiscalWeeksRegionActive: null,
    recruitmentFiscalWeeksViewerExperience: null,
    recruitmentFiscalWeeksViewerExperiencePercentage: null,
    recruitmentFiscalWeeksViewerExperienceActive: null,
};

export const snapshotSummaryReducer = createReducer<TSnapshotSummaryState>({}, defaultState);

snapshotSummaryReducer.on(requestSnapshotSummaryData, state => ({
    ...state,
    isLoading: true
})).on(requestSnapshotSummaryDataSuccess, (state, payload) => ({
    ...state,
    isLoading: false,
    ...payload
})).on(requestSnapshotSummaryDataFail, (state, err) => ({
    ...state,
    isLoading: false,
    error: err
})).on(requestSnapshotSummaryFilters, state => {
    return {
        ...state,
        isLoading: true,
    };
}).on(requestSnapshotSummaryFiltersSuccess, (state, payload: TFilterList) => ({
    ...state,
    isLoading: false,
    filters: payload
})).on(requestSnapshotSummaryFiltersFail, (state, err) => ({
    ...state,
    isLoading: false,
    error: err
}))
    .on(requestSnapshotSummaryFilterUpdate, (state, payload: TFilters) => {
        return {
            ...state,
            selectedFilters: {
                ...state.selectedFilters,
                ...payload
            }
        };
    })
    .on(requestActualsConversion, state => ({
        ...state,
        recruitmentFiscalWeeksTotalVsTargetActive: state.recruitmentFiscalWeeksTotalVsTarget,
        recruitmentFiscalWeeksPackageActive: state.recruitmentFiscalWeeksPackage,
        recruitmentFiscalWeeksDecoderTypeActive: state.recruitmentFiscalWeeksDecoderType,
        recruitmentFiscalWeeksViewerExperienceActive: state.recruitmentFiscalWeeksViewerExperience,
        recruitmentFiscalWeeksRegionActive: state.recruitmentFiscalWeeksRegion,
    })).on(requestPercentageConversion, state => ({
        ...state,
        recruitmentFiscalWeeksTotalVsTargetActive: state.recruitmentFiscalWeeksTotalVsTargetPercentage,
        recruitmentFiscalWeeksPackageActive: state.recruitmentFiscalWeeksPackagePercentage,
        recruitmentFiscalWeeksDecoderTypeActive: state.recruitmentFiscalWeeksDecoderTypePercentage,
        recruitmentFiscalWeeksViewerExperienceActive: state.recruitmentFiscalWeeksViewerExperiencePercentage,
        recruitmentFiscalWeeksRegionActive: state.recruitmentFiscalWeeksRegionPercentage,
    })).on(updateSnapshotSummaryFilters, (state, filters) => ({
        ...state,
        selectedFilters: filters
    }));
