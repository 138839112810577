import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CInput, CInputGroup, CInputGroupPrepend, CInputGroupText, CRow, CSpinner } from '@coreui/react';
import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { watchConfirmResetPasswordRequest, watchResetPasswordRequest } from '../../redux/passwordReset/actions';
import dstvLogo from '../../resources/logos/DStv-i-Logo_RGB.png';
import kantarLogo from '../../resources/logos/KANTAR_Large_Logo_Black_RGB.png';
import multiChoiceLogo from '../../resources/logos/MultiChoice_logo.png';
import { themeColors } from '../../scss/themeColors';
import { logoCollectionWrapper } from '../../components/login/loginStyles';
import { RootState } from '../../redux/store';
import { useQuery } from '../../utils/misc/hooks';

const NewPassword = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const { isLoading } = useSelector((state: RootState) => state.confirmReset);
    const token = query.get('token');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const resetPassword = (values: any) => {
        dispatch(watchConfirmResetPasswordRequest({
            data: { password: values.newPassword, token },
            onError: () => console.log('error'),
            onSuccess: () => {
                setPasswordChanged(true);
                toast.success('You have successfully reset your password', { autoClose: 5000, });
            }
        }));
    };
    if (passwordChanged) return <Redirect to='/login' />;
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <div style={logoCollectionWrapper}>
                <div className="d-flex align-items-end mr-4" style={{ position: 'relative' }}><img alt='DSTV-logo' style={{ width: '150px', position: 'relative', bottom: '-4px' }} src={dstvLogo} /></div>
                <div className="d-flex align-items-start p-2" style={{ position: 'relative' }}> <img alt='Multi-Choice-logo' style={{ width: '150px', position: 'relative', bottom: '-7px' }} src={multiChoiceLogo} /> </div>
                <div className="d-flex align-items-end ml-4"><img alt='Kantar-logo' style={{ width: '150px' }} src={kantarLogo} /></div>
            </div>
            <CContainer style={{ position: 'relative' }}>
                <CRow className="justify-content-center">
                    <CCol md="6">

                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody >
                                    {
                                        // passwordChanged ?
                                        //     <div>
                                        //         <h3>You have successfully reset your password</h3>
                                        //         <CButton style={{ background: themeColors['blueStates'], color: 'white' }}>
                                        //             <Link to='/login' className="text-white">Login</Link>
                                        //         </CButton>
                                        //     </div> :
                                        <Formik
                                            onSubmit={resetPassword}
                                            initialValues={{
                                                newPassword: '',
                                                passwordConfirmation: ''
                                            }}
                                            validationSchema={Yup.object({
                                                newPassword: Yup.string().required('Enter new password').min(10, 'Minimum 10 characters required').matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()])/, 'Password does not meet requirements').trim(),
                                                passwordConfirmation: Yup.string().required('Enter confirmation password').oneOf([Yup.ref('newPassword')], 'Passwords do not match').trim()
                                            })}
                                        >
                                            {({ errors, values: { newPassword, passwordConfirmation }, touched, handleBlur, handleChange, handleSubmit }) => (
                                                <>
                                                    <h1>Reset Password</h1>
                                                    <p className="text-muted">New Password</p>
                                                    <CInputGroup className="mb-3">
                                                        <CInputGroupPrepend>
                                                            <CInputGroupText>
                                                                <CIcon name="cil-user" />
                                                            </CInputGroupText>
                                                        </CInputGroupPrepend>
                                                        <CInput
                                                            type="password"
                                                            placeholder="New password"
                                                            value={newPassword}
                                                            onChange={handleChange('newPassword')}
                                                            onBlur={handleBlur('newPassword')}
                                                        />
                                                    </CInputGroup>
                                                    <div className="text-danger mb-2">{errors.newPassword && touched.newPassword ? errors.newPassword : null}</div>
                                                    <ul>
                                                        <li>Use at least 10 characters</li>
                                                        <li>Use upper case and lower case characters</li>
                                                        <li>Use 1 or more numbers</li>
                                                    </ul>
                                                    <p className="text-muted">Confirmation Password</p>
                                                    <CInputGroup className="mb-3">
                                                        <CInputGroupPrepend>
                                                            <CInputGroupText>
                                                                <CIcon name="cil-user" />
                                                            </CInputGroupText>
                                                        </CInputGroupPrepend>
                                                        <CInput
                                                            type="password"
                                                            placeholder="Confirmation password"
                                                            value={passwordConfirmation}
                                                            onChange={handleChange('passwordConfirmation')}
                                                            onBlur={handleBlur('passwordConfirmation')}
                                                        />
                                                    </CInputGroup>
                                                    <div className="text-danger mb-2">{errors.passwordConfirmation && touched.passwordConfirmation ? errors.passwordConfirmation : null}</div>
                                                    <div className="d-flex align-items-center">
                                                        <CButton
                                                            style={{ background: themeColors['blueStates'], color: 'white' }}
                                                            type="submit"
                                                            className="px-4"
                                                            onClick={handleSubmit}
                                                            disabled={isLoading}

                                                        >Submit</CButton>
                                                        {isLoading && <CSpinner color="info" style={{ margin: '0 5px' }} />}
                                                    </div>

                                                </>
                                            )}

                                        </Formik>
                                    }

                                </CCardBody>
                            </CCard>

                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default NewPassword;
