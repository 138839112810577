
import { call, put, takeLatest } from 'redux-saga/effects';
import { requestUserAccess, requestUserAccessFailed, requestUserAccessSuccess } from './action';
import { get } from '../../api/api';
import { systemError } from '../../utils/notifyToast';
import { UserAccessResponseView } from '../../types';


function* userAccessFunc() {
    try {
        const { data } = yield call(() => get('user_access/'));
        yield put(requestUserAccessSuccess(data as UserAccessResponseView));

    } catch (err) {
        systemError(err);
        yield put(requestUserAccessFailed(err));
    }
}

export function* userAccessWatchSaga() {
    yield takeLatest(requestUserAccess, userAccessFunc);
}
