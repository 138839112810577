import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import NewPassword from './pages/forgotPassword/NewPassword';
import { EAuthToken } from './types/authTokens';

// Containers
const TheLayout = React.lazy(() => import('../src/components/common/layouts/TheLayout'));

// Pages
const Login = React.lazy(() => import('../src/pages/login/Login'));

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const getToken = () => localStorage.getItem(EAuthToken.ACCESS_TOKEN) ? true : false;

const Routers = () => (
    <BrowserRouter>
        <React.Suspense fallback={loading}>
            <Switch>
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/password_reset/confirm/" component={NewPassword} />
                {/* <Route path="/change-password" component={ChangePassword}/> */}
                <Route exact path="/login" render={() => getToken() ? <Redirect to="/" /> : <Login />} />
                <Route path="/" render={() => {
                    const isLoggedin = getToken();
                    return isLoggedin ? <TheLayout /> : <Redirect to="/login" />;
                }} />
            </Switch>
        </React.Suspense>
    </BrowserRouter>
);

export default Routers;
