export type TFormpayload = {
    householdPk: number | string,
    fiscal: string,
    otdSalesOrderNumber:  number | string,
    dateContacted:  string,
    dateSentToOtd:  string,
    dateOfDelivery:  string,
    numberOfModemsNeeded:  number | string,
    daysSinceContact: number | string,
    daysSinceDelivery: number | string,
    installationMethod:  string,
    queryStatus:  string,
    kantarComments:  string,
    kantarCommentsDatetime:  string,
    multichoiceOverallStatus:  string,
    multichoiceSubStatus:  string,
    multichoiceComments:  string,
    decoderSerialNumber: number | string,
    dateOfInstallerVisit:  string,
    installerName:  string,
    categoryAssessment:  string,
    outcomeOfVisit:  string,
    installedModemImeiCode1: number | string,
    simcardSerialNumber1: number | string,
    simcardIdNumber1: number | string,
    installedModemImeiCode2: number | string,
    simcardSerialNumber2: number | string,
    simcardIdNumber2: number | string,
    installedModemImeiCode3: number | string,
    simcardSerialNumber3: number | string,
    simcardIdNumber3: number | string,
    fsCommentDate:  string,
};


export const defaultFormState = {
    householdPk: '',
    fiscal: '',
    otdSalesOrderNumber: '',
    dateContacted: '',
    dateSentToOtd: '',
    dateOfDelivery: '',
    numberOfModemsNeeded: '',
    daysSinceContact: '',
    daysSinceDelivery: '',
    installationMethod: '',
    queryStatus: '',
    kantarComments: '',
    kantarCommentsDatetime: '',
    multichoiceOverallStatus: '',
    multichoiceSubStatus: '',
    multichoiceComments: '',
    decoderSerialNumber: '',
    dateOfInstallerVisit: '',
    installerName: '',
    categoryAssessment: '',
    outcomeOfVisit: '',
    installedModemImeiCode1: '',
    simcardSerialNumber1: '',
    simcardIdNumber1: '',
    installedModemImeiCode2: '',
    simcardSerialNumber2: '',
    simcardIdNumber2: '',
    installedModemImeiCode3: '',
    simcardSerialNumber3: '',
    simcardIdNumber3: '',
    fsCommentDate: '',
};