import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';

export const requestTroubleShootingData = createAction<TFilters>('REQUEST_TROUBLESHOOTING_ACTIVITIES_DATA');
export const requestTroubleShootingDataSuccess = createAction<any>('REQUEST_TROUBLESHOOTING_ACTIVITIES_DATA_SUCCESS');
export const requestTroubleShootingDataFailed = createAction<any>('REQUEST_TROUBLESHOOTING_ACTIVITIES_DATA_FAILED');

export const setSelectedFilters = createAction<TFilters>('SET_TROUBLESHOOTING_ACTIVITIES_SELECTED_FILTERS');
export const setActiveGraphWeeklyProgressProvince = createAction<any>('SET_TROUBLESHOOTING_ACTIVITIES_ACTIVE_GRAPHWEEKLY_PROGRESS_PROVINCE');

export const setFilters = createAction<{[key: string]: string[]}>('SET_TROUBLESHOOTING_ACTIVITIES_FILTERS');

export const clearFilters = createAction('CLEAR_TROUBLESHOOTING_ACTIVITIES_FILTERS');

export const requestTroubleShootingFilters = createAction<TFilters>('REQUEST_TROUBLESHOOTING_ACTIVITIES_FILTERS');
export const requestTroubleShootingFiltersSuccess = createAction<any>('REQUEST_TROUBLESHOOTING_ACTIVITIES_FILTERS_SUCCESS');
export const requestTroubleShootingFiltersFailed = createAction<any>('REQUEST_TROUBLESHOOTING_ACTIVITIES_FILTERS_FAILED');
