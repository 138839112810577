import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { EAuthToken } from '../types/authTokens';
import fileDownload from 'js-file-download';
import { systemError } from '../utils/notifyToast';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
    baseURL: baseURL
});

axiosInstance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem(EAuthToken.ACCESS_TOKEN);
    try {
        const authConfig = config;
        if (token && authConfig && authConfig.headers) {
            authConfig.headers['Authorization'] = `Bearer ${token}`;
        }
        return await Promise.resolve(authConfig);
    } catch (error) {
        return await Promise.resolve(config);
    }
});

axiosInstance.interceptors.response.use(
    (res) => {
        return Promise.resolve(res);
    },
    (err) => {
        systemError(err.response.data.message || err.response.data.detail || err.response.data.email[0]);
        return Promise.reject(err.response.data.message || err.response.data.detail || err.response.data.email[0]);
    }
);

const refreshAuthLogic = async (failedRequest: any) => {
    const refreshToken = localStorage.getItem(EAuthToken.REFRESH_TOKEN);
    if (refreshToken !== null) {
        return axios.post('/api/token/refresh/', { refresh: refreshToken, }, { baseURL: baseURL })
            .then((resp: any) => {
                const { access, refresh } = resp.data;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                localStorage.setItem(EAuthToken.ACCESS_TOKEN, access);
                localStorage.setItem(EAuthToken.REFRESH_TOKEN, refresh);

            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    localStorage.removeItem(EAuthToken.ACCESS_TOKEN);
                    localStorage.removeItem(EAuthToken.REFRESH_TOKEN);
                    window.location.href = '/login';
                }
            });
    }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export const get = async (endpoint: string, params?: { [key: string]: string | number | { [key: string]: any } }) => {
    const payload = await axiosInstance.get(endpoint, params ? { params: params } : {});
    return payload;
};

export const post = async (endpoint: string, body: any) => {
    const payload = await axiosInstance.post(endpoint, body);
    return payload;
};

export const update = async (endpoint: string, body: any) => {
    const payload = await axiosInstance.put(endpoint, body);
    return payload;
};

export const downloadFileGet = async (endpoint: string, filename: string) => {
    const payload: any = await axiosInstance.get(endpoint, {
        responseType: 'blob'
    });
    fileDownload(payload.data, filename);
    return { data: true };
};

export const downloadFilePost = async (endpoint: string, data: any, filename: string) => {
    const payload: any = await axiosInstance.post(endpoint, data, {
        responseType: 'blob'
    });
    fileDownload(payload.data, filename);
    return { data: true };
};

export const axiosDelete = async (endpoint: string, body: any) => {
    const payload = await axiosInstance.delete(endpoint, { data: body });
    return payload;
};

export default axiosInstance;
