import { createReducer } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import {
    requestRecruitmentProgressPerWeekFiltersFailed,
    requestRecruitmentProgressPerWeekFilters,
    requestRecruitmentProgressPerWeekFiltersSuccess,
    requestRecruitmentProgressPerWeekTotalvsTarget,
    requestRecruitmentProgressPerWeekTotalvsTargetSuccess,
    requestRecruitmentProgressPerWeekTotalvsTargetFailed,
    requestRecruitmentProgressPerWeekTotalvsTargetQuotaFailed,
    requestRecruitmentProgressPerWeekTotalvsTargetQuotaSuccess,
    requestRecruitmentProgressPerWeekTotalvsTargetQuota,
    updateFiltersSelected
} from './actions';

export type TRecruitmentProgressPerWeekState = {
    isLoading: boolean,
    isLoadingTotalTarget: boolean,
    isLoadingTotalTargetQuota: boolean,
    error: any,
    filters: any,
    selectedFilters: TFilters,
    recruitmentProgressPerWeekTotalvsTargetData: any
    recruitmentProgressPerWeekTotalvsTargetQuotaData: any
};

const defaultState: TRecruitmentProgressPerWeekState = {
    isLoading: true,
    isLoadingTotalTarget: true,
    isLoadingTotalTargetQuota: true,
    error: null,
    filters: null,
    selectedFilters: {},
    recruitmentProgressPerWeekTotalvsTargetData: {},
    recruitmentProgressPerWeekTotalvsTargetQuotaData: {},
};


export const recruitmentProgressPerWeekReducer = createReducer<TRecruitmentProgressPerWeekState>({}, defaultState);

recruitmentProgressPerWeekReducer.on(requestRecruitmentProgressPerWeekFilters , state => ({
    ...state,
    isLoading:true,
    error:'',
})).on(requestRecruitmentProgressPerWeekFiltersSuccess , (state, filters) => ({
    ...state,
    filters: {...filters},
    isLoading:false,
})).on(requestRecruitmentProgressPerWeekFiltersFailed , (state) => ({
    ...state,
    isLoading:false,
})).on(requestRecruitmentProgressPerWeekTotalvsTarget , state => ({
    ...state,
    isLoadingTotalTarget:true,
    error:'',
})).on(requestRecruitmentProgressPerWeekTotalvsTargetSuccess , (state, data) => ({
    ...state,
    recruitmentProgressPerWeekTotalvsTargetData: {...data},
    isLoadingTotalTarget:false,
})).on(requestRecruitmentProgressPerWeekTotalvsTargetFailed , (state) => ({
    ...state,
    isLoadingTotalTarget:false,
})).on(requestRecruitmentProgressPerWeekTotalvsTargetQuota , state => ({
    ...state,
    isLoadingTotalTargetQuota:true,
    error:'',
})).on(requestRecruitmentProgressPerWeekTotalvsTargetQuotaSuccess , (state, data) => ({
    ...state,
    recruitmentProgressPerWeekTotalvsTargetQuotaData: {...data},
    isLoadingTotalTargetQuota:false,
})).on(requestRecruitmentProgressPerWeekTotalvsTargetQuotaFailed , (state) => ({
    ...state,
    isLoadingTotalTargetQuota:false,
})).on(updateFiltersSelected , (state, filters) => ({
    ...state,
    selectedFilters: {...filters},
}));
