import { createReducer } from 'redux-act';
import { TInterviewPlanFiltersState, TInterviewPlanProjectState, TInterviewPlanState } from '../../../types';
import { createInterviewPlan, createInterviewPlanFailed, createInterviewPlanSuccess, requestInterviewPlan, requestInterviewPlanFailed, requestInterviewPlanFilters, requestInterviewPlanFiltersFailed, requestInterviewPlanFiltersSuccess, requestInterviewPlanProject, requestInterviewPlanProjectFailed, requestInterviewPlanProjectReset, requestInterviewPlanProjectSuccess, requestInterviewPlanReset, requestInterviewPlanSuccess, requestInterviewPlanUpdate, requestInterviewPlanUpdateSuccess, requestPProjectPlanDownloadSuccess, requestProjectPlanDownload, requestProjectPlanDownloadFailed, setOriginalWeeklyPlan, updateInterviewPlan, updateInterviewPlanFailed, updateInterviewPlanSuccess } from './action';

export const defaultInterviewPlanState: TInterviewPlanState = {
    plan: {
        project:{
            id: 0,
            projectName: '',
            version: 0,
            market: '',
            service: '',
            serviceTermProject: '',
            fiscal: '',
            quota: 0,
            weeklyCapacity: 0,
            startDate: '',
            endDate: '',
        },
        weeklyPlan:[]
    },
    originalWeek:{
        id: 0,
        week: '',
        mainRecruitment: 0,
        activeTsSubmitted: 0,
        activeTsEstComplete: 0,
        activeTsEstInstallationsReq: 0,
        sparkSubmitted: 0,
        modemSwapsSumitted: 0,
        modemSwapsEstCompleted: 0,
        modemSwapsEstInstallationsReq: 0,
        projectOverview: 0,
        isRemoved: false,
        totalMCA: 0
    },
    isLoading:false,
    error:'',
    isAdding:false,
    addingError:'',
    isUpdate: false,
    updateError:'',
    isUpdatingWeekData: false,
};

export const interviewPlanReducer = createReducer<TInterviewPlanState>({}, defaultInterviewPlanState);

interviewPlanReducer.on(requestInterviewPlan , state => ({
    ...state,
    isLoading: true,
    error:'',
})).on(requestInterviewPlanSuccess,(state,plan) => ({
    ...state,
    isLoading: false,
    plan
})).on(requestInterviewPlanFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
})).on(createInterviewPlan, state => ({
    ...state,
    isAdding: true,
    addingError:''
})).on(createInterviewPlanFailed, (state,addingError) => ({
    ...state,
    isAdding: false,
    addingError
})).on(requestInterviewPlanReset, state => ({
    ...state,
    ...defaultInterviewPlanState
})).on(updateInterviewPlan,state => ({
    ...state,
    updateError: '',
    isUpdatingWeekData: true
})).on(updateInterviewPlanSuccess,state => ({
    ...state,
    isUpdate:false,
    isUpdatingWeekData: false
})).on(updateInterviewPlanFailed,(state,error) => ({
    ...state,
    isUpdate:false,
    isUpdatingWeekData:false,
    error
})).on(requestInterviewPlanUpdate,state => ({
    ...state,
    isUpdate:true,
    addingError:''
})).on(requestInterviewPlanUpdateSuccess,(state,plan) =>  ({
    ...state,
    isUpdate:false,
    plan
})).on(createInterviewPlanSuccess, state => ({
    ...state,
    isAdding:false
})).on(setOriginalWeeklyPlan,(state,originalWeek) => ({
    ...state,
    originalWeek
}));
    
export const defaultInterviewPlanFiltersState: TInterviewPlanFiltersState = {
    filters: {
        market:[],
        service:[],
        serviceTermProject:[],
        fiscal:[]
    },
    isLoading:false,
    error:'',
};

export const interviewPlanFilterReducer = createReducer<TInterviewPlanFiltersState>({}, defaultInterviewPlanFiltersState);

interviewPlanFilterReducer.on(requestInterviewPlanFilters , state => ({
    ...state,
    isLoading:true,
    error:'',
})).on(requestInterviewPlanFiltersSuccess , (state,filters) => ({
    ...state,
    isLoading:false,
    filters
})).on(requestInterviewPlanFiltersFailed,(state,error) => ({
    ...state,
    isLoading:false,
    error
}));

export const defaultInterviewPlanProjectState: TInterviewPlanProjectState = {
    projectNames:[],
    isLoading:false,
    error:'',
};

export const interviewPlanProjectReducer = createReducer<TInterviewPlanProjectState>({}, defaultInterviewPlanProjectState);

interviewPlanProjectReducer.on(requestInterviewPlanProject , state => ({
    ...state,
    isLoading:true,
    error:'',
})).on(requestInterviewPlanProjectSuccess , (state,projectNames) => ({
    ...state,
    isLoading:false,
    projectNames
})).on(requestInterviewPlanProjectFailed,(state,error) => ({
    ...state,
    isLoading:false,
    error
})).on(requestInterviewPlanProjectReset, state => ({
    ...state,
    ...defaultInterviewPlanProjectState
}));


export type TProjectPlanFileExportState = {
    file:string;
    isLoading:boolean;
    error: any;
  }
  
const defaultFileExportState: TProjectPlanFileExportState = {
    file:'',
    isLoading: false,
    error:''
};
  
export const projectPlanFileExportReducer = createReducer<TProjectPlanFileExportState>({}, defaultFileExportState);

projectPlanFileExportReducer.on(requestProjectPlanDownload,state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestPProjectPlanDownloadSuccess,state => ({
    ...state,
    isLoading: false,
})).on(requestProjectPlanDownloadFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
}));
