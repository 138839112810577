import { columnFormats } from './mappingAndTableData';
import alphaSort from 'alpha-sort';

type Data = [{ [key: string]: number | string }]

// Generate Filter Lists
export const genFilterList = (data: Data) => {

    if (data.length > 0) {
        const list: any = {};
        // Gen Columns
        Object.keys(data[0]).forEach((key: any) => {
            columnFormats[key] && columnFormats[key] == 'DropDownFilter' ? list[key] = [] : null;
        });

        // Sort helper Function
        const sortData = (data: any[]) => {
            const type = typeof data[0];

            if (type == 'number') {
                return data.sort(function (a: number, b: number) {
                    return a - b;
                });
            } else {
                return data.sort(alphaSort());
            }
        };

        // Populate Columns
        data.forEach((obj: any) => {
            // loop over objects
            Object.keys(obj).forEach((key: string) => {
                // loop over objects keys
                const value = obj[key] != null && obj[key] != undefined ? obj[key].toString() : null;
                if (value !== null && columnFormats[key] == 'DropDownFilter') {
                    list[key] ? list[key] = sortData([value, ...list[key]]) : console.log(`There is no such key in list ${key}`);
                    list[key] ? list[key] = new Set(Object.values(list[key])) : console.log(`There is no such key in list ${key}`);
                }
            });
        });

        return list;
    }
    else {
        return [];
    }

};


// Build Table Data from filtersState
export const buildTableData = (data: Data, filters: Data) => {

    // Extract values to ignore from Filters
    const valuesToFilter: any[] = [new Set(Object.keys(filters).map((item: string) => { return item; }))];

    const _data = [];

    // Decide whic items to filter
    Object.keys(data).forEach((obj) => {
        let add = true;
        Object.values(obj).forEach((value: string | number) => {
            valuesToFilter.includes(value) ? add = false : null;
        });

        add ? _data.push(obj) : null;
        add = true;

    });
};
