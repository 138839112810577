export const colorMap: { [key: number]: string } = {
    0: 'rgb(68, 6, 88)',
    1: 'rgb(68, 6, 88)',
    2: 'rgb(1, 166, 182)',
    3: 'rgb(1, 166, 182)',
    4: 'rgb(198, 103, 55)',
    5: 'rgb(198, 103, 55)',
    6: 'rgb(0, 110, 144)',
    7: 'rgb(0, 110, 144)',
    8: 'rgb(126, 40, 129)',
    9: 'rgb(126, 40, 129)',
    10: 'rgb(77, 178, 69)',
    11: 'rgb(77, 178, 69)',
    12: 'rgb(228, 1, 136)',
    13: 'rgb(228, 1, 136)',
    14: 'rgba(32 100 55)',
    15: 'rgba(32 100 55)',
    16: 'rgb(179, 39, 53)',
    17: 'rgb(179, 39, 53)',
    18: 'rgb(245, 180, 48)',
    19: 'rgb(245, 180, 48)',
    20: 'rgb(230, 28, 35)',
    21: 'rgb(230, 28, 35)',
    22: 'rgb(218, 138, 54)',
    23: 'rgb(218, 138, 54)',
    24: 'rgb(236, 108, 31)',
    25: 'rgb(236, 108, 31)',
    26: 'rgb(198, 103, 55)',
    27: 'rgb(198, 103, 55)',
    28: 'rgb(146, 169, 145)',
    29: 'rgb(146, 169, 145)',
    30: 'rgb(150, 150, 173)',
    31: 'rgb(150, 150, 173)',
    32: 'rgb(217, 152, 190)',
    33: 'rgb(217, 152, 190)',
    34: 'rgb(175, 172, 59)',
    35: 'rgb(175, 172, 59)',
    36: 'rgb(241, 221, 180)',
    37: 'rgb(241, 221, 180)',
    38: 'rgb(195, 167, 124)',
    39: 'rgb(195, 167, 124)',
    40: 'rgb(187, 180, 180)',
    41: 'rgb(187, 180, 180)',
};

export const basicColorMap: { [key: number]: string } = {
    0: 'rgb(68, 6, 88)',
    1: 'rgb(1, 166, 182)',
    2: 'rgb(198, 103, 55)',
    3: 'rgb(0, 110, 144)',
    4: 'rgb(126, 40, 129)',
    5: 'rgb(77, 178, 69)',
    6: 'rgb(228, 1, 136)',
    7: 'rgba(32 100 55)',
    8: 'rgb(179, 39, 53)',
    9: 'rgb(245, 180, 48)',
    10: 'rgb(230, 28, 35)',
    11: 'rgb(218, 138, 54)',
    12: 'rgb(236, 108, 31)',
    13: 'rgb(198, 103, 55)',
    14: 'rgb(146, 169, 145)',
    15: 'rgb(150, 150, 173)',
    16: 'rgb(217, 152, 190)',
    17: 'rgb(175, 172, 59)',
    18: 'rgb(241, 221, 180)',
    19: 'rgb(195, 167, 124)',
    20: 'rgb(187, 180, 180)',
};
