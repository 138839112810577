import { createReducer } from 'redux-act';
import { TFilterList, TFilters } from '../../../types/commonTypes';
import {
    requestInstallationProgressPerWeekMondayData,
    requestInstallationProgressPerWeekMondayDataFail,
    requestInstallationProgressPerWeekMondayDataSuccess,
    requestInstallationProgressProgressPerWeekMondayFiltersSuccess,
    updateInstallationProgressPerWeekMondayFilters,
} from './actions';

export type TInstallationProgressPerWeekMondayState = {
    isLoading: boolean;
    error: any;
    filters: TFilterList;
    installationProgressWeeksActualInstalls: any;
    installationProgressWeeksCumulative: any;
    installationProgressInstallationRate: any;
    installationProgressInstallationStatistics: any;
    selectedFilters: TFilters;
};

const defaultState: TInstallationProgressPerWeekMondayState = {
    isLoading: true,
    error: null,
    filters: null,
    installationProgressWeeksActualInstalls: null,
    installationProgressWeeksCumulative: null,
    installationProgressInstallationRate: null,
    installationProgressInstallationStatistics: null,
    selectedFilters: {},
};

export const installationProgressPerWeekMondayReducer =
    createReducer<TInstallationProgressPerWeekMondayState>({}, defaultState);

installationProgressPerWeekMondayReducer
    .on(requestInstallationProgressPerWeekMondayData, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .on(
        requestInstallationProgressPerWeekMondayDataSuccess,
        (state, payload) => {
            return {
                ...state,
                isLoading: false,
                installationProgressWeeksActualInstalls:
                    payload.installationProgressWeeksActualInstalls,
                installationProgressInstallationRate:
                    payload.installationProgressInstallationRate
            };
        }
    )
    .on(
        requestInstallationProgressProgressPerWeekMondayFiltersSuccess,
        (state, filters: TFilters) => ({
            ...state,
            filters: filters,
        })
    )

    .on(requestInstallationProgressPerWeekMondayDataFail, (state, error) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    })
    .on(updateInstallationProgressPerWeekMondayFilters, (state, filters) => {

        return {
            ...state,
            selectedFilters: { ...filters },
        };
    });
