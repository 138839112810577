export const mainLogoStyle: any = {
    margin: '20px',
    width: '90px'
};

export const logoBadge: any = {
    margin: '5px',
    width: '120px'
};

export const logobadgeWrapperRight: any = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '10px',
};

export const logoCollectionWrapper: any = {
    position: 'absolute',
    width: '100vw',
    top: '50px',
    left: 0,
    display: 'flex',
    justifyContent: 'center'
};


export const loginCard: any = {
    background: 'linear-gradient(282deg, #0093d7, #0271b3)'
};

