import { themeColors } from '../../../scss/themeColors';
import { humanReadableLabels } from '../../dataMapping/apiMappings';
import { defaultOptionsDoubleBar, totalPercentageOptionDoubleBar } from '../optionTemplates';
import { roundMax } from '../proccessingUtils';



export const transDoubleBarData = (data: any, meta?: string, legends?: any) => {

    const options = defaultOptionsDoubleBar;

    const labels: any[] = [];
    Object.keys(data).forEach((key, i) => {
        const isWholeNum = i % 2 === 0;
        if (!isWholeNum) {
            humanReadableLabels[key] ? labels.push(humanReadableLabels[key]) :
                labels.push(key);
        }
    });

    const firstHalf: any = [];
    const secondHalf: any = [];

    Object.values(data).forEach((value, i) => {
        const isWholeNum = i % 2 === 0;
        if (isWholeNum) {
            firstHalf.push(value);
        } else {
            secondHalf.push(value);
        }
    });

    if (meta == 'max') {
        const max = roundMax(Math.max(...firstHalf));
        options.scales.y.max = max;
    }

    return [
        {
            labels: labels,
            datasets: [
                {
                    label: legends[0],
                    backgroundColor: themeColors['darkPurple'],
                    borderColor: themeColors['darkPurpleStates'],
                    borderWidth: 1,
                    data: firstHalf,
                },
                {
                    label: legends[1],
                    backgroundColor: themeColors['red'],
                    borderColor: themeColors['redStates'],
                    borderWidth: 1,
                    data: secondHalf,
                },
            ],
        },
        options,
    ];
};

export const transDoubleBarDataPercentage = (
    data: any,
    meta: string,
    legends?: any
) => {
    const labels: any[] = [];
    const options = defaultOptionsDoubleBar;


    Object.keys(data).forEach((key, i) => {
        const isWholeNum = i % 2 === 0;
        if (!isWholeNum) {
            if (humanReadableLabels[key]) {
                // labels.push(humanReadableLabels[key].split(' '));
                labels.push(humanReadableLabels[key]);
            }
        }
    });

    const firstHalf: any = [];
    const secondHalf: any = [];

    const arr: any = Object.values(data);
    const max = arr.reduce((a: number, b: number) => a + b);

    Object.values(data).forEach((value: any, i) => {
        const isWholeNum = i % 2 === 0;
        if (isWholeNum) {
            firstHalf.push(Math.ceil((100 / max) * value));
        } else {
            secondHalf.push(Math.ceil((100 / max) * value));
        }
    });

    return [
        {
            labels: labels,
            datasets: [
                {
                    label: legends[0],
                    backgroundColor: themeColors['blue'],
                    borderColor: themeColors['blueStates'],
                    borderWidth: 1,
                    data: firstHalf,
                },
                {
                    label: legends[1],
                    backgroundColor: themeColors['red'],
                    borderColor: themeColors['redStates'],
                    borderWidth: 1,
                    data: secondHalf,
                },
            ],
        },
        meta === 'max' ? totalPercentageOptionDoubleBar : options,
    ];
};
