import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { 
    ReportingTrackerFiltersRespose,
    ReportingTrackerRequestView,
    ReportingTrackerResponseView
} from './reducer';

export const requestReportingTracker = createAction<ReportingTrackerRequestView>('GET_REPORTING_TRACKER_REQUEST');
export const requestReportingTrackerSuccess = createAction<ReportingTrackerResponseView>('GET_REPORTING_TRACKER_SUCCESS_REQUEST');
export const requestReportingTrackerFailed = createAction<any>('GET_REPORTING_TRACKER_FAILED_REQUEST');

export const requestMoreReportingTracker = createAction<ReportingTrackerRequestView>('GET_MORE_REPORTING_TRACKER_REQUEST');

export const requestReportingTrackerFilters = createAction<TFilters>('GET_REPORTING_TRACKER_FILTGET_REPORTING_TRACKER_GET_REPORTING_TRACKER_FILTER_REQUEST');
export const requestReportingTrackerFiltersSuccess = createAction<ReportingTrackerFiltersRespose>('GET_REPORTING_TRACKER_FILTER_SUCCESS_REQUEST');
export const requestReportingTrackerFiltersFailed = createAction<any>('GET_REPORTING_TRACKER_FAILED_FILTER_REQUEST');

export const requestReportingTrackerDownload = createAction<TFilters>('GET_REPORTING_TRACKER_DOWNLOAD_REQUEST');
export const requestReportingTrackerDownloadSuccess = createAction('GET_REPORTING_TRACKER_SUCCESS_DOWNLOAD_REQUEST');
export const requestReportingTrackerDownloadFailed = createAction<any>('GET_REPORTING_TRACKER_FAILED_DOWNLOAD_REQUEST');