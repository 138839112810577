
import { combineReducers } from 'redux';
import { TRecruitmentState } from '../types/state.types';
import { boilerPlateReducer } from './boilerPlate/reducer';
import { installationProgressPerWeekReducer } from './recruitment/installationProgressPerWeek/reducer';
import { installationProgressPerWeekMondayReducer } from './recruitment/installationProgressPerWeekMonday/reducer';
import { installationProgressSnapshotSummaryReducer } from './recruitment/installationProgressSnapshotSummary/reducer';
import { interviewPlanFilterReducer, interviewPlanProjectReducer, interviewPlanReducer } from './recruitment/projectPlanTimeline/reducer';
import { quotaDesignFiltersReducer, quotaDesignReducer } from './recruitment/quotaDesign/reducer';
import { recruitmentProgressPerWeekReducer } from './recruitment/recruitmentProgressPerWeek/reducer';
import { snapshotSummaryReducer } from './recruitment/snapshotSummary/reducer';

export const recruitmentReducer = combineReducers<TRecruitmentState>({
    boilerPlate: boilerPlateReducer,
    installationProgressSnapshotSummary:
        installationProgressSnapshotSummaryReducer,
    interviewPlan: interviewPlanReducer,
    interviewPlanFilters: interviewPlanFilterReducer,
    interviewPlanProject: interviewPlanProjectReducer,
    installationProgressPerWeek: installationProgressPerWeekReducer,
    installationProgressPerWeekMonday: installationProgressPerWeekMondayReducer,
    quotaDesign: quotaDesignReducer,
    quotaDesignFilter: quotaDesignFiltersReducer,
    recruitmentProgressPerWeek: recruitmentProgressPerWeekReducer,
    snapshotSummary: snapshotSummaryReducer,
});
