import { createReducer } from 'redux-act';
import { 
    requestBatchNumberTracker, 
    requestBatchNumberTrackerFailed, 
    requestBatchNumberTrackerFilters, 
    requestBatchNumberTrackerFiltersFailed, 
    requestBatchNumberTrackerFiltersSuccess, 
    requestBatchNumberTrackerSuccess,
    updateBatchNumberTracker,
    updateBatchNumberTrackerFailed,
    updateBatchNumberTrackerSuccess
} from './action';


export type BatchNumberTrackerResponseView = {
    id:number;
    batchNumber:number;
    country:string;
    dateSubmitted:string;
    numberHouseholdsSubmitted:number;
    status:string;
    weekNumber: number;
    weekStartDate:string;
    completedPercent: number;
    positiveOutcomesPercent: number;
    negativeOutcomesPercent: number;
    comment:string;
}


export type TBatchNumberTrackerState = {
  batchNumber: BatchNumberTrackerResponseView[];
  isLoading: boolean;
  error: any;
  isUpdating: boolean;
  updatigError: any;
}


const defaultBatchNumberTrackerStatee: TBatchNumberTrackerState = {
    batchNumber: [],
    isLoading:false,
    error:'',
    isUpdating:false,
    updatigError:''
};

export const batchNumberTrackerReducer = createReducer<TBatchNumberTrackerState>({}, defaultBatchNumberTrackerStatee);

batchNumberTrackerReducer.on(requestBatchNumberTracker , state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestBatchNumberTrackerSuccess,(state,batchNumber) => ({
    ...state,
    isLoading: false,
    batchNumber
})).on(requestBatchNumberTrackerFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
})).on(updateBatchNumberTracker, state => ({
    ...state,
    isUpdating: true,
    error:''
})).on(updateBatchNumberTrackerSuccess, state => ({
    ...state,
    isUpdating: false,
})).on(updateBatchNumberTrackerFailed,(state,updatigError) => ({
    ...state,
    updatigError
}));
    

export type BatchNumberTrackerFiltersRespose = {
    country: string[];
  }

export type TBatchNumberTrackerFiltersState = {
    filters:BatchNumberTrackerFiltersRespose;
    isLoading:boolean;
    error: any;
  }
  
  
const defaultBatchNumberTrackerFilterState: TBatchNumberTrackerFiltersState = {
    filters: {
        country:[],
    },
    isLoading:false,
    error:''
};
  
export const batchNumberTrackerFilterReducer = createReducer<TBatchNumberTrackerFiltersState>({}, defaultBatchNumberTrackerFilterState);
  
batchNumberTrackerFilterReducer.on(requestBatchNumberTrackerFilters, state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestBatchNumberTrackerFiltersSuccess,(state,filters) => ({
    ...state,
    isLoading: false,
    filters
})).on(requestBatchNumberTrackerFiltersFailed,(state,error) => ({
    ...state,
    isLoading: false,
    error
}));
