import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../../../api/api';
import { buildGETUrlWithParams } from '../../../api/misc/misc';
import { humanReadableLabels } from '../../../utils/dataMapping/apiMappings';
import { multiBarTransform } from '../../../utils/graphs&charts/transBarData/multiBarTransData';
import { transChartData } from '../../../utils/graphs&charts/transBarData/transformData';
import { _isPayloadTruthy } from '../../../utils/misc/misc';
import { systemError } from '../../../utils/notifyToast';
import { toggleLoadingCursor } from '../../home/action';
import {
    requestInstallationProgressSnapshotSummaryData,
    requestInstallationProgressSnapshotSummaryDataFail,
    requestInstallationProgressSnapshotSummaryDataSuccess,
    requestInstallationProgressSnapshotSummaryFilters,
    requestInstallationProgressSnapshotSummaryFiltersFailed,
    requestInstallationProgressSnapshotSummaryFiltersSuccess,
    requestInstallationProgressSnapshotSummaryProvinceData,
    requestInstallationProgressSnapshotSummaryProvinceDataFail,
    requestInstallationProgressSnapshotSummaryProvinceDataSuccess
} from './actions';
import { recruitmentInstallationProgressCumulativeColorMap } from './meta/meta';

function* mySagaFunction(action: any) {
    let data: any = {};
    try {
        yield axios.all([
            get(buildGETUrlWithParams(action.payload, '/installation_progress/cumulative/?')),
            get(buildGETUrlWithParams(action.payload, '/installation_progress/?')),
            get(buildGETUrlWithParams(action.payload, '/installation_progress/reasons/?'))
        ]).then(axios.spread((...responses) => {
            data = {
                recruitmentInstallationProgressCumulative: _isPayloadTruthy(responses[0].data) ? multiBarTransform(responses[0].data, recruitmentInstallationProgressCumulativeColorMap) : null,
                recruitmentInstallationProgress: _isPayloadTruthy(responses[1].data) ? transChartData(responses[1].data, humanReadableLabels, 'bar') : null,
                recruitmentInstallationProgressReasons: responses[2].data,
            };
        }));
        yield put(requestInstallationProgressSnapshotSummaryDataSuccess(data));
    } catch (err) {
        yield put(requestInstallationProgressSnapshotSummaryDataFail(err));
    }
}

function* requesInstallationProgressSummaryFiltersSaga(action: any){
    try {
        const {data} =  yield call(() => get(buildGETUrlWithParams(action.payload, '/installation_progress/filters/?')));
        yield put(requestInstallationProgressSnapshotSummaryFiltersSuccess(data));
    } catch (err) {
        systemError(err);
        yield put(requestInstallationProgressSnapshotSummaryFiltersFailed(err));
    }
}

function* mySagaProvinceFunction(action: any) {
    let data: any = {};
    try {
        yield put(toggleLoadingCursor(true));
        yield axios.all([
            get(buildGETUrlWithParams(action.payload, '/installation_progress/province/?'))
        ]).then(axios.spread((...responses) => {
            data = {
                recruitmentInstallationProgressProvince: responses[0].data,
            };
        }));

        yield put(requestInstallationProgressSnapshotSummaryProvinceDataSuccess(data));
        yield put(toggleLoadingCursor(false));
    } catch (err) {
        yield put(toggleLoadingCursor(false));
        requestInstallationProgressSnapshotSummaryProvinceDataFail(err);
    }
}

export function* installationProgressSnapshotSummary() {
    yield takeLatest(requestInstallationProgressSnapshotSummaryData, mySagaFunction);
    yield takeLatest(requestInstallationProgressSnapshotSummaryProvinceData, mySagaProvinceFunction);
    yield takeLatest(requestInstallationProgressSnapshotSummaryFilters, requesInstallationProgressSummaryFiltersSaga);
}
