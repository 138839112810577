import { createAction } from 'redux-act';
import { LoginRequestView, LoginResponseView } from './reducer';

export const requestLogin = createAction<LoginRequestView>('GET_LOGIN_REQUEST');
export const requestLoginSuccess = createAction<LoginResponseView>('GET_LOGIN_SUCCESS_REQUEST');
export const requestLoginFailed = createAction<any>('GET_LOGIN_FAILED_REQUEST');
export const createCredentials = createAction<any>('CREDENTIALS');


export const requestLogOut = createAction('GET_LOGOUT_REQUEST');


