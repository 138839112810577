
import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-act';
import { createInterviewPlan, createInterviewPlanSuccess, requestInterviewPlan, requestInterviewPlanFailed, requestInterviewPlanFilters, requestInterviewPlanFiltersFailed, requestInterviewPlanFiltersSuccess, requestInterviewPlanProject, requestInterviewPlanProjectFailed, requestInterviewPlanProjectSuccess, requestInterviewPlanSuccess, requestInterviewPlanUpdate, requestInterviewPlanUpdateSuccess, requestPProjectPlanDownloadSuccess, requestProjectPlanDownload, requestProjectPlanDownloadFailed, setOriginalWeeklyPlan, updateInterviewPlan, updateInterviewPlanFailed, updateInterviewPlanSuccess } from './action';
import { downloadFileGet, downloadFilePost, get, post } from '../../../api/api';
import { success, systemError } from '../../../utils/notifyToast';
import { InterviewPlanResponseView, InterviewPlanFiltersRespose, TWeekly, InterviewPlanUpdateRequestView } from '../../../types';
import { buildGETUrlWithParams } from '../../../api/misc/misc';

function* createInterviewPlanFunc(action: Action<InterviewPlanResponseView>){
    
    try{
        const { data } = yield call(() => post('/interview_plan/',action.payload));
        
        yield put(createInterviewPlanSuccess());
        // success('created successfully');
    }catch (err) {
        systemError(err);
        yield put(requestInterviewPlanFailed(err));
    }
}

function* interviewPlanFiltersFunc(action: Action<string | null>){
    
    try{
        const { data } = yield call(() => get(`/interview_plan/filters/?project_name=${action.payload?.replace('#','%23')}`));
        yield put(requestInterviewPlanFiltersSuccess(data as InterviewPlanFiltersRespose));
        
    }catch (err) {
        systemError();
        yield put(requestInterviewPlanFiltersFailed(err));
    }
}

function* requestInterviewPlanFunc(action: Action<string | null>){
    try{
        const { data } = yield call(() => get(`/interview_plan/?project_name=${action.payload?.replace('#','%23')}`));
        const project = data.project;
        let mainRecruitment=0;
        let   activeTsSubmitted=0;
        let   activeTsEstComplete=0;
        let   activeTsEstInstallationsReq=0;
        let    sparkSubmitted=0;
        let   modemSwapsSumitted=0;
        let   modemSwapsEstCompleted=0;
        let   modemSwapsEstInstallationsReq=0;
        let   projectOverview=0;
        let totalMCA = 0;

        const weeklyPlan:TWeekly[] = [{
            week:'Total',
            mainRecruitment,
            id:0,
            activeTsSubmitted,
            activeTsEstComplete,
            activeTsEstInstallationsReq,
            sparkSubmitted,
            modemSwapsSumitted,
            modemSwapsEstCompleted,
            modemSwapsEstInstallationsReq,
            projectOverview,
            isRemoved: false,
            totalMCA
        }];

        (data.weeklyPlan as TWeekly[]).map(plan => {
            plan.isRemoved = false;
            mainRecruitment += plan.mainRecruitment;
            activeTsSubmitted += plan.activeTsSubmitted;
            activeTsEstComplete+= plan.activeTsEstComplete;
            activeTsEstInstallationsReq+= plan.activeTsEstInstallationsReq;
            sparkSubmitted+= plan.sparkSubmitted;
            modemSwapsSumitted+= plan.modemSwapsSumitted;
            modemSwapsEstCompleted+= plan.modemSwapsEstCompleted;
            modemSwapsEstInstallationsReq+= plan.modemSwapsEstInstallationsReq;
            projectOverview+= plan.projectOverview;
            totalMCA += (plan.mainRecruitment + plan.activeTsEstInstallationsReq + plan.sparkSubmitted + plan.modemSwapsEstInstallationsReq);
            plan.totalMCA = (plan.mainRecruitment + plan.activeTsEstInstallationsReq + plan.sparkSubmitted + plan.modemSwapsEstInstallationsReq);
            weeklyPlan.push(plan);
        });
        weeklyPlan[0].mainRecruitment = mainRecruitment;
        weeklyPlan[0].activeTsSubmitted = activeTsSubmitted;
        weeklyPlan[0].activeTsEstComplete = activeTsEstComplete;
        weeklyPlan[0].activeTsEstInstallationsReq = activeTsEstInstallationsReq;
        weeklyPlan[0].sparkSubmitted = sparkSubmitted;
        weeklyPlan[0].modemSwapsSumitted = modemSwapsSumitted;
        weeklyPlan[0].modemSwapsEstCompleted = modemSwapsEstCompleted;
        weeklyPlan[0].modemSwapsEstInstallationsReq = modemSwapsEstInstallationsReq;
        weeklyPlan[0].projectOverview = projectOverview;
        weeklyPlan[0].totalMCA = totalMCA;
        
        const originalWeek = weeklyPlan[0];
        yield put(requestInterviewPlanSuccess({ project , weeklyPlan }));
        yield put(setOriginalWeeklyPlan(originalWeek));
        
    }catch (err) {
        systemError();
        yield put(requestInterviewPlanFailed(err));
    }
}

function* requestInterviewPlanProjectFunc(action: Action<{[key: string]:string}>){
    
    try{
        const requestUrl = buildGETUrlWithParams(action.payload, '/interview_plan/projects/?');
        const { data } = yield call(() => get(requestUrl));
        yield put(requestInterviewPlanProjectSuccess(data as string[]));
        
    }catch (err) {
        systemError();
        yield put(requestInterviewPlanProjectFailed(err));
    }
}

function* updateInterviewPlanFunc(action: Action<InterviewPlanUpdateRequestView>){
    try{
        const { projectName , weeks , project } = action.payload;
        yield call(createInterviewPlanFunc,createInterviewPlan({...project as any}));
        const { data } = yield call(() => post('/interview_plan/weeks/',{ projectName, weeks}));
        yield put(updateInterviewPlanSuccess());
        yield call(requestInterviewPlanFunc,requestInterviewPlan(action.payload.projectName));
        success('updated successfully');
        
    }catch (err) {
        yield put(updateInterviewPlanFailed(err));
    }
}

function* updateInterviewPlanInsideFunc(action: Action<InterviewPlanResponseView>){
    yield put(requestInterviewPlanUpdateSuccess(action.payload));
    //success('updated successfully');
}

function* projectplanFileExportFunc(action: Action<{[key: string]:string | number}>){
    try{
        const { data } = yield call(() => downloadFileGet(`/interview_plan/export_excel/?project_name=${action.payload.projectName}`,`${action.payload.projectName}.xlsx`,));
        success('File downloaded successfully');
        yield put(requestPProjectPlanDownloadSuccess());
    }catch (err) {
        yield put(requestProjectPlanDownloadFailed(err));
    }
}

export function* interviewPlanWatchSaga(){
    yield takeLatest(createInterviewPlan,createInterviewPlanFunc);
    yield takeLatest(requestInterviewPlanFilters,interviewPlanFiltersFunc);
    yield takeLatest(requestInterviewPlan,requestInterviewPlanFunc);
    yield takeLatest(requestInterviewPlanProject, requestInterviewPlanProjectFunc);
    yield takeLatest(updateInterviewPlan,updateInterviewPlanFunc);
    yield takeLatest(requestInterviewPlanUpdate,updateInterviewPlanInsideFunc);
    yield takeLatest(requestProjectPlanDownload, projectplanFileExportFunc);
}
