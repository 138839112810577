import { call, takeLatest, put } from 'redux-saga/effects';
import { get } from '../../../api/api';
import {
    requestRecruitmentProgressPerWeekFilters,
    requestRecruitmentProgressPerWeekFiltersSuccess,
    requestRecruitmentProgressPerWeekFiltersFailed,
    requestRecruitmentProgressPerWeekTotalvsTargetQuota,
    requestRecruitmentProgressPerWeekTotalvsTargetQuotaSuccess,
    requestRecruitmentProgressPerWeekTotalvsTargetQuotaFailed,
    requestRecruitmentProgressPerWeekTotalvsTarget,
    requestRecruitmentProgressPerWeekTotalvsTargetSuccess,
    requestRecruitmentProgressPerWeekTotalvsTargetFailed
} from './actions';
import { systemError } from '../../../utils/notifyToast';
const BASE_PATH = '/recruitment_progress_weeks';
import { buildGETUrlWithParams } from '../../../api/misc/misc';

function* requestRecruitmentProgressPerWeekFiltersSaga(action: any){
    try {
        const {data} =  yield call(() => get(buildGETUrlWithParams(action.payload, `${BASE_PATH}/filters/?`)));
        yield put(requestRecruitmentProgressPerWeekFiltersSuccess(data));
    } catch (err) {
        systemError(err);
        yield put(requestRecruitmentProgressPerWeekFiltersFailed(err));
    }
}

function* requestRecruitmentProgressPerWeekTotalvsTargetSaga(action: any){
    try {
        const {data} =  yield call(() => get(buildGETUrlWithParams(action.payload,`${BASE_PATH}/total_vs_target/?`)));
        yield put(requestRecruitmentProgressPerWeekTotalvsTargetSuccess(data));
    } catch (err) {
        systemError(err);
        yield put(requestRecruitmentProgressPerWeekTotalvsTargetFailed(err));
    }
}

function* requestRecruitmentProgressPerWeekTotalvsTargetQuotaSaga(action: any){
    try {
        const {data} =  yield call(() => get(buildGETUrlWithParams(action.payload, `${BASE_PATH}/total_vs_target_quota/?`)));
        yield put(requestRecruitmentProgressPerWeekTotalvsTargetQuotaSuccess(data));
    } catch (err) {
        systemError(err);
        yield put(requestRecruitmentProgressPerWeekTotalvsTargetQuotaFailed(err));
    }
}

export function* recruitmentProgressPerWeekWatchSaga(){
    yield takeLatest(requestRecruitmentProgressPerWeekFilters, requestRecruitmentProgressPerWeekFiltersSaga);
    yield takeLatest(requestRecruitmentProgressPerWeekTotalvsTarget,requestRecruitmentProgressPerWeekTotalvsTargetSaga);
    yield takeLatest(requestRecruitmentProgressPerWeekTotalvsTargetQuota,requestRecruitmentProgressPerWeekTotalvsTargetQuotaSaga);
}
