import { takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { put } from 'redux-saga/effects';
import { post } from '../../../api/api';
import {
    requestTotalPollingStatsData,
    requestTotalPollingStatsFail,
    requestTotalPollingStatsFilters,
    requestTotalPollingStatsFiltersFail,
    requestTotalPollingStatsFiltersSuccess,
    requestTotalPollingStatsSuccess,
} from './actions';


function* totalPollingStatsFunc(action: any) {
    try {
        let data: any;
        yield axios
            .all([
                post('/total_polling_status/polling_summary/', {country: action.payload.country}),
                post('/total_polling_status/polling_summary/', action.payload),
                post('/total_polling_status/polling_breakdown/', action.payload),
                post('/total_polling_status/total_viewers/', action.payload),
                post('/total_polling_status/total_contact_violations/', action.payload),
            ])
            .then(
                axios.spread((...responses) => {
                    data = {
                        chartPollingCountry: responses[0],
                        chartPolling: responses[1],
                        pollingBreakDown: responses[2],
                        totalViewers: responses[3],
                        totalContactViolations: responses[4],
                    };
                })
            );
        yield put(requestTotalPollingStatsSuccess(data));
    } catch (error) {
        requestTotalPollingStatsFail(error);
    }
}

function* totalPollingStatsFiltersFunc(action: any) {
    try {
        let data: any;
        yield axios
            .all([
                post('/total_polling_status/filters/', action.payload),
            ])
            .then(
                axios.spread((...responses) => {
                    data = {
                        filters: responses[0],
                    };
                })
            );
        yield put(requestTotalPollingStatsFiltersSuccess(data));
    } catch (error) {
        requestTotalPollingStatsFiltersFail(error);
    }
}


export function* totalPollingStatsDataWatchSaga() {
    yield takeLatest(requestTotalPollingStatsData, totalPollingStatsFunc);
    yield takeLatest(requestTotalPollingStatsFilters, totalPollingStatsFiltersFunc);
}
