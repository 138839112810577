export type THumanReadableLabels = {
    [key: string]: string;
};

export const humanReadableLabels: THumanReadableLabels = {
    // TroubleShooting Page
    installerAllocation: 'Installer Allocations',
    pendingSubmission: 'Pending TS Submissions',
    subToCati: 'Submitted to CATI',
    concluded: 'Concluded',
    target: 'Target',
    inProgress: 'In Progress',
    totalPolled: 'Total Polled',
    nonPolling: 'Non Polling',
    polling: 'Polling',
    polling02: 'Polling 0-2',
    nonPolling314: 'Non Polling 3-14',
    nonPolling1534: 'Non Polling 15-34',
    nonPolling35Plus: 'Non Polling 35+',
    totalPanel: 'Total Panel',
    svPolling: 'SV Polling',
    svNonPolling: 'SV Non Polling',
    evPolling: 'EV Polling',
    evPollingGtt: 'EV Polling',
    evNonPolling: 'EV Non Polling',
    evPartialNonContact: 'EV Partial Non Contact',
    suspended: 'Suspend – V/E Change',
    totalPanelGtt: 'Total Panel',
    svPollingGtt: 'SV Polling',
    svNonPollingGtt: 'SV Non Polling',
    evPartialNonContactGtt: 'EV Partial Non Contact',
    evNonPollingGtt: 'EV Non Polling',
    suspendedGtt: 'Suspend – V/E Change',
    allocationPending: 'Allocation Pending',
    // Recruitment Pages
    removedPreinstall: 'Removed Pre-Install'
};
