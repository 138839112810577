import { createReducer } from 'redux-act';
import { createCredentials, requestLogin , requestLoginFailed, requestLoginSuccess, requestLogOut } from './action';


export type LoginResponseView = {
  refresh: string;
  access:string;
}

export type LoginRequestView = {
  username: string;
  password:string;
}

export type TCredentialsState = {
  refresh: string;
  access:string;
  isLoading: boolean;
  error: any;
}


const defaultCrendentialsState: TCredentialsState = {
    refresh: '',
    access:'',
    isLoading:false,
    error:''
};

export const credentialsReducer = createReducer<TCredentialsState>({}, defaultCrendentialsState);

credentialsReducer.on(createCredentials, (state, data) => ({
    ...state,
    data,
})).on(requestLogin, state => ({
    ...state,
    isLoading: true,
    error:''
})).on(requestLoginSuccess,(state, payload) => ({
    ...state,
    isLoading: false,
    refresh: payload.refresh,
    access: payload.access,
})).on(requestLoginFailed,(state, payload) => ({
    ...state,
    isLoading: false,
    error: payload
})).on(requestLogOut, state => ({
    ...state,
    ...defaultCrendentialsState
}));

    

