import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { TQuotaDesignFilterResponse, TQuotaDesignResponse , TQuotaDesignRequest, TQuotaDesignPriorityRequest, TQuotaDesignCompondedResponse, TQuotaDesignCompondedRequest} from '../../../types';

export const requestQuotaDesign = createAction<string>('GET_QUOTA_DESIGN_REQUEST');
export const requestQuotaDesignSuccess = createAction<TQuotaDesignResponse[]>('GET_QUOTA_DESIGN_SUCCESS_REQUEST');
export const requestQuotaDesignFailed = createAction<any>('GET_QUOTA_DESIGN_FAILED_REQUEST');
export const requestQuotaDesignReset = createAction('GET_QUOTA_DESIGN_RESET_REQUEST');

export const saveQuotaDesignWeeks = createAction<TQuotaDesignRequest>('SAVE_QUOTA_DESIGN_WEEKS_REQUEST');
export const saveQuotaDesignWeeksSuccess = createAction('SAVE_QUOTA_DESIGN_WEEKS_SUCCESS');
export const saveQuotaDesignWeeksFailed = createAction<any>('SAVE_QUOTA_DESIGN_WEEKS_FAILED');
export const saveQuotaDesignWeeksReset = createAction('SAVE_QUOTA_DESIGN_RESET_WEEKS_REQUEST');

export const saveQuotaDesignPriority = createAction<TQuotaDesignPriorityRequest>('SAVE_QUOTA_DESIGN_PRIORITY_REQUEST');
export const saveQuotaDesignPrioritySuccess = createAction('SAVE_QUOTA_DESIGN_PRIORITY_SUCCESS');
export const saveQuotaDesignPriorityFailed = createAction<any>('SAVE_QUOTA_DESIGN_PRIORITY_FAILED');
export const saveQuotaDesignPriorityReset = createAction('SAVE_QUOTA_DESIGN_RESET_PRIORITY_REQUEST');

export const requestQuotaDesignFilters = createAction<TFilters>('GET_QUOTA_DESIGN_FILTERS_REQUEST');
export const requestQuotaDesignFiltersSuccess = createAction<TQuotaDesignFilterResponse>('GET_QUOTA_DESIGN_FILTERS_SUCCESS_REQUEST');
export const requestQuotaDesignFiltersFailed = createAction<any>('GET_QUOTA_DESIGN_FILTERS_FAILED_REQUEST');

export const requestQuotaDesignWeeksCompounded = createAction<TQuotaDesignCompondedRequest>('REQUESTQUOTA_DESIGN_WEEKS_COMPOUNDED_REQUEST');
export const requestQuotaDesignWeeksCompoundedSuccess = createAction<TQuotaDesignCompondedResponse[]>('REQUESTQUOTA_DESIGN_WEEKS_COMPOUNDED_SUCCESS');
export const requestQuotaDesignWeeksCompoundedFailed = createAction<any>('REQUESTQUOTA_DESIGN_WEEKS_COMPOUNDED_FAILED');
export const requestQuotaDesignWeeksCompoundedReset = createAction('REQUEST_QUOTA_DESIGN_RESET_WEEKS_COMPOUNDED_REQUEST');

export const exportQuotaDesignWeeksCompounded = createAction<TQuotaDesignCompondedRequest>('EXPORT_QUOTA_DESIGN_WEEKS_COMPOUNDED_REQUEST');
export const exportQuotaDesignWeeksCompoundedSuccess = createAction('EXPORT_QUOTA_DESIGN_WEEKS_COMPOUNDED_SUCCESS');
export const exportQuotaDesignWeeksCompoundedFailed = createAction<any>('EXPORT_QUOTA_DESIGN_WEEKS_COMPOUNDED_FAILED');
