import { combineReducers } from 'redux';
import { TAppState } from '../types';
import { filterReducer } from './filters/reducer';
import { homeReducer } from './home/reducer';
import { credentialsReducer } from './login/reducer';
import { userAccessReducer } from './userAccess/reducer';
import { adminReducer } from './admin/reducer';
import { boilerPlateReducer } from './boilerPlate/reducer';
import { combinedTroubleShootingReducer } from './troubleShootingReducer';
import { recruitmentReducer } from './recruitmentReducer';
import { changePasswordReducer, confirmResetPasswordReducer, resetPasswordReducer } from './passwordReset/reducers';

const rootReducer = combineReducers<TAppState>({
    home: homeReducer,
    admin: adminReducer,
    boilerPlate: boilerPlateReducer,
    filterState: filterReducer,
    userCredentials: credentialsReducer,
    userAccess: userAccessReducer,
    troubleShooting: combinedTroubleShootingReducer,
    recruitment: recruitmentReducer,
    password: resetPasswordReducer,
    passwordChange: changePasswordReducer,
    confirmReset: confirmResetPasswordReducer
});

export default rootReducer;

