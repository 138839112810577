import { createReducer } from 'redux-act';
import { defaultFormState } from './formState/defaultFormState';
import { genPaginatedData } from '../../../utils/pagination/pagiantion';
import { clearTechnicianTroubleshootingFilters, postTechnicianTroubleshootingFormData, postTechnicianTroubleshootingFormDataFail, requestExportTechnicianTroubleshootingTableData, requestExportTechnicianTroubleshootingTableDataFail, requestExportTechnicianTroubleshootingTableDataSuccess, requestTechnicianTroubleshootingData, requestTechnicianTroubleshootingDataFail, requestTechnicianTroubleshootingDataSuccess, requestTechnicianTroubleshootingFiltersSuccess, updateCategoryFilters, updateQueryParameters, updateQueryParametersSuccess } from './actions';
import { setFilters } from '../troubleShootingActivities/actions';
import { TFilters } from '../../../types/commonTypes';

export type TTechnicianTroubleshootingState = {
    isLoading: boolean,
    error: any,
    dataList: any,
    categoryFilters: {[key: string]: boolean},
    formBody: {[key: string]: number | string},
    queryParameters: TFilters | null,
    fileExportIsDownloading: boolean,
    filters: {[key: string]: string[]}

}

const defaultState: TTechnicianTroubleshootingState = {
    isLoading: true,
    error: null,
    dataList: null,
    categoryFilters: {},
    formBody: defaultFormState,
    queryParameters: null,
    fileExportIsDownloading: false,
    filters: {}
};

export const technicianTroubleshootingReducer = createReducer({}, defaultState);

technicianTroubleshootingReducer
    .on(updateCategoryFilters, (state, payload) => ({
        ...state,
        categoryFilters: payload
    }))
    .on(requestTechnicianTroubleshootingData, state => ({
        ...state
    }))
    .on(requestTechnicianTroubleshootingDataSuccess, (state, payload) => {
        return {
            ...state,
            dataList: genPaginatedData(40, payload),
            isLoading: false,
        };
    })
    .on(requestTechnicianTroubleshootingFiltersSuccess, (state, payload) => ({
        ...state,
        filters: payload
    }))
    .on(requestTechnicianTroubleshootingDataFail, (state, payload) => ({
        ...state,
        error: payload,
        isLoading: false
    })).on(postTechnicianTroubleshootingFormData, state => ({
        ...state,
        isLoading: true
    })).on(postTechnicianTroubleshootingFormDataFail, (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload
    })).on(updateQueryParameters, (state, payload: TFilters) => ({
        ...state,
        isLoading: true,
        queryParameters: {
            ...payload
        }
    })).on(clearTechnicianTroubleshootingFilters, state => ({
        ...state,
        isLoading: true,
        queryParameters: {}
    })).on(requestExportTechnicianTroubleshootingTableData, state => ({
        ...state,
        fileExportIsDownloading: true
    })).on(requestExportTechnicianTroubleshootingTableDataSuccess, state => ({
        ...state,
        fileExportIsDownloading: false
    })).on(requestExportTechnicianTroubleshootingTableDataFail, (state, payload: any) => ({
        ...state,
        fileExportIsDownloading: false,
        error: payload
    })).on(setFilters, (state, payload) => ({
        ...state,
        filters: payload
    }))
    // .on(requestTechnicianTroubleshootingDataSuccess, (state, payload) => ({
    //     ...state,
    //     dataList: genPaginatedData(40, payload[0].data),
    //     filters: payload[1].data,
    //     isLoading: false,
    // }))
    .on(updateQueryParametersSuccess, (state, payload) => ({
        ...state,
        dataList: genPaginatedData(40, payload),
        isLoading: false,
    }));
