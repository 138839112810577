export const options = {
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true   // minimum value will be 0.
            }
        }],
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        }
    },
};

export const optionsWithLegends = {
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true   // minimum value will be 0.
            }
        }],
    },
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        }
    },
};

export const percentageOption: any = {
    scales: {
        y: {
            max: 100
        }
    },
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Values as %',
            position: 'left',
        },
    },
};
