import { createReducer } from 'redux-act';
import { requestIngestionData, requestIngestionDataFail, requestIngestionDataSuccess } from './actions';

export type TFileIngestionState = {
    'SCR': any,
    'PMR': any,
    'INTAB HH': any,
    'INTAB SC': any,
    'CATI TS': any,
    'DMC': any,
    isLoading: boolean,
    error: any,
    dataList: any,
    cacheOption: string
};

const defaultState: TFileIngestionState = {
    'SCR': [],
    'PMR': [],
    'INTAB HH': [],
    'INTAB SC': [],
    'CATI TS': [],
    'DMC': [],
    isLoading: true,
    error: null,
    dataList: [],
    cacheOption: ''
};

export const fileIngestionReducer = createReducer<TFileIngestionState>({}, defaultState);

fileIngestionReducer.on(requestIngestionData, (state) => ({
    ...state,
    isLoading: true,
})).on(requestIngestionDataSuccess, (state, payload) => ({
    ...state,
    isLoading: false,
    error: null,
    cacheOption: payload.cacheOption ? payload.cacheOption : '.',
    dataList: {
        'SCR': payload.scr,
        'PMR': payload.pmr,
        'INTAB HH': payload.intab_hh,
        'INTAB SC': payload.intab_sc,
        'CATI TS': payload.cati_ts,
        'DMC': payload.dmc
    }
})).on(requestIngestionDataFail, (state, payload) => ({
    ...state,
    isLoading: false,
    error: payload
}));
