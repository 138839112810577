import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { get } from '../../../api/api';
import { multiBarTransform, removeZeroValuedData } from '../../../utils/graphs&charts/transBarData/multiBarTransData';
import { transChartData, transChartDataPercentage } from '../../../utils/graphs&charts/transBarData/transformData';
import { systemError } from '../../../utils/notifyToast';
import { requestSnapshotSummaryData, requestSnapshotSummaryDataFail, requestSnapshotSummaryDataSuccess, requestSnapshotSummaryFilters, requestSnapshotSummaryFiltersFail, requestSnapshotSummaryFiltersSuccess, requestSnapshotSummaryFilterUpdate } from './actions';
import { colorMap, recruitedToDateViewerExperienceColorMap, snapShotSummaryHumanReadableLables } from './meta/meta';
import { buildGETUrlWithParams } from '../../../api/misc/misc';

function* mySagaFunction(action: any) {
    const data: any = {};

    try {
        yield axios.all([
            get(buildGETUrlWithParams(action.payload, '/recruitment_fiscal_weeks/total_vs_target/?')),
            get(buildGETUrlWithParams(action.payload, '/recruitment_fiscal_weeks/package/?')),
            get(buildGETUrlWithParams(action.payload, '/recruitment_fiscal_weeks/decoder_type/?')),
            get(buildGETUrlWithParams(action.payload, '/recruitment_fiscal_weeks/region/?')),
            get(buildGETUrlWithParams(action.payload, '/recruitment_fiscal_weeks/viewer_experience/?')),
        ]).then(axios.spread((...responses: any) => {
            data.recruitmentFiscalWeeksTotalVsTarget = transChartData(
                responses[0].data,
                snapShotSummaryHumanReadableLables,
                'bar'
            );
            const percentageBase = data.recruitmentFiscalWeeksTotalVsTarget[0].datasets[0].data[0];

            //Recruitment percentage
            data.recruitmentFiscalWeeksTotalVsTargetPercentage = transChartDataPercentage(
                responses[0].data,
                snapShotSummaryHumanReadableLables,
                'bar'
            );
            data.recruitmentFiscalWeeksTotalVsTargetPercentage[0].datasets = percentageConverter(data.recruitmentFiscalWeeksTotalVsTarget[0].datasets ,percentageBase);


            data.recruitmentFiscalWeeksTotalVsTargetActive = transChartData(
                responses[0].data,
                snapShotSummaryHumanReadableLables,
                'bar'
            );
            data.recruitmentFiscalWeeksPackage = multiBarTransform(responses[1].data, colorMap, null, snapShotSummaryHumanReadableLables);

            //Package percentage
            data.recruitmentFiscalWeeksPackagePercentage = multiBarTransform(responses[1].data, colorMap, true, snapShotSummaryHumanReadableLables);
            data.recruitmentFiscalWeeksPackagePercentage[0].datasets = percentageConverter(data.recruitmentFiscalWeeksPackage[0].datasets, percentageBase);

            data.recruitmentFiscalWeeksPackageActive = multiBarTransform(responses[1].data, colorMap, null, snapShotSummaryHumanReadableLables);

            data.recruitmentFiscalWeeksDecoderType = multiBarTransform(responses[2].data, colorMap);

            //Decoder Type percentage
            data.recruitmentFiscalWeeksDecoderTypePercentage = multiBarTransform(responses[2].data, colorMap, true);
            data.recruitmentFiscalWeeksDecoderTypePercentage[0].datasets = percentageConverter(data.recruitmentFiscalWeeksDecoderType[0].datasets, percentageBase);


            data.recruitmentFiscalWeeksDecoderTypeActive = multiBarTransform(responses[2].data, colorMap);
            data.recruitmentFiscalWeeksRegion = multiBarTransform(responses[3].data, colorMap);

            //Region Active percentage
            data.recruitmentFiscalWeeksRegionPercentage = multiBarTransform(responses[3].data, colorMap, true);
            data.recruitmentFiscalWeeksRegionPercentage[0].datasets = percentageConverter(data.recruitmentFiscalWeeksRegion[0].datasets, percentageBase);


            data.recruitmentFiscalWeeksRegionActive = multiBarTransform(responses[3].data, colorMap);

            const viewerExperienceWithoutZeroData = removeZeroValuedData(responses[4].data);
            data.recruitmentFiscalWeeksViewerExperience = transChartData(
                viewerExperienceWithoutZeroData,
                snapShotSummaryHumanReadableLables,
                '',
                recruitedToDateViewerExperienceColorMap
            );
            //Viewer experience percentage
            data.recruitmentFiscalWeeksViewerExperiencePercentage = transChartDataPercentage(
                viewerExperienceWithoutZeroData,
                snapShotSummaryHumanReadableLables,
                'bar',
                '',
                recruitedToDateViewerExperienceColorMap
            );
            data.recruitmentFiscalWeeksViewerExperiencePercentage[0].datasets = percentageConverter(data.recruitmentFiscalWeeksViewerExperience[0].datasets, percentageBase);


            data.recruitmentFiscalWeeksViewerExperienceActive = transChartData(
                viewerExperienceWithoutZeroData,
                snapShotSummaryHumanReadableLables,
                '',
                recruitedToDateViewerExperienceColorMap
            );
        }));
        yield put(requestSnapshotSummaryDataSuccess(data));
    } catch (err) {
        systemError(err);
        yield put(requestSnapshotSummaryDataFail(err));
    }
}

function* requestFiltersFn(action: any) {
    try {
        const { data } = yield get(buildGETUrlWithParams(action.payload,'/recruitment_fiscal_weeks/filters/?'));
        yield put(requestSnapshotSummaryFiltersSuccess(data));
    } catch (err) {
        systemError(err);
        yield put(requestSnapshotSummaryFiltersFail(err));
    }
}

export function* snapshotSummaryWatchSaga() {
    yield takeLatest(requestSnapshotSummaryData, mySagaFunction);
    yield takeLatest(requestSnapshotSummaryFilters, requestFiltersFn);
}

const percentageConverter = (datasetsArray: any, base: number) => {
    const newDataset = datasetsArray.map((item: any) => {
        const newData = item.data.map((dataItem: any) => Math.floor(100*(dataItem/base)));
        return {...item, data: newData};
    });
    return newDataset;
};
