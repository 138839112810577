import { createAction } from 'redux-act';


export const requestAdminData = createAction('REQUEST_ADMIN_DATA');
export const requestAdminDataSuccess = createAction<any>('REQUEST_ADMIN_DATA_SUCCESS');
export const requestAdminDataFail = createAction<any>('REQUEST_ADMIN_DATA_FAIL');

export const submitForm = createAction<any[]>('ADMIN_SUBMIT_FORM');

export const updateFilters = createAction<{[key: string]: string | number | (string | number)[]}>('ADMIN_UPDATE_FILTERS');
export const clearFilters = createAction('ADMIN_CLEAR_FILTERS');

export const requestAddUser = createAction('ADMIN_REQUEST_ADD_USER');
export const requestAddUserSuccess = createAction<any[]>('ADMIN_REQUEST_ADD_USER_SUCCESS');
export const requestAddUserFail = createAction<any>('ADMIN_REQUEST_ADD_USER_FAIL');
