import { createReducer } from 'redux-act';
import { updateFilters } from './action';

export type TFilterState = {
    smartcard_id?: number,
    customer_id?: number,
    country?: string,
    polling_bucket?: string,
    viewer_experience?: string,
    decoder_type?: string,
    connected_services_indicator?: boolean,
    actuals?: number,
    package?: string,
    network?: string,
    tenure?: string,
    province?: string,
    hh_status?: string,
    hh_sub_status?: string,
    hh_unique_polling_status?: string,
    service?: string,
    modem_type?: string,
    modem_supplier?: string,
    recruitment_phase?: string,
    ts_bucket_classification?: string,
    connected_flag?: string,
    equipment_set_up?: boolean,
    no_of_smartcards?: number,
    days_since_last_poll?: number,
    submission_1_batch_number?: number,
    submission_2_batch_number?: number,
    platform?: string[]
}
const defaultFilterState: TFilterState = {};

export const filterReducer = createReducer<TFilterState>({}, defaultFilterState);

filterReducer.on(updateFilters, (state, payload) => {
    return ({
        ...state,
        ...payload
    });
});
