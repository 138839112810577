

type Data = { [key: string]: string }

export const columnFormats: Data = {
    'householdStatus': 'DropDownFilter',
    'householdSubStatus': 'DropDownFilter',
    'panel': 'DropDownFilter',
    'recruitmentPhase': 'DropDownFilter',
    'noOfSmartcards': 'DropDownFilter',
    'decoderTypeSc1': 'DropDownFilter',
    'modemTypeSc1': 'DropDownFilter',
    'decoderTypeSc2': 'DropDownFilter',
    'modemTypeSc2': 'DropDownFilter',
    'package': 'DropDownFilter',
    'viewerExperience': 'DropDownFilter',
    'daysSinceLastPollSc1': 'DropDownFilter',
    'pollingBucketSc1': 'DropDownFilter',
    'daysSinceLastPollSc2': 'DropDownFilter',
    'pollingBucketSc2': 'DropDownFilter',
    'onPmrHh': 'DropDownFilter',
    'connectedFlag': 'DropDownFilter',
    'connectedServicesIndicator': 'DropDownFilter',
    'uniqueHouseholdPollingStatus': 'DropDownFilter',
    'troubleshootingBucket': 'DropDownFilter',
    'batchNumber': 'DropDownFilter',
    'troubleshootingBucketAtSubmission': 'DropDownFilter',
    'phoneCallDate': 'DropDownFilter',
    'numberOfCalls': 'DropDownFilter',
    'callResponseCode': 'DropDownFilter',
    'callResponseOutcome': 'DropDownFilter',
    'dateSubmittedToFs': 'DropDownFilter',
    'techAllocated': 'DropDownFilter',
    'installerVisitDate': 'DropDownFilter',
    'monitor5daysStart': 'DropDownFilter',
    'polledConsistently5days': 'DropDownFilter',
    'polledConsistently5daysDroppedDate': 'DropDownFilter',
    'resolution': 'DropDownFilter',
    'catiFsFeedback': 'DropDownFilter',
    'submitToCati': 'Checkbox',
    'submitToFs': 'Checkbox',
};

export const labels: Data = {
    recruitmentPhase: 'Recruitment Phase',
    noOfSmartcards: 'No. of Smartcards',
    smartcardIdSc1: 'Primary Smartcard(current)',
    decoderTypeSc1: 'Primary Decoder',
    modemTypeSc1: 'Primary Smartcard Modem',
    smartcardIdSc2: 'Secondary Smartcard(current)',
    decoderTypeSc2: 'Secondary Decoder',
    modemTypeSc2: 'Secondary Smartcard Modem',
    package: 'Package',
    viewerExperience: 'Viewer Experience',
    daysSinceLastPollSc1: 'SC1 Days since last poll',
    pollingBucketSc1: 'SC1 bucket',
    daysSinceLastPollSc2: 'SC2 Days since last poll',
    pollingBucketSc2: 'SC2 bucket',
    onPmrHh: 'On the PMR',
    connectedFlag: 'Connected Flag',
    connectedServicesIndicator: 'Connected Services Indicator',
    uniqueHouseholdPollingStatus: 'Unique Household Polling Status',
    troubleshootingBucket: 'Bucket Classification',
    batchNumber: 'Batch',
    troubleshootingBucket_atSubmission: 'Bucket Classification at time of submission',
    sms1Date: 'SMS Notification 1 Date',
    sms2Date: 'SMS Notification 2 Date',
    phoneCallDate: 'Date of phonecall',
    numberOfCalls: 'Number of attempts (Phone)',
    callResponseCode: 'Response Code (Phone)',
    callResponseOutcome: 'Response Outcome (Phone)',
    dateSubmittedToFs: 'TS List sent to FS Date',
    tsDatePlus30d: 'TS List Max 30 Day count	',
    tech_allocated: 'Tech allocated',
    installerVisitDate: 'Installer Visit Date',
    monitor5DaysStart: 'Monitor for 5 days (Start)',
    monitor5DaysEnd: ' Monitor for 5 days (End)',
    polledConsistently5days: 'Polled Consistently during 5 day monitoring',
    polledConsistently5daysDroppedDate: 'Polled Consistently during 5 day monitoring (Date Dropped)',
    resolution: 'Resolution',
    catiFsFeedback: 'FS/CATI Feedback',
    comment: 'Comment',
    commentDate: 'Comment Date',
    submitToCati: 'Submit to CATI',
    submitToFs: 'Submit to FS',
};
