import { createAction } from 'redux-act';
import { InterviewPlanResponseView, InterviewPlanFiltersRespose, InterviewPlanUpdateRequestView, TWeekly } from '../../../types';
import { TFilters } from '../../../types/commonTypes';

export const requestInterviewPlan = createAction<string | null>('GET_INTERVIEW_PLAN_REQUEST');
export const requestInterviewPlanSuccess = createAction<InterviewPlanResponseView>('GET_INTERVIEW_PLAN_SUCCESS_REQUEST');
export const requestInterviewPlanFailed = createAction<any>('GET_INTERVIEW_PLAN_FAILED_REQUEST');
export const requestInterviewPlanReset = createAction('GET_INTERVIEW_PLAN_FAILED_RESET');

export const setOriginalWeeklyPlan = createAction<TWeekly>('SET_ORIGINAL_WEEKLY_PLAN');

export const requestInterviewPlanUpdate = createAction<InterviewPlanResponseView>('GET_INTERVIEW_PLAN_REQUEST_UPDATE');
export const requestInterviewPlanUpdateSuccess = createAction<InterviewPlanResponseView>('GET_INTERVIEW_PLAN_REQUEST_UPDATE_SUCCESS');

export const createInterviewPlan = createAction<InterviewPlanResponseView>('CREATE_INTERVIEW_PLAN_REQUEST');
export const createInterviewPlanFailed = createAction<any>('CREATE_INTERVIEW_PLAN_FAILED_REQUEST');
export const createInterviewPlanSuccess = createAction('CREATE_INTERVIEW_PLAN_SUCCESS_REQUEST');

export const requestInterviewPlanFilters = createAction<string | null>('GET_INTERVIEW_PLAN_FILTERS_REQUEST');
export const requestInterviewPlanFiltersSuccess = createAction<InterviewPlanFiltersRespose>('GET_INTERVIEW_PLAN_FILTERS_SUCCESS_REQUEST');
export const requestInterviewPlanFiltersFailed = createAction<any>('GET_INTERVIEW_PLAN_FILTERS_FAILED_REQUEST');


export const requestInterviewPlanProject = createAction<TFilters>('GET_INTERVIEW_PLAN_PROJECT_REQUEST');
export const requestInterviewPlanProjectSuccess = createAction<string[]>('GET_INTERVIEW_PLAN_PROJECT_SUCCESS_REQUEST');
export const requestInterviewPlanProjectFailed = createAction<any>('GET_INTERVIEW_PLAN_PROJECT_FAILED_REQUEST');
export const requestInterviewPlanProjectReset= createAction('GET_INTERVIEW_PLAN_PROJECT_RESET_REQUEST');

export const updateInterviewPlan = createAction<InterviewPlanUpdateRequestView>('UPDATE_INTERVIEW_PLAN_REQUEST');
export const updateInterviewPlanSuccess = createAction('UPDATE_INTERVIEW_PLAN_SUCCESS_REQUEST');
export const updateInterviewPlanFailed = createAction<any>('UPDATE_INTERVIEW_PLAN_FAILED_REQUEST');

export const requestProjectPlanDownload = createAction<{[key: string]:string | number}>('GET_PROJECT_PLAN_DOWNLOAD_REQUEST');
export const requestPProjectPlanDownloadSuccess = createAction('GET_PROJECT_PLAN_SUCCESS_DOWNLOAD_REQUEST');
export const requestProjectPlanDownloadFailed = createAction<any>('GET_PROJECT_PLAN_FAILED_DOWNLOAD_REQUEST');



