import { put, takeLatest } from '@redux-saga/core/effects';
import { requestIngestionData, requestIngestionDataFail, requestIngestionDataSuccess } from './actions';
import { get } from '../../../api/api';
import axios from 'axios';
import { systemError } from '../../../utils/notifyToast';

function* myFunc(action: any | string) {
    try {
        let data: any;
        yield axios.all([
            get('/file_ingester/?source=SCR'),
            get('/file_ingester/?source=PMR'),
            get('/file_ingester/?source=INTAB HH'),
            get('/file_ingester/?source=INTAB SC'),
            get('/file_ingester/?source=CATI TS'),
            get('/file_ingester/?source=DMC'),
        ]).then((...responses) => {
            data = {
                scr: responses[0][0].data,
                pmr: responses[0][1].data,
                intab_hh: responses[0][2].data,
                intab_sc: responses[0][3].data,
                cati_ts: responses[0][4].data,
                dmc: responses[0][5].data,
                cacheOption: action.payload ? action.payload : ''
            };
        });
        yield put(requestIngestionDataSuccess(data));
    } catch (error) {
        yield put(requestIngestionDataFail(error));
    }
}

export function* fileIngestionWatchSaga() {
    yield takeLatest(requestIngestionData, myFunc);
}
