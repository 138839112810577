import { createReducer } from 'redux-act';
import {
    exportSubmissionAndMonitoringTable,
    exportSubmissionAndMonitoringTableFail,
    exportSubmissionAndMonitoringTableSuccess,
    postSubmissionAndMonitoringFormData,
    postSubmissionAndMonitoringFormDataFail,
    postSubmissionAndMonitoringFormDataSuccess,
    requestSubmissionAndMonitoringData,
    requestSubmissionAndMonitoringFiltersSuccess,
    requestSubmissionAndMonitoringDataFail,
    requestSubmissionAndMonitoringDataSuccess,
    exportSubmissionAndMonitoringDemmographicsTable,
    setTableFilters,
    updateFilters,
    exportSubmissionAndMonitoringDemmographicsTableSuccess,
    postCommentSubmissionAndMonitoringFormData,
    postCommentSubmissionAndMonitoringFormDataSuccess,
    postCommentSubmissionAndMonitoringFormDataFail,
    updateCommentsFormBody,
    updateDemographicsFilterState
} from './actions';
import { genPaginatedData, transformValuesToString } from '../../../utils/pagination/pagiantion';
import { genFilterList } from '../../../utils/sumbissionAndMonitoring/filtering';
import { TFormBody } from '../../../types/formTypes';
import { genDemographicsFilters } from '../../../utils/dataMapping/submissionAndMonitoring/helperFunctions';

type FilterData = { [key: string]: string[] }

export type TSubmissionMonitoringTrackerState = {
    isLoading: boolean,
    error: string,
    dataList: any, // Raw State
    tableFilters: FilterData,
    selectedFilters: { [key: string]: number | string | (string | number)[] } | null,
    existingFiltersRecord: { [key: string]: string[] },
    page: number,
    filters: FilterData | null,
    formBody: TFormBody,
    exportLoading: boolean,
    tableExportData: any | [],
    commentFormBody: TFormBody,
    demographicsTableFilterState: {[key: string]: boolean} | null
}

const defaultState: TSubmissionMonitoringTrackerState = {
    isLoading: true,
    error: '',
    selectedFilters: null,
    dataList: null,
    tableFilters: {},
    existingFiltersRecord: {},
    page: 0,
    filters: null,
    formBody: [],
    exportLoading: false,
    tableExportData: [],
    commentFormBody: [],
    demographicsTableFilterState: null
};

export const submissionMonitoringTrackerReducer = createReducer({}, defaultState);

submissionMonitoringTrackerReducer.on(updateFilters, (state, payload: any) => ({
    ...state,
    selectedFilters: payload,
    isLoading: true
})).on(requestSubmissionAndMonitoringData, state => ({
    ...state,
}))
    .on(requestSubmissionAndMonitoringDataSuccess, (state, payload) => ({
        ...state,
        dataList: transformValuesToString(genPaginatedData(100, payload.data)),
        tableFilters: genFilterList(payload.data),
        isLoading: false,
        exportLoading: false,
        tableExportData: []
    }))
    .on(requestSubmissionAndMonitoringFiltersSuccess, (state, payload) => ({
        ...state,
        filters: payload.filterData,
    }))
    .on(requestSubmissionAndMonitoringDataFail, (state, payload) => ({
        ...state,
        error: payload,
        isLoading: false
    })).on(setTableFilters, (state, payload: any) => ({
        ...state,
        isLoading: false
    })).on(postSubmissionAndMonitoringFormData, (state, payload: any) => ({
        ...state,
    })).on(postSubmissionAndMonitoringFormDataSuccess, (state, payload: any) => ({
        ...state,
        tableExportData: genPaginatedData(50, payload.tableExportData),
        demographicsTableFilterState: genDemographicsFilters(payload.tableExportData),
        selectedFilters: {},
        formBody: [],
        commentFormBody: [],
    })).on(postSubmissionAndMonitoringFormDataFail, (state, payload: any) => ({
        ...state,
        isLoading: false,
        error: payload,
        formBody: []
    })).on(exportSubmissionAndMonitoringTable, (state) => ({
        ...state,
        exportLoading: true,
    })).on(exportSubmissionAndMonitoringTableFail, (state, payload) => ({
        ...state,
        exportLoading: false,
        error: payload
    })).on(exportSubmissionAndMonitoringTableSuccess, (state) => ({
        ...state,
        exportLoading: false,
    })).on(exportSubmissionAndMonitoringDemmographicsTable, (state) => ({
        ...state,
        exportLoading: true
    })).on(exportSubmissionAndMonitoringDemmographicsTableSuccess, (state) => ({
        ...defaultState,
        formBody: [],
    })).on(postCommentSubmissionAndMonitoringFormData, (state) => ({
        ...state
    })).on(postCommentSubmissionAndMonitoringFormDataSuccess, (state, payload) => ({
        ...state,
        commentFormBody: [],
        dataList: payload
    })).on(postCommentSubmissionAndMonitoringFormDataFail, (state, payload) => ({
        ...state,
        error: payload
    })).on(updateCommentsFormBody, (state, payload) => ({
        ...state,
        commentFormBody: payload
    })).on(updateDemographicsFilterState, (state, payload) => ({
        ...state,
        demographicsTableFilterState: payload
    }));
