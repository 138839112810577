import { combineReducers } from 'redux';
import { fileIngestionReducer } from './troubleShooting/fileIngestion/reducer';
import { progressTrackerFileExportReducer, progressTrackerFilterReducer, progressTrackerReducer } from './troubleShooting/progressTracker/reducer';
import { totalPollingStatsReducer } from './troubleShooting/totalPollingStats/reducer';
import { troubleShootingActivitiesReducer } from './troubleShooting/troubleShootingActivities/reducers';
import { reportingTrackerFileExportReducer, reportingTrackerFilterReducer, reportingTrackerReducer } from './troubleShooting/reportingTracker/reducer';
import { submissionMonitoringTrackerReducer } from './troubleShooting/submissionsMonitoringTracker/reducer';
import { exceptionListFileExportReducer, exceptionListFilterReducer, exceptionListReducer } from './troubleShooting/exceptionList/reducer';
import { batchNumberTrackerFilterReducer, batchNumberTrackerReducer } from './troubleShooting/batchNumberTracker/reducer';
import { technicianTroubleshootingReducer } from './troubleShooting/technicianTroubleshooting/reducer';
import { outcomeFilterReducer, outcomeInstallReducer, outcomeReducer, outcomeResReducer } from './troubleShooting/outcomes/reducer';
import { TTroubleShootingState } from '../types/state.types';


export const combinedTroubleShootingReducer = combineReducers<TTroubleShootingState>({
    progressTracker: progressTrackerReducer,
    progressTrackerFilters: progressTrackerFilterReducer,
    troubleShootingActivities: troubleShootingActivitiesReducer,
    totalPollingStats: totalPollingStatsReducer,
    fileIngestion: fileIngestionReducer,
    reportingTracker: reportingTrackerReducer,
    reportingTrackerFilters: reportingTrackerFilterReducer,
    submissionMonitoringTracker: submissionMonitoringTrackerReducer,
    technicianTroubleshooting: technicianTroubleshootingReducer,
    exceptionList: exceptionListReducer,
    exceptionListFilters: exceptionListFilterReducer,
    batchNumberTracker: batchNumberTrackerReducer,
    batchNumberTrackerFilters: batchNumberTrackerFilterReducer,
    progressTrackerFileExport: progressTrackerFileExportReducer,
    exceptionListFileExport: exceptionListFileExportReducer,
    outcomes: outcomeReducer,
    outcomesFilters: outcomeFilterReducer,
    installerCall: outcomeInstallReducer,
    resolutionCall: outcomeResReducer,
    reportingTrackerFileExport: reportingTrackerFileExportReducer,
});

