
import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-act';
import { downloadFileGet, get, post } from '../../../api/api';
import { success, systemError } from '../../../utils/notifyToast';
import {
    exportQuotaDesignWeeksCompounded,
    exportQuotaDesignWeeksCompoundedFailed,
    exportQuotaDesignWeeksCompoundedSuccess,
    requestQuotaDesign,
    requestQuotaDesignFailed,
    requestQuotaDesignFilters,
    requestQuotaDesignFiltersFailed,
    requestQuotaDesignFiltersSuccess,
    requestQuotaDesignSuccess,
    requestQuotaDesignWeeksCompounded,
    requestQuotaDesignWeeksCompoundedFailed,
    requestQuotaDesignWeeksCompoundedSuccess,
    saveQuotaDesignPriority,
    saveQuotaDesignPriorityFailed,
    saveQuotaDesignPrioritySuccess,
    saveQuotaDesignWeeks,
    saveQuotaDesignWeeksFailed,
    saveQuotaDesignWeeksSuccess } from './action';
import { TQuotaDesignCompondedRequest, TQuotaDesignPriorityRequest, TQuotaDesignRequest } from '../../../types';
import { buildGETUrlWithParams } from '../../../api/misc/misc';

function* requestQuotaDesignFiltersFunc(action: Action<{[key: string]:string}>){
    try{
        const { data } = yield call(() => get('/quota_design/filters/?') );
        yield put(requestQuotaDesignFiltersSuccess(data));
        // success('created successfully');
    }catch (err) {
        systemError(err);
        yield put(requestQuotaDesignFiltersFailed(err));
    }
}

function* saveQuotaDesignWeeksFunc(action: Action<TQuotaDesignRequest>){
    try{
        const { projectName , quotaDesign } = action.payload;
        const { data } = yield call(() => post(`/quota_design/weeks/?project_name=${projectName.replace('#', '%23')}`,quotaDesign));
        yield call(requestQuotaDesignFunc,requestQuotaDesign(projectName));
        yield put(saveQuotaDesignWeeksSuccess());
    }catch (err) {
        // systemError(err);
        yield put(saveQuotaDesignWeeksFailed(err));
    }
}

function* saveQuotaDesignPriorityFunc(action: Action<TQuotaDesignPriorityRequest>){

    try{
        const { projectName , quotaDesign , quotaBreakdowns } = action.payload;
        const newData = action.payload.quotaBreakdowns.map(week => {
            if(week.priority?.toString() === '0' || Number(week.priority) === 0){
                week.priority = null;
            }
            return week;
        });
        action.payload.quotaBreakdowns = newData;
        const { data } = yield call(() => post('/quota_design/priorities/',{projectName , quotaBreakdowns}));

        yield call(saveQuotaDesignWeeksFunc,saveQuotaDesignWeeks({projectName,quotaDesign}));
        yield put(saveQuotaDesignPrioritySuccess());
        success('Saved');
    }catch (err) {
        systemError(err);
        yield put(saveQuotaDesignPriorityFailed(err));
    }
}

function* requestQuotaDesignFunc(action: Action<string>){
    try{
        const { data } = yield call(() => get(`quota_design/project_list_full/?project_name=${action.payload.replace('#', '%23')}`));
        yield put(requestQuotaDesignSuccess(data));
    }catch (err) {
        systemError(err);
        yield put(requestQuotaDesignFailed(err));
    }
}

function* requestQuotaDesignWeeksCompoundedFunc(action: Action<TQuotaDesignCompondedRequest>){
    try{
        const { projectName , week } = action.payload;
        const { data } = yield call(() => get(`/quota_design/weeks/compounded/?projectName=${projectName.replace('#', '%23')}&week=${week}`));
        yield put(requestQuotaDesignWeeksCompoundedSuccess(data));
    }catch (err) {
        systemError(err);
        yield put(requestQuotaDesignWeeksCompoundedFailed(err));
    }
}

function* exportQuotaDesignWeeksCompoundedFunc(action: Action<TQuotaDesignCompondedRequest>){
    try{
        const { projectName , week } = action.payload;
        const { data } = yield call(() => downloadFileGet(`/quota_design/weeks/export_excel/?project_name=${projectName.replace('#', '%23')}&week=${week}`,`${projectName}_${week}_quota_design_week.xlsx`));
        yield put(exportQuotaDesignWeeksCompoundedSuccess());
        success('download completed');
    }catch (err) {
        systemError(err);
        yield put(exportQuotaDesignWeeksCompoundedFailed(err));
    }
}

export function* quotaDesignWatchSaga(){
    yield takeLatest(requestQuotaDesign,requestQuotaDesignFunc);
    yield takeLatest(requestQuotaDesignFilters,requestQuotaDesignFiltersFunc);
    yield takeLatest(saveQuotaDesignWeeks,saveQuotaDesignWeeksFunc);
    yield takeLatest(saveQuotaDesignPriority,saveQuotaDesignPriorityFunc);
    yield takeLatest(requestQuotaDesignWeeksCompounded,requestQuotaDesignWeeksCompoundedFunc);
    yield takeLatest(exportQuotaDesignWeeksCompounded,exportQuotaDesignWeeksCompoundedFunc);
}
