
import { call, put, takeLatest } from 'redux-saga/effects';
import { requestOutcome, requestOutcomeFailed, requestOutcomeFilters, requestOutcomeFiltersFailed, requestOutcomeFiltersSuccess, requestOutcomeSuccess, requestOutcomeTopInstallCall, requestOutcomeTopInstallCallFailed, requestOutcomeTopInstallCallSuccess, requestOutcomeTopResCall, requestOutcomeTopResCallFailed, requestOutcomeTopResCallSuccess } from './action';
import { OutcomeResponseView } from './reducer';
import { post } from '../../../api/api';
import { systemError } from '../../../utils/notifyToast';
import { Action } from 'redux-act';


function* outcomeFunc(action: Action<{[key: string]:string | number | boolean}>){
    
    try{
        const { data } = yield call(() => post('/troubleshooting_outcomes/calls/',action.payload));
        yield put(requestOutcomeSuccess(data as OutcomeResponseView));
        
    }catch (err) {
        systemError();
        yield put(requestOutcomeFailed(err));
    }
}

function* outcomeFilterFunc(action: Action<{[key: string]:string | number | boolean}>){
    try{
        const { data } = yield call(() => post('/troubleshooting_outcomes/filters/',action.payload));
        yield put(requestOutcomeFiltersSuccess(data));
        
    }catch (err) {
        systemError();
        yield put(requestOutcomeFiltersFailed(err));
    }
}

function* outcomeResFunc(action: Action<{[key: string]:string | number | boolean}>){
    
    try{
        const { data } = yield call(() => post('/troubleshooting_outcomes/weekly_progress_calls/',action.payload));
        yield put(requestOutcomeTopResCallSuccess(data));
        
    }catch (err) {
        systemError();
        yield put(requestOutcomeTopResCallFailed(err));
    }
}

function* outcomeInstallFunc(action: Action<{[key: string]:string | number | boolean}>){
    
    try{
        const { data } = yield call(() => post('/troubleshooting_outcomes/installer_visits/',action.payload));
        yield put(requestOutcomeTopInstallCallSuccess(data));
        
    }catch (err) {
        systemError();
        yield put(requestOutcomeTopInstallCallFailed(err));
    }
}

export function* outcomeWatchSaga(){
    yield takeLatest(requestOutcome,outcomeFunc);
    yield takeLatest(requestOutcomeFilters,outcomeFilterFunc);
    yield takeLatest(requestOutcomeTopResCall,outcomeResFunc);
    yield takeLatest(requestOutcomeTopInstallCall,outcomeInstallFunc);
}
