import { TBoilerPlateState } from '../boilerPlate/reducer';
import { CHANGE_PASSWORD, CONFIRM_RESET_PASSWORD, RESET_PASSWORD } from './actions';

const defaultState: TBoilerPlateState = {
    isLoading: false,
    error: '',
    data: []
};


export const resetPasswordReducer = (state = defaultState, action: any) => {
    const { type, payload } = action;
    switch (type) {
    case RESET_PASSWORD.REQUEST:
        return { ...state, isLoading: true };
    case RESET_PASSWORD.SUCCESS:
        return { ...state, data: payload, isLoading: false };
    case RESET_PASSWORD.ERROR:
        return { ...state, error: payload, isLoading: false };
    default:
        return state;
    }
};

export const changePasswordReducer = (state = defaultState, action: any) => {
    const { type, payload } = action;
    switch (type) {
    case CHANGE_PASSWORD.REQUEST:
        return { ...state, isLoading: true };
    case CHANGE_PASSWORD.SUCCESS:
        return { ...state, data: payload, isLoading: false };
    case CHANGE_PASSWORD.ERROR:
        return { ...state, error: payload, isLoading: false };
    default:
        return state;
    }
};

export const confirmResetPasswordReducer = (state = defaultState, action: any) => {
    const { type, payload } = action;
    switch (type) {
    case CONFIRM_RESET_PASSWORD.REQUEST:
        return { ...state, isLoading: true };
    case CONFIRM_RESET_PASSWORD.SUCCESS:
        return { ...state, data: payload, isLoading: false };
    case CONFIRM_RESET_PASSWORD.ERROR:
        return { ...state, error: payload, isLoading: false };
    default:
        return state;
    }
};