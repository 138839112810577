/*
Collection of common utility functions
*/

// _isPayloadTruthy was designed to inspect objects and arrays for integer values, where payloads contain only 0's the return value is then set to false
// This func is useful for graphs and charts that need > 0 values to generate ticks
export const _isPayloadTruthy = (data: any) => {
    let bool = true;

    if(data){
        if (typeof data === 'object') {
            if (Array.isArray(data)) {
                data.length < 1 || Math.max(...data) < 1 ? bool = false : null;
            } else {
                const values = Object.values(data);
                values.length < 1 || Math.max(...values as number[]) < 1 ? bool = false : null;
            }
        }
    }
    else {
        return false;
    }


    return bool;
};
