import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';

// Cateogry Filters
export const updateCategoryFilters = createAction<{[key: string]: boolean}>('TECHICIAN_TROUBLESHOOTING_UPDATE_FILTERS');

// RequestData
export const requestTechnicianTroubleshootingData = createAction<{[key: string]: string | number}>('REQUEST_TECHICIAN_TROUBLESHOOTING_DATA');
export const requestTechnicianTroubleshootingDataSuccess = createAction<{ [key: string]: any }[]>('REQUEST_TECHICIAN_TROUBLESHOOTING_DATA_SUCCESS');
export const requestTechnicianTroubleshootingDataFail = createAction<any>('REQUEST_TECHICIAN_TROUBLESHOOTING_DATA_FAIL');

export const requestTechnicianTroubleshootingFilters = createAction<{[key: string]: string | number}>('REQUEST_TECHICIAN_TROUBLESHOOTING_FILTER');
export const requestTechnicianTroubleshootingFiltersSuccess = createAction<{ [key: string]: any }>('REQUEST_TECHICIAN_TROUBLESHOOTING_FILTER_SUCCESS');
export const requestTechnicianTroubleshootingFiltersFail = createAction<any>('REQUEST_TECHICIAN_TROUBLESHOOTING_FILTER_FAIL');

// Post Data
export const postTechnicianTroubleshootingFormData = createAction<{[key: string]: any}>('POST_TECHICIAN_TROUBLESHOOTING_FORM_DATA');
export const postTechnicianTroubleshootingFormDataFail = createAction<any>('POST_TECHICIAN_TROUBLESHOOTING_FORM_DATA_FAIL');

// Query Paremeter Filters
export const updateQueryParameters = createAction<TFilters>('TECHICIAN_TROUBLESHOOTING_UPDATE_QUERY_PARAMETERS');
export const requestFilteredTechnicianTroubleshootingData = createAction<{[key: string]: boolean}>('REQUEST_FILTERED_TECHICIAN_TROUBLESHOOTING_DATA');

// Clear Filters
export const clearTechnicianTroubleshootingFilters = createAction<{[key: string]: string | number}>('CLEAR_TECHICIAN_TROUBLESHOOTING_FILTERS');

// Export Data
export const requestExportTechnicianTroubleshootingTableData = createAction<{[key: string]:string | number | {[key: string] : string}}>('REQUEST_EXPORT_TECHICIAN_TROUBLESHOOTING_TABLE_DATA');
export const requestExportTechnicianTroubleshootingTableDataSuccess = createAction('REQUEST_EXPORT_TECHICIAN_TROUBLESHOOTING_TABLE_DATA_SUCCESS');
export const requestExportTechnicianTroubleshootingTableDataFail = createAction<any>('REQUEST_EXPORT_TECHICIAN_TROUBLESHOOTING_TABLE_DATA_FAIL');

// Filters
export const setFilters = createAction<{[key: string]: string[]}>('SET_TECHICIAN_TROUBLESHOOTING_FILTERS');
export const updateQueryParametersSuccess = createAction<{ [key: string]: any }[]>('UPDATE_TECHICIAN_TROUBLESHOOTING_FILTER_SUCCESS');
