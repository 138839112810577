
export const buildGETUrlWithParams = (params: any, url: string) => {
    let _url = url;
    if(params){
        const objectKeys = Object.keys(params).length;

        if(objectKeys > 0){
            for(const[key,value] of Object.entries(params)){
                _url +=`${key}=${value}&`;
            }
        }
    }
    return _url;
};
