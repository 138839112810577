export const genDemographicsFilters = (data: any) => {
    console.log('Demographics Data', data);

    const obj: {[key : string]: boolean} = {};
    Object.keys(data[0]).forEach(key => {
        obj[key] = true;
    });

    return obj;
};
