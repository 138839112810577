import {toast} from 'react-toastify';
  
toast.configure();

export const success = (msg?:string) =>
    toast.success(msg || 'successful');

export const systemError = (err?: any | string) =>
    toast.error(`${err ? err : 'System error!'}`);

export const infoError = (err: string) =>
    toast.info(`${err}`);

