import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';

export const updateInstallationProgressPerWeekMondayFilters =
    createAction<TFilters>('INSTALLATION_PROGRESS_PER_WEEK_MONDAY_FILTER_UPDATE');

export const requestInstallationProgressPerWeekMondayData =
    createAction<TFilters>('INSTALLATION_PROGRESS_PER_WEEK_MONDAY_DATA');
export const requestInstallationProgressPerWeekMondayDataSuccess =
    createAction<any>('INSTALLATION_PROGRESS_PER_WEEK_MONDAY_DATA_SUCCESS');
export const requestInstallationProgressPerWeekMondayDataFail =
    createAction<any>('INSTALLATION_PROGRESS_PER_WEEK_MONDAY_DATA_FAIL');

export const requestInstallationProgressProgressPerWeekMondayFilters =
    createAction('INSTALLATION_PROGRESS_PER_WEEK_MONDAY_FILTERS');
export const requestInstallationProgressProgressPerWeekMondayFiltersSuccess =
    createAction<any>(
        'GET_INSTALLATION_PROGRESS_PER_WEEK_MONDAY_FILTERS_SUCCESS'
    );
export const requestInstallationProgressProgressPerWeekMondayFiltersFailed =
    createAction<any>('GET_INSTALLATION_PROGRESS_PER_WEEK_MONDAY_FILTERS_FAILED');
