import { createAction } from 'redux-act';
import { 
    ProgressTrackerRequestView, 
    ProgressTrackerResponseView,
    ProgressTrackerFiltersRespose 
} from './reducer';

export const requestProgressTracker = createAction<ProgressTrackerRequestView>('GET_PROGRESS_TRACKER_REQUEST');
export const requestProgressTrackerSuccess = createAction<ProgressTrackerResponseView>('GET_PROGRESS_TRACKER_SUCCESS_REQUEST');
export const requestProgressTrackerFailed = createAction<any>('GET_PROGRESS_TRACKER_FAILED_REQUEST');

export const requestMoreProgressTracker = createAction<ProgressTrackerRequestView>('GET_MORE_PROGRESS_TRACKER_REQUEST');

export const requestProgressTrackerFilters = createAction<{[key: string]:string | number}>('GET_PROGRESS_TRACKER_FILTGET_PROGRESS_TRACKER_GET_PROGRESS_TRACKER_FILTER_REQUEST');
export const requestProgressTrackerFiltersSuccess = createAction<ProgressTrackerFiltersRespose>('GET_PROGRESS_TRACKER_FILTER_SUCCESS_REQUEST');
export const requestProgressTrackerFiltersFailed = createAction<any>('GET_PROGRESS_TRACKER_FAILED_FILTER_REQUEST');

export const requestProgressTrackerDownload = createAction<{[key: string]:string | number}>('GET_PROGRESS_TRACKER_DOWNLOAD_REQUEST');
export const requestProgressTrackerDownloadSuccess = createAction('GET_PROGRESS_TRACKER_SUCCESS_DOWNLOAD_REQUEST');
export const requestProgressTrackerDownloadFailed = createAction<any>('GET_PROGRESS_TRACKER_FAILED_DOWNLOAD_REQUEST');
