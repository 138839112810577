import { createAction } from 'redux-act';
import { TFilters, TFormBody } from '../../../types/commonTypes';
import { TInstallationProgressSnapshotSummaryData } from './types/types';

export const requestInstallationProgressSnapshotSummaryData = createAction('INSTALLATIONPROGRESS_SUMMARY_DATA');
export const requestInstallationProgressSnapshotSummaryDataSuccess = createAction<TInstallationProgressSnapshotSummaryData>('INSTALLATIONPROGRESS_SUMMARY_DATA_SUCCESS');
export const requestInstallationProgressSnapshotSummaryDataFail = createAction<any>('INSTALLATIONPROGRESS_SUMMARY_DATA_FAIL');

export const requestInstallationProgressSnapshotSummaryProvinceData = createAction<TFormBody>('INSTALLATIONPROGRESS_SUMMARY_PROVINCE_DATA');
export const requestInstallationProgressSnapshotSummaryProvinceDataSuccess = createAction<any>('INSTALLATIONPROGRESS_SUMMARY_DATA_PROVINCE_SUCCESS');
export const requestInstallationProgressSnapshotSummaryProvinceDataFail = createAction<any>('INSTALLATIONPROGRESS_SUMMARY_DATA_PROVINCE_FAIL');

export const requestInstallationProgressSnapshotSummaryFilters = createAction('INSTALLATION_PROGRESS_FILTERS');
export const requestInstallationProgressSnapshotSummaryFiltersSuccess = createAction<any>('GET_INSTALLATION_PROGRESS_FILTERS_SUCCESS');
export const requestInstallationProgressSnapshotSummaryFiltersFailed = createAction<any>('GET_INSTALLATION_PROGRESS_FILTERS_FAILED');

export const updateFiltersSelected = createAction<TFilters>('INSTALLATIONPROGRESS_SUMMARY_UPDATE_FILTERS');
export const clearFiltersSelected = createAction('INSTALLATIONPROGRESS_SUMMARY_CLEAR_FILTERS');
