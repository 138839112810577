import { optionsWithLegends, percentageOption } from '../../../components/common/graphs/templateData/templateData';
import { transCamelLabels } from '../../labels/transLabels';
import { basicColorMap } from '../colorMap';
type TCustomLabelsMap = { [key: string | number]: string };

// Handle Color Assign
const handleColorAssign = (color: string, percentage: any) => {
    return percentage ? `${color.substring(0, color.length - 1)}, 0.7)` : color;
};

// Generate Labels
const generateLabels = (data: any, customLabelMap?: { [key: string]: string }) => {
    const labels = Object.keys(data).map(str => str);
    return labels;
};

// Generate Legends
const generateLegends = (data: any) => {
    const legends = Object.keys(data);
    return Object.keys(data[legends[0]]);
};

// Generate Data Mapping Object
const generateDataMappingObjects = (data: any, percentage?: boolean | null) => {
    const _data: any = {};
    const percentageMax: number[] = [];

    Object.keys(data).forEach(key => {
        Object.keys(data[key]).forEach(innerKey => {
            percentageMax.push(data[key][innerKey]);
            if (innerKey in _data) {
                _data[innerKey].push(data[key][innerKey]);
            }
            else {
                _data[innerKey] = [data[key][innerKey]];
            }
        });
    });

    if (percentage) {
        const max = Math.max(...percentageMax);
        Object.keys(_data).forEach(key => {
            _data[key] = _data[key].map((v: number) => {
                return v / max * 100;
            });
        });
    }
    return _data;
};

// Generate Final Product for Graph Shipping
export const multiBarTransform = (
    data: any,
    colorMap?: { [key: number]: string } | null,
    percentage?: boolean | null,
    customLabelMap?: TCustomLabelsMap
) => {
    const _colorMap = colorMap ? colorMap : basicColorMap;

    // Options
    const _optionsWithLegends = optionsWithLegends;
    const _percentageOption = percentageOption;

    // Generate pieces of return data
    const legends = generateLegends(data);
    const labels = generateLabels(data, customLabelMap);
    const _data: any = generateDataMappingObjects(data, percentage);

    if (customLabelMap) {
        customLabelMap['pollingNotOnPmr'] = "Recruited On SCR";
    }
    const _dataSets = Object.keys(_data).map((key, index) => {
        return {
            label: customLabelMap && customLabelMap[legends[index]] ? customLabelMap[legends[index]] : transCamelLabels(legends[index]),
            backgroundColor: handleColorAssign(_colorMap[index] as string, percentage),
            borderColor: handleColorAssign(_colorMap[index] as string, percentage),
            borderWidth: 1,
            data: _data[key],
        };
    });

    const returnObj = [
        {
            labels: labels,
            datasets: [..._dataSets],
        },
        percentage ? _percentageOption : _optionsWithLegends,
    ];

    return returnObj;
};

//remove items with  null value
export const removeZeroValuedData = (data: any) => {
    const newData: any = {};
    for (const item in data) {
        if (data[item] !== 0) {
            newData[item] = data[item];
        }
    }
    return newData;
};
