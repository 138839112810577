import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';

export const requestSnapshotSummaryData = createAction<TFilters>('REQUEST_SNAPSHOT_SUMMARY_DATA');
export const requestSnapshotSummaryDataSuccess = createAction<any>('REQUEST_SNAPSHOT_SUMMARY_DATA_SUCCESS');
export const requestSnapshotSummaryDataFail = createAction<any>('REQUEST_SNAPSHOT_SUMMARY_DATA_FAIL');

export const requestSnapshotSummaryFilterUpdate = createAction<TFilters>('REQUEST_SNAPSHOT_SUMMARY_FILTER_UPDATE');

export const requestActualsConversion = createAction('SNAPSHOT_SUMMARY_REQUEST_ACTUALS_CONVERSION');
export const requestPercentageConversion = createAction('SNAPSHOT_SUMMARY_REQUEST_PERCENTAGE_CONVERSION');

export const requestSnapshotSummaryFilters = createAction('SNAPSHOT_SUMMARY_REQUEST_FILTERS');
export const requestSnapshotSummaryFiltersSuccess = createAction<any>('SNAPSHOT_SUMMARY_REQUEST_FILTERS_SUCCESS');
export const requestSnapshotSummaryFiltersFail = createAction<any>('SNAPSHOT_SUMMARY_REQUEST_FILTERS_FAIL');

export const updateSnapshotSummaryFilters = createAction('SNAPSHOT_SUMMARY_UPDATE_FILTERS');
