import axios from 'axios';
import { Action } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import axiosInstance, { get, post } from '../../api/api';
import { success } from '../../utils/notifyToast';
import { requestAddUser, requestAddUserFail, requestAddUserSuccess, requestAdminData, requestAdminDataFail, requestAdminDataSuccess, submitForm } from './actions';

function extractGroupNames(data: any[]) {
    const obj: any = {};
    data.forEach((item) => {
        obj[item.id] = item.name;
    });

    return obj;
}

function* requestAdminDataFunction() {
    let data: any = {};
    try {
        yield axios.all([
            get('/users/'),
            get('/user_access/groups/')
        ]).then((responses: any) => {
            data = {
                data: responses[0].data,
                groups: extractGroupNames(responses[1].data)
            };
        });

        yield put(requestAdminDataSuccess(data));
    } catch (error: any) {
        yield put(requestAdminDataFail(error));
    }
}

function* submitFormFunction(action: any) {
    const apiCallArray = action.payload.map((obj: any) => {
        return call(() => axiosInstance.patch(`/users/${obj.id}/`, obj));
    });

    try {
        yield all([
            ...apiCallArray
        ]);
        yield requestAdminDataFunction();
    } catch (error: any) {
        yield put(requestAdminDataFail(error));
    }
}

function* requestAddUserFunction(action: any) {
    try {
        const { status } = yield call(() => post('/users/', action.payload));
        const { data } = yield call(() => get('/users/'));
        if (status == 201) {
            yield put(requestAddUserSuccess(data));
            success();
        }
    } catch (error: any) {
        yield put(requestAddUserFail(error));
    }
}

export function* adminWatchSaga() {
    yield takeLatest(requestAdminData, requestAdminDataFunction);
    yield takeLatest(submitForm, submitFormFunction);
    yield takeLatest(requestAddUser, requestAddUserFunction);
}
