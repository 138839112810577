import { createReducer } from 'redux-act';

export type TBoilerPlateState = {
    isLoading: boolean,
    error: any,
    data: any
};

const defaultState: TBoilerPlateState = {
    isLoading: false,
    error: null,
    data: []
};


export const boilerPlateReducer = createReducer<TBoilerPlateState>({}, defaultState);
