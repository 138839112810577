

const genPages = (dataLength: number, pageSize: number) => {
    const list: any[] = [];
    const pagesToAdd = Math.ceil(dataLength / pageSize);

    for (let i = 0; i < pagesToAdd; i++) {
        list.push([]);
    }
    return list;
};

export const genPaginatedData = (pageSize: number, data: any) => { // 30, {length = 45}
    if (data.length > 0) {
        const paginatedData = genPages(data.length, pageSize); // [ [],  [], [] ]
        let counter = pageSize - 1; // 29
        let indexToAddTo = 0;

        data.forEach((row: { [key: string]: string | number | null }, index: number) => {
            paginatedData[indexToAddTo].push(row);
            if (index == counter) {
                indexToAddTo = indexToAddTo + 1;
                counter = counter + pageSize;
            }
        });

        return paginatedData;
    }
    else {
        return [];
    }
};

// Transform All Values to strings
export const transformValuesToString = (data: [{ [key: string | number]: any }][]) => {

    const _data: any[] = [];

    data.forEach((page, pageIndex) => {
        _data.push([]);
        page.forEach((item, itemIndex) => {
            _data[pageIndex].push({});
            Object.keys(item).forEach(key => {
                const value = data[pageIndex][itemIndex][key] != null && data[pageIndex][itemIndex][key] != undefined  ? data[pageIndex][itemIndex][key].toString() : null;
                _data[pageIndex][itemIndex][key] = value;
            });
        });
    });

    return _data;
};
