import { call, put, takeLatest } from 'redux-saga/effects';
import { requestExceptionList, requestExceptionListDownload, requestExceptionListDownloadFailed, requestExceptionListDownloadSuccess, requestExceptionListFailed, requestExceptionListFilters, requestExceptionListFiltersFailed, requestExceptionListFiltersSuccess, requestExceptionListRemovalReasons, requestExceptionListRemovalReasonsFailed, requestExceptionListRemovalReasonsSuccess, requestExceptionListSuccess, updateExceptionList, updateExceptionListFailed, updateExceptionListSuccess } from './action';
import { ExceptionListFiltersRespose, ExceptionListResponseView } from './reducer';
import { downloadFileGet, get, update } from '../../../api/api';
import { success, systemError } from '../../../utils/notifyToast';
import { Action } from 'redux-act';
import { buildGETUrlWithParams } from '../../../api/misc/misc';


function* exceptionListFunc(action: Action<{ [key: string]: string | number }>) {
    try {
        const requestUrl = buildGETUrlWithParams(action.payload, '/exception_list/?');

        const { data } = yield call(() => get(requestUrl));
        yield put(requestExceptionListSuccess(data as ExceptionListResponseView[]));

    } catch (err) {
        systemError();
        yield put(requestExceptionListFailed(err));
    }
}

function* exceptionListRemovalReasonsFunc(action: Action<{ [key: string]: string | number }>) {
    try {
        const requestUrl = buildGETUrlWithParams(action.payload, '/exception_list/removal_reasons/');

        const { data } = yield call(() => get(requestUrl));
        yield put(requestExceptionListRemovalReasonsSuccess(data));

    } catch (err) {
        systemError();
        yield put(requestExceptionListRemovalReasonsFailed(err));
    }
}

function* exceptionListFilterFunc(action: Action<{ [key: string]: string | number }>) {
    try {
        const { data } = yield call(() => get(buildGETUrlWithParams(action.payload, '/exception_list/filters/?')));
        yield put(requestExceptionListFiltersSuccess(data as ExceptionListFiltersRespose));

    } catch (err) {
        systemError();
        yield put(requestExceptionListFiltersFailed(err));
    }
}

function* updateExceptionListFunc(action: Action<ExceptionListResponseView[]>) {
    try {
        const { data } = yield call(() => update('/exception_list/', action.payload));
        success();
        yield put(updateExceptionListSuccess());

    } catch (err) {
        systemError();
        yield put(updateExceptionListFailed(err));
    }
}

function* exceptionListFileExportFunc(action: Action<{ [key: string]: string | number }>) {
    try {
        let requestUrl = '/exception_list/export_excel/';
        const objectKeys = Object.keys(action.payload).length;
        if (objectKeys > 0) {
            requestUrl = '/exception_list/export_excel/?';
            for (const [key, value] of Object.entries(action.payload)) {
                requestUrl += `${key}=${value}&`;
            }
        }
        const { data } = yield call(() => downloadFileGet(requestUrl, 'export_excel.xlsx'));
        success('File downloaded successfully');
        yield put(requestExceptionListDownloadSuccess());
    } catch (err) {
        systemError();
        yield put(requestExceptionListDownloadFailed(err));
    }
}

export function* exceptionListWatchSaga() {
    yield takeLatest(requestExceptionList, exceptionListFunc);
    yield takeLatest(requestExceptionListFilters, exceptionListFilterFunc);
    yield takeLatest(updateExceptionList, updateExceptionListFunc);
    yield takeLatest(requestExceptionListDownload, exceptionListFileExportFunc);
    yield takeLatest(requestExceptionListRemovalReasons, exceptionListRemovalReasonsFunc);
}
