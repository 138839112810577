import { createReducer } from 'redux-act';
import { pastelThemeColors, themeColors } from '../../../scss/themeColors';
import { TFilters } from '../../../types/commonTypes';
import { transLineData, transSpecialLineData } from '../../../utils/graphs&charts/transLineData/trans';
import { transPieData } from '../../../utils/graphs&charts/transPieData/trans';
import {
    requestTroubleShootingData,
    requestTroubleShootingDataFailed,
    requestTroubleShootingDataSuccess,
    setActiveGraphWeeklyProgressProvince,
    setSelectedFilters,
    clearFilters,
    requestTroubleShootingFiltersSuccess
} from './actions';

export type TroubleShootingActivitiesSate = {
    selectedFilters: TFilters | null,
    isLoading: boolean,
    error: any,
    graphTroubleShootingActivities: any,
    graphTSInstallerVisits: any,
    graphWeeklyProgressProvince: any,
    activeGraphWeeklyProgressProvince: any,
    pieTotal: number,
    graphWeeklyProgressProvinceProvinces: string[],
    filters: any
}



const defaultState: TroubleShootingActivitiesSate = {
    selectedFilters: null,
    isLoading: true,
    error: null,
    graphTroubleShootingActivities: [],
    graphTSInstallerVisits: [],
    graphWeeklyProgressProvince: [],
    activeGraphWeeklyProgressProvince: [],
    pieTotal: 0,
    graphWeeklyProgressProvinceProvinces: [],
    filters: {}
};

export const troubleShootingActivitiesReducer = createReducer<TroubleShootingActivitiesSate>({}, defaultState);

troubleShootingActivitiesReducer.on(requestTroubleShootingData, state => ({
    ...state,
    isLoading: true,
}))
    .on(requestTroubleShootingDataSuccess, (state, payload) => ({
        ...state,
        isLoading: false,
        graphTroubleShootingActivities: transPieData(
            payload.troubleshootingActivities, // data
            ['nonPolling'], // labels to ignore
            payload.troubleshootingActivities.nonPolling, // maximum value for % calculations
            [themeColors['darkPurple'], themeColors['red'], themeColors['yellow'], themeColors['blueStates'], themeColors['green']], // custom colors
            [pastelThemeColors['darkPurple'], pastelThemeColors['red'], pastelThemeColors['yellow'], pastelThemeColors['darkPurpleStates'], pastelThemeColors['blueStates'], pastelThemeColors['green']],  // custom colors
            {
                'noFeedback': 'No feedback from FS (30+ days)'
            }
        ),
        graphTSInstallerVisits: transLineData(payload.tsInstallerVisits, 'sortByYearAndWeeks'),
        graphWeeklyProgressProvince: transSpecialLineData(payload.weeklyProgressProvince, { legendKey: 'province', labelKey: 'week', dataKey: ['inProgress', 'concluded'] }),
        activeGraphWeeklyProgressProvince: transSpecialLineData(payload.weeklyProgressProvince, { legendKey: 'province', labelKey: 'week', dataKey: ['inProgress', 'concluded'] }),
        graphWeeklyProgressProvinceProvinces: Object.keys(payload.weeklyProgressProvince),
        pieTotal: payload.troubleshootingActivities.nonPolling
    }))
    .on(requestTroubleShootingFiltersSuccess, (state, payload) => ({
        ...state,
        filters: payload.filters
    }))
    .on(requestTroubleShootingDataFailed, (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,

    })).on(setSelectedFilters, (state, payload: TFilters) => ({
        ...state,
        selectedFilters: {
            ...state.selectedFilters,
            ...payload
        }
    })).on(setActiveGraphWeeklyProgressProvince, (state, payload) => ({
        ...state,
        activeGraphWeeklyProgressProvince: payload,

    })).on(clearFilters, state => ({
        ...state,
        selectedFilters: null
    }));
