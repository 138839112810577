

// Double Bar Graphs

export const defaultOptionsDoubleBar: any = {
    scales: {
        y: {
            max: 0,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
    },
};

export const totalPercentageOptionDoubleBar = {
    scales: {
        y: {
            max: 100,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Values as %',
            position: 'left',
        },
    },
};

// Bar Graphs

export const optionsActualBar = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true, // minimum value will be 0.
                },
            },
        ],
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },
    },
};

export const optionsTotalBar: any = {
    scales: {
        y: {
            max: 0,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },
    },
};

// Bar Graph Percentage

export const optionsActualBarPecentage = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true, // minimum value will be 0.
                },
            },
        ],
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Values as %',
            position: 'left',
        },
    },
};

export const optionsTotalsBarPecentage: any = {
    scales: {
        y: {
            max: 0,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Values as %',
            position: 'left',
        },
    },
};
