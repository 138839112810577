import { all, fork } from 'redux-saga/effects';
import { fileIngestionWatchSaga } from './troubleShooting/fileIngestion/sagas';
import { loginWatchSaga } from './login/saga';
import { progressTrackerWatchSaga } from './troubleShooting/progressTracker/saga';
import { totalPollingStatsDataWatchSaga } from './troubleShooting/totalPollingStats/sagas';
import { troubleShootWatchSaga } from './troubleShooting/troubleShootingActivities/sagas';
import { reportingTrackerWatchSaga } from './troubleShooting/reportingTracker/saga';
import { clearTechnicianTroubleshootingFiltersWatchSaga, exportTechnicianTroubleshootingTableDataWatchSaga, postTechnicianTroubleshootingFormDataWatchSaga, requestTechnicianTroubleshootingDataWatchSaga, updateTechnicianTroubleshootingQueryParametersWatchSaga } from './troubleShooting/technicianTroubleshooting/saga';
import { userAccessWatchSaga } from './userAccess/saga';
import { exceptionListWatchSaga } from './troubleShooting/exceptionList/saga';
import { batchNumberTrackerWatchSaga } from './troubleShooting/batchNumberTracker/saga';
import { requestSubmissionAndMonitoringDataWatchSaga } from './troubleShooting/submissionsMonitoringTracker/saga';
import { outcomeWatchSaga } from './troubleShooting/outcomes/saga';
import { interviewPlanWatchSaga } from './recruitment/projectPlanTimeline/saga';
import { adminWatchSaga } from './admin/sagas';
import { installationProgressSnapshotSummary } from './recruitment/installationProgressSnapshotSummary/sagas';
import { quotaDesignWatchSaga } from './recruitment/quotaDesign/saga';
import { passwordSaga } from './passwordReset/saga';
import { installationProgressPerWeekWatchSaga } from './recruitment/installationProgressPerWeek/sagas';
import { installationProgressPerWeekMondayWatchSaga } from './recruitment/installationProgressPerWeekMonday/sagas';
import { recruitmentProgressPerWeekWatchSaga } from './recruitment/recruitmentProgressPerWeek/sagas';
import { snapshotSummaryWatchSaga } from './recruitment/snapshotSummary/sagas';

export default function* rootSaga() {
    yield all([
        // Login
        fork(loginWatchSaga),

        // Admin
        fork(adminWatchSaga),

        //password
        fork(passwordSaga),

        // TROUBLESHOOTING SAGAS
        fork(progressTrackerWatchSaga),
        fork(totalPollingStatsDataWatchSaga),
        fork(requestTechnicianTroubleshootingDataWatchSaga),
        fork(postTechnicianTroubleshootingFormDataWatchSaga),
        fork(updateTechnicianTroubleshootingQueryParametersWatchSaga),
        fork(clearTechnicianTroubleshootingFiltersWatchSaga),
        fork(exportTechnicianTroubleshootingTableDataWatchSaga),
        fork(troubleShootWatchSaga),
        fork(fileIngestionWatchSaga),
        fork(userAccessWatchSaga),
        fork(exceptionListWatchSaga),
        fork(batchNumberTrackerWatchSaga),
        fork(requestSubmissionAndMonitoringDataWatchSaga),
        fork(outcomeWatchSaga),
        fork(reportingTrackerWatchSaga),

        // RECRUITMENT SAGAS
        fork(interviewPlanWatchSaga),
        fork(installationProgressSnapshotSummary),
        fork(quotaDesignWatchSaga),
        fork(installationProgressPerWeekWatchSaga),
        fork(installationProgressPerWeekMondayWatchSaga),
        fork(recruitmentProgressPerWeekWatchSaga),
        fork(snapshotSummaryWatchSaga),

    ]);
}
