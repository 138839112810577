import { createReducer } from 'redux-act';
import { TFilterList, TFilters } from '../../../types/commonTypes';
import {
    requestInstallationProgressPerWeekData,
    requestInstallationProgressPerWeekDataFail,
    requestInstallationProgressPerWeekDataSuccess,
    requestInstallationProgressProgressPerWeekFiltersSuccess,
    updateInstallationProgressPerWeekFilters,
} from './actions';

export type TInstallationProgressPerWeekState = {
    isLoading: boolean;
    error: any;
    filters: TFilterList;
    installationProgressWeeksActualInstalls: any;
    installationProgressWeeksCumulative: any;
    installationProgressInstallationRate: any;
    installationProgressInstallationStatistics: any;
    selectedFilters: TFilters;
};

const defaultState: TInstallationProgressPerWeekState = {
    isLoading: true,
    error: null,
    filters: null,
    installationProgressWeeksActualInstalls: null,
    installationProgressWeeksCumulative: null,
    installationProgressInstallationRate: null,
    installationProgressInstallationStatistics: null,
    selectedFilters: {},
};

export const installationProgressPerWeekReducer =
    createReducer<TInstallationProgressPerWeekState>({}, defaultState);

installationProgressPerWeekReducer
    .on(requestInstallationProgressPerWeekData, (state) => ({
        ...state,
        isLoading: true,
    }))
    .on(requestInstallationProgressPerWeekDataSuccess, (state, payload) => {

        return {
            ...state,
            isLoading: false,
            installationProgressWeeksActualInstalls:
                payload.installationProgressWeeksActualInstalls,
            installationProgressWeeksCumulative:
                payload.installationProgressWeeksCumulative,
            installationProgressInstallationRate:
                payload.installationProgressInstallationRate,
            installationProgressInstallationStatistics:
                payload.installationProgressInstallationStatistics,
        };
    })
    .on(
        requestInstallationProgressProgressPerWeekFiltersSuccess,
        (state, filters: TFilters) => ({
            ...state,
            filters: filters,
        })
    )

    .on(requestInstallationProgressPerWeekDataFail, (state, error) => ({
        ...state,
        isLoading: false,
        error,
    }))
    .on(updateInstallationProgressPerWeekFilters, (state, filters) => {
        console.log(state, filters);

        return {
            ...state,
            selectedFilters: { ...filters },
        };
    });
