import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../../../api/api';
import { multiBarTransform } from '../../../utils/graphs&charts/transBarData/multiBarTransData';
import { _isPayloadTruthy } from '../../../utils/misc/misc';
import {
    requestInstallationProgressPerWeekMondayDataFail,
    requestInstallationProgressPerWeekMondayData,
    requestInstallationProgressPerWeekMondayDataSuccess,
    requestInstallationProgressProgressPerWeekMondayFilters,
    requestInstallationProgressProgressPerWeekMondayFiltersFailed,
    requestInstallationProgressProgressPerWeekMondayFiltersSuccess,
} from './actions';
import {
    recruitmentInstallationProgressPerWeekColorMapG1,
} from './meta/meta';
import { buildGETUrlWithParams } from '../../../api/misc/misc';
import { systemError } from '../../../utils/notifyToast';

function* mySagaFunction(action: any) {
    let data;

    try {
        yield axios
            .all([
                get(
                    buildGETUrlWithParams(
                        action.payload,
                        '/installation_progress_weeks_monday/actual_installs/?'
                    )
                ),
                get(
                    buildGETUrlWithParams(
                        action.payload,
                        '/installation_progress_weeks_monday/installation_rate/?'
                    )
                ),
            ])
            .then(
                axios.spread((...responses) => {
                    data = {
                        installationProgressWeeksActualInstalls: _isPayloadTruthy(
                            responses[0].data
                        )
                            ? multiBarTransform(
                                responses[0].data,
                                recruitmentInstallationProgressPerWeekColorMapG1
                            )
                            : {},
                        installationProgressInstallationRate: _isPayloadTruthy(responses[1].data)
                            ? responses[1].data
                            : {},
                    };
                })
            );
        yield put(requestInstallationProgressPerWeekMondayDataSuccess(data));
    } catch (err) {
        yield put(requestInstallationProgressPerWeekMondayDataFail(err));
    }
}

function* requestInstallationProgressProgressPerWeekFiltersSaga(action: any) {
    try {
        const { data } = yield call(() =>
            get(
                buildGETUrlWithParams(
                    action.payload,
                    '/installation_progress_weeks_monday/filters/?'
                )
            )
        );

        yield put(
            requestInstallationProgressProgressPerWeekMondayFiltersSuccess(data)
        );
    } catch (err) {
        systemError(err);
        yield put(
            requestInstallationProgressProgressPerWeekMondayFiltersFailed(err)
        );
    }
}

export function* installationProgressPerWeekMondayWatchSaga() {
    yield takeLatest(
        requestInstallationProgressPerWeekMondayData,
        mySagaFunction
    );
    yield takeLatest(
        requestInstallationProgressProgressPerWeekMondayFilters,
        requestInstallationProgressProgressPerWeekFiltersSaga
    );
}
