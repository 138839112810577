import { humanReadableLabels } from '../dataMapping/apiMappings';
import { transCamelLabels } from '../labels/transLabels';

export const processLabels = (data: any, match?: {[key: string]: string}, meta?: string) => {

    const array = Object.keys(data).map((item, i) => {
        if (match) {
            const label = match[item];
            return label ? label : transCamelLabels(item);
        } else {
            return transCamelLabels(item);
        }
    });
    
    return array;
};

export const roundMax = (max: number) => {
    let isWhole: any = (max / 2) % 2 === 0 ? '2' : '1'; 
    const numStr: any = max.toString();
  
    for(let step = 0; step < numStr.length - 1; step++) {
        isWhole = isWhole + '0' ;
    }
    
    const roundUpper = parseInt(isWhole); 
    // Rounding down the max param
    const roundedMax = Math.ceil(max / isWhole)*isWhole;

    if(numStr.length === 0){ 
        return roundedMax + roundUpper;
    }
    else {
        if(roundUpper > max){
            return roundedMax;
        }
        return roundedMax + roundUpper; 
    } 
};

