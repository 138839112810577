import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';

export const requestTotalPollingStatsData = createAction<{ [key: string]: number | string  }>('REQUEST_TOTAL_POLLING_STATS_DATA');
export const requestTotalPollingStatsSuccess = createAction<any>('REQUEST_TOTAL_POLLING_STATS_DATA_SUCCESS');
export const requestTotalPollingStatsFail = createAction<any>('REQUEST_TOTAL_POLLING_STATS_DATA_FAIL');
export const updateFiltersSelected = createAction<TFilters>('TOTAL_POLLING_STATS_UPDATE_FILTERS_SELECTED');
export const clearFiltersSelected = createAction('TOTAL_POLLING_STATS_CLEAR_FILTERS_SELECTED');
export const convertActuals = createAction('TOTAL_POLLING_STATS_CONVERT_ACTUALS');
export const convertPercentage = createAction('TOTAL_POLLING_STATS_CONVERT_PERCENTAGE');

export const requestTotalPollingStatsFilters = createAction<{ [key: string]: number | string  }>('REQUEST_TOTAL_POLLING_STATS_FILTERS');
export const requestTotalPollingStatsFiltersSuccess = createAction<any>('REQUEST_TOTAL_POLLING_STATS_FILTERS_SUCCESS');
export const requestTotalPollingStatsFiltersFail = createAction<any>('REQUEST_TOTAL_POLLING_STATS_FILTERS_FAIL');
