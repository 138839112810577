import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';

export const updateFiltersSelected = createAction<TFilters>('RECRUITMENT_PROGRESS_PER_WEEK_UPDATE_FILTERS');
export const clearFiltersSelected = createAction('RECRUITMENT_PROGRESS_PER_WEEK_CLEAR_FILTERS');

export const requestRecruitmentProgressPerWeekFilters = createAction('RECRUITMENT_PROGRESS_PER_WEEK_FILTERS');
export const requestRecruitmentProgressPerWeekFiltersSuccess = createAction<any>('GET_RECRUITMENT_PROGRESS_PER_WEEK_FILTERS_SUCCESS');
export const requestRecruitmentProgressPerWeekFiltersFailed = createAction<any>('GET_RECRUITMENT_PROGRESS_PER_WEEK_FILTERS_FAILED');

export const requestRecruitmentProgressPerWeekTotalvsTarget = createAction<TFilters>('RECRUITMENT_PROGRESS_PER_WEEK_TOTAL_VS_TARGET');
export const requestRecruitmentProgressPerWeekTotalvsTargetSuccess = createAction<any>('GET_RECRUITMENT_PROGRESS_PER_WEEK_TOTAL_VS_TARGET_SUCCESS');
export const requestRecruitmentProgressPerWeekTotalvsTargetFailed = createAction<any>('GET_RECRUITMENT_PROGRESS_PER_WEEK_TOTAL_VS_TARGET_FAILED');

export const requestRecruitmentProgressPerWeekTotalvsTargetQuota = createAction<TFilters>('RECRUITMENT_PROGRESS_PER_WEEK_TOTAL_VS_TARGET_QUOTA');
export const requestRecruitmentProgressPerWeekTotalvsTargetQuotaSuccess = createAction<any>('GET_RECRUITMENT_PROGRESS_PER_WEEK_TOTAL_VS_TARGET_QUOTA_SUCCESS');
export const requestRecruitmentProgressPerWeekTotalvsTargetQuotaFailed = createAction<any>('GET_RECRUITMENT_PROGRESS_PER_WEEK_TOTAL_VS_TARGET_QUOTA_FAILED');
