import { createReducer } from 'redux-act';
import { requestMoreProgressTracker, requestProgressTracker, requestProgressTrackerDownload, requestProgressTrackerDownloadFailed, requestProgressTrackerDownloadSuccess, requestProgressTrackerFailed, requestProgressTrackerFilters, requestProgressTrackerFiltersFailed, requestProgressTrackerFiltersSuccess, requestProgressTrackerSuccess } from './action';

export type ProgressTrackerRequestView = {
    page_y: number;
    filters: any;
    originalData: any[];
}


export type ProgressTrackerFiltersRespose = {
    country: string[];
    service: string[];
    hhStatus: string[];
    hhSubStatus: string[];
    hhUniquePollingStatus: string[];
    pollingBucket: string[];
    viewerExperience: string[];
    decoderType: string[];
    modem: string[];
    connectedServicesIndicator: string[];
    package: string[];
    network: string[];
    tenure: string[];
    province: string[];
    modemType: string[];
    modemSupplier: string[];
    recruitmentPhase: string[];
    tsBucketClassification: string[];
    connectedFlag: string[];
    equipmentSetUp: string[];
    noOfSmartcards: number[];
    daysSinceLastPoll: number[];
    submission1BatchNumber: number[];
    submission2BatchNumber: number[];
    scPlatform: string[];
    applicationFormatVersion: string[];
    noLogsSubmitted: string[]
}

export type ProgressTrackerResponseView = {
    progressTrackerData: any;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
}

export type TProgressTrackerState = {
    progressTrackerData: any;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
    isLoading: boolean;
    isLoadingMoreRows: boolean;
    error: any;
}


const defaultProgressTrackerState: TProgressTrackerState = {
    progressTrackerData: {},
    totalPages: 1,
    currentPage: 0,
    totalRecords: 0,
    isLoading: false,
    isLoadingMoreRows: false,
    error: ''
};

export const progressTrackerReducer = createReducer<TProgressTrackerState>({}, defaultProgressTrackerState);

progressTrackerReducer.on(requestProgressTracker, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestProgressTrackerSuccess, (state, payload) => ({
    ...state,
    isLoading: false,
    isLoadingMoreRows: false,
    progressTrackerData: payload,
    totalPages: payload.totalPages === 0 ? payload.progressTrackerData.originalData.length : payload.totalPages,
    currentPage: payload.currentPage,
    totalRecords: payload.totalRecords,
})).on(requestProgressTrackerFailed, (state, payload) => ({
    ...state,
    isLoading: false,
    isLoadingMoreRows: false,
    error: payload
})).on(requestMoreProgressTracker, state => ({
    ...state,
    isLoadingMoreRows: true
}));


export type TProgressTrackerFiltersState = {
    progressTrackerFilters: ProgressTrackerFiltersRespose;
    isLoading: boolean;
    error: any;
}


const defaultProgressTrackerFilterState: TProgressTrackerFiltersState = {
    progressTrackerFilters: {
        country: [],
        service: [],
        hhStatus: [],
        hhSubStatus: [],
        hhUniquePollingStatus: [],
        pollingBucket: [],
        viewerExperience: [],
        decoderType: [],
        modem: [],
        connectedServicesIndicator: [],
        package: [],
        network: [],
        tenure: [],
        province: [],
        modemType: [],
        modemSupplier: [],
        recruitmentPhase: [],
        tsBucketClassification: [],
        connectedFlag: [],
        equipmentSetUp: [],
        noOfSmartcards: [],
        daysSinceLastPoll: [],
        submission1BatchNumber: [],
        submission2BatchNumber: [],
        scPlatform: [],
        applicationFormatVersion: [],
        noLogsSubmitted: []
    },
    isLoading: false,
    error: ''
};

export const progressTrackerFilterReducer = createReducer<TProgressTrackerFiltersState>({}, defaultProgressTrackerFilterState);

progressTrackerFilterReducer.on(requestProgressTrackerFilters, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestProgressTrackerFiltersSuccess, (state, payload) => ({
    ...state,
    isLoading: false,
    progressTrackerFilters: payload
})).on(requestProgressTrackerFiltersFailed, (state, payload) => ({
    ...state,
    isLoading: false,
    error: payload
}));


export type TProgressTrackerFileExportState = {
    file: string;
    isLoading: boolean;
    error: any;
}

const defaultFileExportState: TProgressTrackerFileExportState = {
    file: '',
    isLoading: false,
    error: ''
};

export const progressTrackerFileExportReducer = createReducer<TProgressTrackerFileExportState>({}, defaultFileExportState);

progressTrackerFileExportReducer.on(requestProgressTrackerDownload, state => ({
    ...state,
    isLoading: true,
    error: ''
})).on(requestProgressTrackerDownloadSuccess, state => ({
    ...state,
    isLoading: false,
})).on(requestProgressTrackerDownloadFailed, (state, error) => ({
    ...state,
    isLoading: false,
    error
}));
