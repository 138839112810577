import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { ExceptionListFiltersRespose, ExceptionListResponseView } from './reducer';

export const requestExceptionList = createAction<TFilters>('GET_EXCEPTION_LIST_REQUEST');
export const requestExceptionListSuccess = createAction<ExceptionListResponseView[]>('GET_EXCEPTION_LIST_SUCCESS_REQUEST');
export const requestExceptionListFailed = createAction<any>('GET_EXCEPTION_LIST_FAILED_REQUEST');

export const requestExceptionListRemovalReasons = createAction<any>('GET_EXCEPTION_LIST_REQUEST_REMOVAL_REASONS');
export const requestExceptionListRemovalReasonsSuccess = createAction<any>('GET_EXCEPTION_LIST_REMOVAL_REASONS_SUCCESS_REQUEST');
export const requestExceptionListRemovalReasonsFailed = createAction<any>('GET_EXCEPTION_LIST_REMOVAL_REASONS_FAILED_REQUEST');

export const updateExceptionList = createAction<ExceptionListResponseView[]>('GET_EXCEPTION_LIST_UPDATE');
export const updateExceptionListSuccess = createAction('GET_EXCEPTION_LIST_SUCCESS_UPDATE');
export const updateExceptionListFailed = createAction<any>('GET_EXCEPTION_LIST_FAILED_UPDATE');

export const requestExceptionListFilters = createAction<{ [key: string]: string | number }>('GET_EXCEPTION_LIST_FILTER_REQUEST');
export const requestExceptionListFiltersSuccess = createAction<ExceptionListFiltersRespose>('GET_EXCEPTION_LIST_FILTER_SUCCESS_REQUEST');
export const requestExceptionListFiltersFailed = createAction<any>('GET_EXCEPTION_LIST_FAILED_FILTER_REQUEST');

export const requestExceptionListDownload = createAction<TFilters>('GET_EXCEPTION_LIST_DOWNLOAD_REQUEST');
export const requestExceptionListDownloadSuccess = createAction('GET_EXCEPTION_LIST_SUCCESS_DOWNLOAD_REQUEST');
export const requestExceptionListDownloadFailed = createAction<any>('GET_EXCEPTION_LIST_FAILED_DOWNLOAD_REQUEST');
