import { createReducer } from 'redux-act';
import { themeColors } from '../../../scss/themeColors';
import { TFilterList, TFilters } from '../../../types/commonTypes';
import { humanReadableLabels } from '../../../utils/dataMapping/apiMappings';
import { transDoubleBarData, transDoubleBarDataPercentage } from '../../../utils/graphs&charts/transBarData/doubleBarGraphTransData';
import { transChartData, transChartDataPercentage } from '../../../utils/graphs&charts/transBarData/transformData';
import { transPieData } from '../../../utils/graphs&charts/transPieData/trans';
import {
    requestTotalPollingStatsData,
    clearFiltersSelected,
    requestTotalPollingStatsFail,
    requestTotalPollingStatsSuccess,
    requestTotalPollingStatsFiltersSuccess,
    updateFiltersSelected,
    convertActuals,
    convertPercentage
} from './actions';



export type TTotalPollingStatsState = {
    filters: TFilterList | null,
    pieChartPollingCountry: any,
    pieChartPolling: any,
    pollingBreakDown: any,
    pollingBreakDownPercentage: any,
    activePollingBreakDown: any,
    totalViewers: any,
    totalViewersPercentage: any,
    activeTotalViewers: any,
    totalContactViolations: any,
    totalContactViolationsPercentage: any,
    activeTotalContactViolations: any,
    isLoading: boolean,
    error: any,
    filtersSelected: TFilters | null,
    pieChartPollingCountryTotals: number,
    pieChartPollingTotals: number,
}

const defaultState: TTotalPollingStatsState = {
    filters: null,
    pieChartPollingCountry: [],
    pieChartPolling: [],
    pollingBreakDown: [],
    pollingBreakDownPercentage: [],
    activePollingBreakDown: [],
    totalViewers: [],
    totalViewersPercentage: [],
    activeTotalViewers: [],
    totalContactViolations: [],
    totalContactViolationsPercentage: [],
    activeTotalContactViolations: [],
    isLoading: true,
    error: null,
    filtersSelected: null,
    pieChartPollingCountryTotals: 0,
    pieChartPollingTotals: 0,
};

export const totalPollingStatsReducer = createReducer<TTotalPollingStatsState>({}, defaultState);

totalPollingStatsReducer.on(requestTotalPollingStatsData, state => ({
    ...state,
    isLoading: true,
}))
    .on(requestTotalPollingStatsSuccess, (state, payload) => ({
        ...state,
        isLoading: false,
        pieChartPollingCountry: transPieData(
            payload.chartPollingCountry.data,
            null,
            null,
            [themeColors['green'], themeColors['red']],
            [themeColors['green'], themeColors['red']]
        ),
        pieChartPolling: transPieData(
            payload.chartPolling.data,
            null,
            null,
            [themeColors['green'], themeColors['red']],
            [themeColors['green'], themeColors['red']]
        ),

        pollingBreakDown: transChartData(
            payload.pollingBreakDown.data,
            humanReadableLabels,
            'max'
        ),
        pollingBreakDownPercentage: transChartDataPercentage(payload.pollingBreakDown.data,  humanReadableLabels, 'bar', 'max'),
        activePollingBreakDown: transChartData(
            payload.pollingBreakDown.data,
            humanReadableLabels,
            'max'
        ),

        totalViewers: transChartData(
            payload.totalViewers.data,
            humanReadableLabels,
        ),
        totalViewersPercentage: transChartDataPercentage(payload.totalViewers.data,  humanReadableLabels, 'bar'),
        activeTotalViewers: transChartData(
            payload.totalViewers.data,
            humanReadableLabels,

        ),

        totalContactViolations: transDoubleBarData(
            payload.totalContactViolations.data, 'max', ['Total', '2+ Violations']
        ),
        totalContactViolationsPercentage: transDoubleBarDataPercentage(payload.totalContactViolations.data, 'max', ['Total', '2+ Violations']),
        activeTotalContactViolations: transDoubleBarData(
            payload.totalContactViolations.data, 'max', ['Total', '2+ Violations']
        ),

        pieChartPollingCountryTotals: payload.chartPollingCountry.data.polling + payload.chartPollingCountry.data.nonPolling,
        pieChartPollingTotals: payload.chartPolling.data.polling + payload.chartPollingCountry.data.nonPolling,

    }))
    .on(requestTotalPollingStatsFiltersSuccess, (state, payload) => ({
        ...state,
        filters: payload.filters.data
    }))
    .on(requestTotalPollingStatsFail, (state, payload) => ({
        ...state,
        isLoading: false
    })).on(updateFiltersSelected, (state, payload) => ({
        ...state,
        filtersSelected: payload
    })).on(clearFiltersSelected, state => ({
        ...state,
        filtersSelected: {}
    })).on(convertActuals, state => ({
        ...state,
        activePollingBreakDown: state.pollingBreakDown,
        activeTotalViewers: state.totalViewers,
        activeTotalContactViolations: state.totalContactViolations,
    })).on(convertPercentage, state => ({
        ...state,
        activePollingBreakDown: state.pollingBreakDownPercentage,
        activeTotalViewers: state.totalViewersPercentage,
        activeTotalContactViolations: state.totalContactViolationsPercentage,
    }));
