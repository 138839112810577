import { themeColors } from '../../../../scss/themeColors';

export const colorMap = {
    0: themeColors['red'],
    1: themeColors['green']
};

export const recruitedToDateViewerExperienceColorMap = [themeColors['green'], themeColors['green']];

export const snapShotSummaryHumanReadableLables = {
    totalTarget: 'Total to Recruit',
    targetToDate: 'Target to Date*',
    recruited: 'Recruited**',
    'DStv Access': 'DStv Access',
    'DStv EasyView': 'DStv Easy View',
    'DStv Family': 'DStv Family',
    'HDSingleView x 1': 'HD Single View x 1'
};
