import { createAction } from 'redux-act';
import { TFilters } from '../../../types/commonTypes';
import { BatchNumberTrackerFiltersRespose, BatchNumberTrackerResponseView } from './reducer';

export const requestBatchNumberTracker = createAction<TFilters>('GET_BATCH_NUMBER_TRACKER_REQUEST');
export const requestBatchNumberTrackerSuccess = createAction<BatchNumberTrackerResponseView[]>('GET_BATCH_NUMBER_TRACKER_SUCCESS_REQUEST');
export const requestBatchNumberTrackerFailed = createAction<any>('GET_BATCH_NUMBER_TRACKER_FAILED_REQUEST');


export const requestBatchNumberTrackerFilters = createAction<{[key: string]:string | number}>('GET_BATCH_NUMBER_TRACKER_FILTER_REQUEST');
export const requestBatchNumberTrackerFiltersSuccess = createAction<BatchNumberTrackerFiltersRespose>('GET_BATCH_NUMBER_TRACKER_FILTER_SUCCESS_REQUEST');
export const requestBatchNumberTrackerFiltersFailed = createAction<any>('GET_BATCH_NUMBER_TRACKER_FAILED_FILTER_REQUEST');


export const updateBatchNumberTracker = createAction<BatchNumberTrackerResponseView[]>('GET_BATCH_NUMBER_TRACKER_UPDATE');
export const updateBatchNumberTrackerSuccess = createAction('GET_BATCH_NUMBER_TRACKER_SUCCESS_UPDATE');
export const updateBatchNumberTrackerFailed = createAction<any>('GET_BATCH_NUMBER_TRACKER_FAILED_UPDATE');






