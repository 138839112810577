
import { call, put, takeLatest } from 'redux-saga/effects';
import { requestBatchNumberTracker, requestBatchNumberTrackerFailed, requestBatchNumberTrackerFilters, requestBatchNumberTrackerFiltersFailed, requestBatchNumberTrackerFiltersSuccess, requestBatchNumberTrackerSuccess, updateBatchNumberTracker, updateBatchNumberTrackerFailed, updateBatchNumberTrackerSuccess } from './action';
import { BatchNumberTrackerFiltersRespose, BatchNumberTrackerResponseView } from './reducer';
import { get , update } from '../../../api/api';
import { success, systemError } from '../../../utils/notifyToast';
import { Action } from 'redux-act';
import { buildGETUrlWithParams } from '../../../api/misc/misc';


function* batchNumberTrackerFunc(action: Action<{[key: string]:string | number}>){
    try{
        const requestUrl = buildGETUrlWithParams(action.payload, '/batch_tracker/?');
        const { data } = yield call(() => get(requestUrl));
        yield put(requestBatchNumberTrackerSuccess(data as BatchNumberTrackerResponseView[]));
    }catch (err) {
        systemError();
        yield put(requestBatchNumberTrackerFailed(err));
    }
}

function* batchNumberTrackerFilterFunc(action: Action<{[key: string]:string | number}>){
    try{
        const { data } = yield call(() => get(buildGETUrlWithParams(action.payload,'/batch_tracker/filters/?')));
        yield put(requestBatchNumberTrackerFiltersSuccess(data as BatchNumberTrackerFiltersRespose));
    }catch (err) {
        systemError();
        yield put(requestBatchNumberTrackerFiltersFailed(err));
    }
}

function* updateBatchNumberTrackerFilterFunc(action: Action<BatchNumberTrackerResponseView[]>){
    try{
        const { data } = yield call(() => update('/batch_tracker/',action.payload));
        success();
        yield put(updateBatchNumberTrackerSuccess());

    }catch (err) {
        systemError();
        yield put(updateBatchNumberTrackerFailed(err));
    }
}

export function* batchNumberTrackerWatchSaga(){
    yield takeLatest(requestBatchNumberTracker,batchNumberTrackerFunc);
    yield takeLatest(requestBatchNumberTrackerFilters,batchNumberTrackerFilterFunc);
    yield takeLatest(updateBatchNumberTracker, updateBatchNumberTrackerFilterFunc);
}
