export const themeColors = {
    darkPurple: 'rgb(68, 6, 88)',
    darkPurpleStates: 'rgb(92, 106, 174)',
    lightPurple: 'rgb(126, 40, 129)',
    lightPurpleStates: 'rgb(228, 1, 136)',
    red: 'rgb(179, 39, 53)',
    redStates: 'rgb(230, 28, 35)',
    orange: 'rgb(198, 103, 55)',
    orangeStates: 'rgb(236, 108, 31)',
    yellow: 'rgb(218, 138, 54)',
    yellowStates: 'rgb(245, 180, 48)',
    green: 'rgb(96, 140, 67)',
    greenStates: 'rgb(77, 178, 69)',
    blue: 'rgb(0, 110, 144)',
    blueStates: 'rgb(1, 166, 182)',
};

export const pastelThemeColors = {
    darkPurple: 'rgba(68, 6, 88, 0.6)',
    darkPurpleStates: 'rgba(92, 106, 174, 0.6)',
    lightPurple: 'rgba(126, 40, 129, 0.6)',
    lightPurpleStates: 'rgba(228, 1, 136, 0.6)',
    red: 'rgba(179, 39, 53, 0.6)',
    redStates: 'rgba(230, 28, 35, 0.6)',
    orange: 'rgba(198, 103, 55, 0.6)',
    orangeStates: 'rgba(236, 108, 31, 0.6)',
    yellow: 'rgba(218, 138, 54, 0.6)',
    yellowStates: 'rgba(245, 180, 48, 0.6)',
    green: 'rgba(96, 140, 67, 0.6)',
    greenStates: 'rgba(77, 178, 69, 0.6)',
    blue: 'rgba(0, 110, 144, 0.6)',
    blueStates: 'rgba(1, 166, 182, 0.6)',
};