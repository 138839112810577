import { themeColors } from '../../../scss/themeColors';
import {
    optionsActualBar,
    optionsActualBarPecentage,
    optionsTotalBar,
    optionsTotalsBarPecentage,
} from '../optionTemplates';
import { processLabels, roundMax } from '../proccessingUtils';

export const transChartData = (
    data: any,
    match: { [key: string]: string },
    meta?: string,
    colorMap?: any[]
) => {
    const _optionsActualBar = optionsActualBar;
    const _optionsTotalBar = optionsTotalBar;

    const dataValues: any = Object.values(data).map((item, i) => {
        return item;
    });

    const dataLabels = processLabels(data, match);

    const backgroundColor = [colorMap ? colorMap[0] : themeColors['darkPurple']];
    const borderColor = [
        colorMap ? colorMap[1] : themeColors['darkPurpleStates'],
    ];

    if (meta == 'max') {
        const max = roundMax(Math.max(...dataValues));
        _optionsTotalBar.scales.y.max = max;
    }

    return [
        {
            labels: dataLabels,
            datasets: [
                {
                    data: dataValues,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                },
            ],
        },
        meta === 'max' ? _optionsTotalBar : _optionsActualBar,
    ];
};

export const transChartDataPercentage = (
    data: any,
    match: { [key: string]: string },
    type: string,
    meta?: string,
    colorMap?: any[]
) => {
    const _optionsTotalsBarPecentage = optionsTotalsBarPecentage;
    const _optionsActualBarPecentage = optionsActualBarPecentage;

    let dataValues: any;
    let dataLabels;

    const backgroundColor = [
        colorMap
            ? `${colorMap[0].substring(0, colorMap[0].length - 1)}, 0.7)`
            : `${themeColors['darkPurple'].substring(
                0,
                themeColors['darkPurple'].length - 1
            )}, 0.7)`,
    ];
    const borderColor = [
        colorMap
            ? `${colorMap[1].substring(0, colorMap[1].length - 1)}, 0.7)`
            : `${themeColors['darkPurpleStates'].substring(
                0,
                themeColors['darkPurpleStates'].length - 1
            )}, 0.7)`,
    ];

    if (type === 'pie') {
        dataValues = [data['nonPolling'], data['polling']];
        dataLabels = ['Non Polling', 'Polling'];
    } else if (type === 'bar') {
        dataLabels = processLabels(data, match);
        dataValues = Object.values(data).map((item, i) => {
            return item;
        });
    }

    let max = Math.max(...dataValues);

    if (meta == 'max') {
        max = dataValues.reduce((a: number, b: number) => a + b);
        _optionsTotalsBarPecentage.scales.y.max = 100;
    }

    dataValues = Object.values(dataValues).map((item: any, i) => {
        return Math.ceil((100 / max) * item);
    });

    return [
        {
            labels: dataLabels,
            datasets: [
                {
                    data: dataValues,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1,
                },
            ],
        },
        meta == 'max' ? _optionsTotalsBarPecentage : _optionsActualBarPecentage,
    ];
};
